import { SocialLoginProviders } from '@frontegg/rest-api';
import { ILoginViaSocialLoginPayload } from '@frontegg/redux-store/auth/SocialLogins/interfaces';
import { AppLoader } from '../Common/Loaders/AppLoader';
import { AppRouting } from '../../services/appRoutingResolver';
import { usePerfectSocialSuccessHooks } from '../../hooks/use-perfect-social-success.hooks';
import { UserIdentifyMethod } from '../../store/slices/app-state/app-state.enums';
import { getAndDeleteCodeVerifier } from '../../utils/frontegg';

function MicrosoftSuccessScreen(): JSX.Element {
  const buildLoginPayload = (code: string): ILoginViaSocialLoginPayload => {
    const codeVerifier = getAndDeleteCodeVerifier();

    return {
      code,
      provider: SocialLoginProviders.Microsoft,
      codeVerifier,
      redirectUri: AppRouting.buildMicrosoftLoginSuccessUrl(window.location),
    };
  };

  usePerfectSocialSuccessHooks(UserIdentifyMethod.Microsoft, buildLoginPayload);

  return <AppLoader title="Social Login" />;
}

export default MicrosoftSuccessScreen;
