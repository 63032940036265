import { useEffect } from 'react';
import log from 'loglevel';
import { useSocialLoginActions, useSocialLoginState } from '@frontegg/react';
import { useDispatch } from 'react-redux';
import { ILoginViaSocialLoginPayload } from '@frontegg/redux-store/auth/SocialLogins/interfaces';
import { UserIdentifyMethod } from '../store/slices/app-state/app-state.enums';
import { AppRouting } from '../services/appRoutingResolver';
import { setUserIdentifyMethod } from '../store/slices/app-state/app-state.toolkit-slice';
import { SIGNUP_ERROR_CODE } from '../consts';

const logger = log.getLogger('SOCIAL_SUCCESS_HOOK');

const SOCIAL_LOGIN_ERRORS_RESPONSES = {
  WORK_EMAIL: 'Please sign in with your work email.',
};

const CODE_QUERY_PARAM = 'code';

export const usePerfectSocialSuccessHooks = (
  loginProvider: UserIdentifyMethod,
  buildLoginPayload: (code: string) => ILoginViaSocialLoginPayload,
): void => {
  const { error: socialLoginError } = useSocialLoginState();
  const { loginViaSocialLogin, setSocialLoginError } = useSocialLoginActions();
  const dispatch = useDispatch();

  const params = new URLSearchParams(window.location.search);
  const code = params.get(CODE_QUERY_PARAM) || '';

  useEffect(() => {
    if (!code) {
      logger.warn('invalid code parameter in url. cannot continue with social login');
      setSocialLoginError({ error: 'Social Login failed' });

      return;
    }

    dispatch(setUserIdentifyMethod(loginProvider));
    const payload = buildLoginPayload(code);

    loginViaSocialLogin(payload);
  }, [code, dispatch, buildLoginPayload, loginProvider, loginViaSocialLogin, setSocialLoginError]);

  if (!code) {
    logger.debug('no code provided, navigating to auth page');
    AppRouting.navigateToUrl(AppRouting.buildGlobalLoginUrl(window.location));
  } else if (socialLoginError) {
    let errorCode = SIGNUP_ERROR_CODE.SOCIAL_LOGIN_FAILED;

    if (socialLoginError === SOCIAL_LOGIN_ERRORS_RESPONSES.WORK_EMAIL) {
      errorCode = SIGNUP_ERROR_CODE.WORK_EMAIL;
    }

    logger.error(
      'failed to login with SSO. navigating the user to signup screen. frontegg error:',
      socialLoginError,
      '. signup internal app error code:',
      errorCode,
    );

    AppRouting.navigateToUrl(AppRouting.buildGlobalOnboardingUrl(window.location, errorCode));
  }
};
