import log from 'loglevel';
import {
  addSubDomainToUrl, getSubDomain, replaceSubDomain, shouldRedirectToGlobalLogin,
} from '../utils/url';
import { SIGNUP_ERROR_CODE, SUCCESS_CODE } from '../consts';
import { CONFIG } from '../config';
import { ConversationStatusEnum } from '../store/rtk-query/apis/outreach/enums/conversation-status.enum';
import { MatchFilterId } from '../pages/match-page/match.page.enum';
import { PageName } from '../enums/page-name.enum';

type BuildMatchPagePath = {
  positionId: string,
  matchId: string,
  filterId?: string,
  isShowDialog?: boolean,
  showDailyLimit?: boolean,
};

class AppRoutingResolver {
  private logger = log.getLogger(AppRoutingResolver?.name);

  public readonly URL_PARAMS = {
    MATCH: {
      POSITION_ID: 'positionId',
      MATCH_ID: 'matchId',
      FILTER_ID: 'filter',
      SHOW_MATCH_ENGAGEMENT_PANEL: 'showMatchEngagementPanel',
    },
    OUTREACH: {
      CONVERSATION_ID: 'conversationId',
      SEARCH: 'search',
      FILTER_BY_LIKED_USER: 'liked_user',
      FILTER_BY_STATUS: 'status',
      SHOW_DETAILS: 'show_details',
      SHOW_PREVIEW: 'show_preview',
      CONVERSATION_GROUP: 'conversation_group',
    },
    PAYMENT_SESSION_ID: 'session_id',
    PROMO: 'promo',
    SHOW_UPGRADE_MODAL: 'show_upgrade_modal',
    REFERRER: 'referrer',
  };

  public readonly POSITIONS_PATH_PARAM_KEY = 'positions';

  public readonly MATCH_PATH_PARAM_KEY = 'matches';

  public readonly CONVERSATION_PATH_PARAM_KEY = 'conversations';

  public readonly ROUTES = {
    HOME: '/',
    GLOBAL_LOGIN: '/',
    ONBOARDING: '/',
    ACTIVATE_ACCOUNT: '/account/activate',
    RESET_PASSWORD: '/account/reset-password',
    FORGOT_PASSWORD: '/account/forgot-password',
    SUCCESS_GOOGLE_LOGIN: '/account/social/success',
    SUCCESS_MICROSOFT_LOGIN: '/account/social/microsoft/success',
    POSITION: '/positions/:positionId',
    MATCH: '/positions/:positionId/matches/:matchId',
    MATCHING_PROGRESS: '/positions/:positionId/matches/progress',
    POSITION_STATISTICS: '/positions/:positionId/matches/statistics',
    MATCHES: '/positions/:positionId/matches',
    POSITION_OVERVIEW: '/positions/:positionId/overview',
    MATCH_TUNE: '/positions/:positionId/preferences',
    POSITION_INSIGHTS_PAGE: '/positions/:positionId/insights',
    POSITION_INTRODUCTION_PAGE: '/positions/:positionId/intro',
    POSITION_INTRODUCTION_SCHEDULE_MEETING_PAGE: '/positions/:positionId/intro/schedule',
    POSITION_NO_MATCHES_FOUND_PAGE: '/positions/:positionId/no-matches-found',
    ACCEPT_TENANT_INVITE: '/account/invitation/accept',
    USER_INFO: '/',
    ACTIVATION_COMPLETED: '/activation-completed',
    OUTREACH_POSITION: '/positions/:positionId/outreach',
    OUTREACH_POSITION_CONTENT_PREFERENCES: '/positions/:positionId/outreach/preferences/content',
    OUTREACH_POSITION_CONVERSATIONS: '/positions/:positionId/outreach/conversations',
    OUTREACH_POSITION_CAMPAIGN_PREFERENCES: '/positions/:positionId/outreach/preferences/campaign',
    OUTREACH_POSITION_CONVERSATION_ID: '/positions/:positionId/outreach/conversations/:conversationId',
    OUTREACH_POSITION_CONVERSATION_NEW: '/positions/:positionId/outreach/conversations/new',
  };

  public getPageName(path: string): PageName | null {
    if (path === '/') {
      return PageName.HomePage;
    }
    if (path.match('insights')) {
      return PageName.Insights;
    }
    if (path.match('matching-progress')) {
      return PageName.MatchingProgress;
    }
    if (path.match('schedule')) {
      return PageName.ScheduleMeeting;
    }
    if (path.match('intro')) {
      return PageName.PositionIntroduction;
    }
    if (path.match('no-matches-found')) {
      return PageName.NoMatchesFound;
    }
    if (path.match('matches')) {
      return PageName.Match;
    }
    if (path.match(/positions\/[a-f\d]{24}\/preferences$/i)) {
      return PageName.MatchTune;
    }
    if (path.match('overview')) {
      return PageName.Overview;
    }
    if (path.match(/outreach\/conversations\/[a-f\d]{24}$/i)) {
      return PageName.Conversation;
    }
    if (path.match(/outreach\/conversations/i)) {
      return PageName.ConversationsEmpty;
    }

    if (path.match(/outreach\/preferences\/content/i)) {
      return PageName.OutreachContentPreferences;
    }

    if (path.match(/outreach\/preferences\/campaign/i)) {
      return PageName.OutreachCampaignPreferences;
    }

    return null;
  }

  public buildForgotPasswordPagePath(): string {
    return this.buildGlobalLoginUrl(window.location, undefined, this.ROUTES.FORGOT_PASSWORD);
  }

  public buildHomePagePath(): string {
    return this.ROUTES.HOME;
  }

  /**
   * Build Match Page Path
   * positionId is required
   * matchId & filterId & isShowDialog are optional
   * @param positionId string
   * @param matchId string
   * @param filterId string
   * @param isShowDialog boolean
   * @returns path to match page
   */
  public buildMatchPagePath({
    positionId,
    matchId,
    filterId,
    isShowDialog,
  }: BuildMatchPagePath): string {
    const url = this.ROUTES.MATCH
      .replace(':positionId', positionId)
      .replace(':matchId', matchId);

    let params = '';

    if (filterId) {
      if (params.length > 0) {
        params += '&';
      }

      params += `${this.URL_PARAMS.MATCH.FILTER_ID}=${filterId}`;
    }

    if (isShowDialog) {
      if (params.length > 0) {
        params += '&';
      }

      params += `${this.URL_PARAMS.MATCH.SHOW_MATCH_ENGAGEMENT_PANEL}=${isShowDialog}`;
    }

    return `${url}?${params}`;
  }

  public buildGenAIPreferencesPageUrl = (positionId: string): string => {
    return this.ROUTES.OUTREACH_POSITION_CONTENT_PREFERENCES.replace(':positionId', positionId);
  }

  public buildCampaignsPreferencesPageUrl = (positionId: string): string => {
    return this.ROUTES.OUTREACH_POSITION_CAMPAIGN_PREFERENCES.replace(':positionId', positionId);
  }

  public buildPositionConversationsPageUrl = (positionId: string, status?: ConversationStatusEnum): string => {
    const url = this.ROUTES.OUTREACH_POSITION_CONVERSATIONS.replace(':positionId', positionId);
    if (status) {
      return `${url}?${this.URL_PARAMS.OUTREACH.FILTER_BY_STATUS}=${status}`;
    }

    return url;
  }

  public buildPositionConversationByIdPageUrl = (positionId: string, conversationId: string): string => {
    return this.ROUTES.OUTREACH_POSITION_CONVERSATION_ID
      .replace(':positionId', positionId)
      .replace(':conversationId', conversationId);
  }

  public buildPositionContentPreferencesPageUrl = (positionId: string): string => {
    return this.ROUTES.OUTREACH_POSITION_CONTENT_PREFERENCES
      .replace(':positionId', positionId);
  }

  public buildPositionOverviewPageUrl = (positionId: string): string => {
    return this.ROUTES.POSITION_OVERVIEW.replace(':positionId', positionId);
  }

  public buildPositionConversationPageUrl = (positionId: string, groupName?: string): string => {
    const url = this.ROUTES.OUTREACH_POSITION_CONVERSATIONS.replace(':positionId', positionId);
    if (groupName) {
      return `${url}?${this.URL_PARAMS.OUTREACH.CONVERSATION_GROUP}=${groupName}`;
    }

    return url;
  }

  public buildMatchesPageUrl = (positionId: string, filterId?: string): string => {
    const url = this.ROUTES.MATCHES.replace(':positionId', positionId);

    return this.appendMatchFilterIdToUrl(url, filterId);
  }

  public buildMatchStatisticsPagePath(positionId: string): string {
    return this.ROUTES.POSITION_STATISTICS.replace(':positionId', positionId);
  }

  public buildMatchTunePagePath(positionId: string): string {
    return this.ROUTES.MATCH_TUNE.replace(':positionId', positionId);
  }

  public buildMatchingProgressPagePath(positionId: string, filterId?: string): string {
    const url = this.ROUTES.MATCHING_PROGRESS.replace(':positionId', positionId);

    return this.appendMatchFilterIdToUrl(url, filterId || MatchFilterId.TO_REVIEW.toString());
  }

  public appendMatchFilterIdToUrl(url: string, filterId?: string): string {
    if (!filterId) {
      return url;
    }

    return `${url}?${this.URL_PARAMS.MATCH.FILTER_ID}=${filterId}`;
  }

  public buildPositionInsightsPageUrl = (positionId: string): string => {
    return this.ROUTES.POSITION_INSIGHTS_PAGE.replace(':positionId', positionId);
  }

  public buildPositionIntroductionPageUrl = (positionId: string): string => {
    return this.ROUTES.POSITION_INTRODUCTION_PAGE.replace(':positionId', positionId);
  }

  public buildPositionIntroductionScheduleMeetingPageUrl = (positionId: string): string => {
    return this.ROUTES.POSITION_INTRODUCTION_SCHEDULE_MEETING_PAGE.replace(':positionId', positionId);
  }

  public buildPositionNoMatchesFoundPageUrl = (positionId: string): string => {
    return this.ROUTES.POSITION_NO_MATCHES_FOUND_PAGE.replace(':positionId', positionId);
  }

  /**
   * Build global-login url based on current origin.
   * This supports running the app in different environments
   * and still be redirected to the correct url based on the environment domain
   * @param location window.location object of current session
   * @param successCode success code for display snackbar
   * @param route auth route to append (default is login page)
   * @returns string - url of global-login url
   */
  public buildGlobalLoginUrl(location: Location,
    successCode?: SUCCESS_CODE,
    route?: string): string {
    const { GLOBAL_LOGIN_SUBDOMAIN } = CONFIG;
    const currentSubDomain = getSubDomain(location.hostname);
    const successQuery = successCode ? `?successCode=${successCode}` : '';
    const globalLoginUrl = `${location.origin}${route || this.ROUTES.GLOBAL_LOGIN}${successQuery}`;

    if (!currentSubDomain) {
      // sub-domain doesn't exist
      return addSubDomainToUrl(globalLoginUrl, location.host, GLOBAL_LOGIN_SUBDOMAIN);
    }

    // replacing the first part of the host-name
    return replaceSubDomain(globalLoginUrl, currentSubDomain, GLOBAL_LOGIN_SUBDOMAIN);
  }

  /**
   * Build Google success-social-login url based on current origin.
   * This supports running the app in different environments
   * and still be redirected to the correct url based on the environment domain
   * @param location window.location object of current session
   * @returns string - url of success social login
   */
  public buildGoogleLoginSuccessUrl(location: Location): string {
    const ssoDomain = this.buildSocialLoginSsoDomain(location);

    return ssoDomain + this.ROUTES.SUCCESS_GOOGLE_LOGIN;
  }

  /**
   * Build Microsoft success-social-login url based on current origin.
   * This supports running the app in different environments
   * and still be redirected to the correct url based on the environment domain
   * @param location window.location object of current session
   * @returns string - url of success social login
   */
  public buildMicrosoftLoginSuccessUrl(location: Location): string {
    const ssoDomain = this.buildSocialLoginSsoDomain(location);

    return ssoDomain + this.ROUTES.SUCCESS_MICROSOFT_LOGIN;
  }

  private buildSocialLoginSsoDomain(location: Location): string {
    const ssoDomain = this.buildGlobalLoginUrl(location);

    // remove trailing slash after the origin name
    // https://aaa.bbb/ -> https://aaa.bbb
    return ssoDomain.substr(0, ssoDomain.length - 1);
  }

  /**
   * Get current root hostname, for example: '.perfectmatch.com'
   * in order to set cross-domain cookie,
   * which will be available across all sub-domain of the website
   * @returns string root origin host name
   */
  public getRootOrigin(): string {
    const currentSubDomain = getSubDomain(window.location.hostname);

    if (!currentSubDomain) {
      return window.location.hostname;
    }

    // remove first part in the host name
    const parts = window.location.hostname.split('.');
    parts.shift();

    return parts.join('.');
  }

  /**
   * Build global-onboarding url based on current origin.
   * @param location window.location object of current session
   * @param errorCode
   * @returns string - url of global-onboarding url
   */
  public buildGlobalOnboardingUrl(location: Location,
    errorCode?: SIGNUP_ERROR_CODE): string {
    const { ONBOARDING_SUBDOMAIN } = CONFIG;
    const currentSubDomain = getSubDomain(location.hostname);

    const errorQuery = errorCode ? `?errorCode=${errorCode}` : '';

    const globalOnboardingUrl = `${location.origin}${this.ROUTES.ONBOARDING}${errorQuery}`;

    if (!currentSubDomain) {
      // sub-domain doesn't exist
      return addSubDomainToUrl(globalOnboardingUrl, location.host, ONBOARDING_SUBDOMAIN);
    }

    // replacing the first part of the host-name
    return replaceSubDomain(globalOnboardingUrl, currentSubDomain, ONBOARDING_SUBDOMAIN);
  }

  /**
   * change page url to a new url location
   * @param newUrl
   */
  public navigateToUrl(newUrl: string): void {
    this.logger.debug('Navigating to a new url', { newUrl });
    window.location.assign(newUrl);
  }

  /**
   * Open url in new blank tab
   * @param newUrl
   */
  public openInNewTab(newUrl: string): void {
    this.logger.debug('Navigating to a new url', { newUrl });
    window.open(newUrl,  '_blank');
  }

  /**
   * Check if there is no sub-domain [or] its a reserved sub-domain,
   * and if so - redirect the user to a predefined global login URL
   * @returns boolean - true: if redirection is in action
   */
  public redirectToGlobalLogin(): boolean {
    const { location } = window;

    if (shouldRedirectToGlobalLogin(location)) {
      this.logger.debug('The user should be redirected to global login');
      this.navigateToUrl(this.buildGlobalLoginUrl(location));

      return true;
    }

    return false;
  }
}

const AppRouting =  new AppRoutingResolver();

export { AppRouting };
