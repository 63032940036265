import styled, { css }  from 'styled-components';
import { ASSETS } from '../../../../assets';
import { THEME } from '../../../../themes';
import { Subtitle1Font } from '../../../../themes/fonts';

const generateSocialButton = (url: string) => (
  css`
    ${Subtitle1Font};
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    padding: 20px;
    border-radius: ${THEME.RADIUS.LOOPY};
    box-shadow: 0 12px 24px 0 ${THEME.COLORS_RGB.BLACK_DIVIDER};
    position: relative;
    :before {
      position: absolute;
      left: 14px;
      top: calc(50% - 14px);
      content: '';
      background: url(${url}) no-repeat center center;
      background-size: contain;
      width: 28px;
      height: 28px;
    }
  `
);

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.BASE};
`;

export const ContinueWithGoogleButton = styled.div`
  ${generateSocialButton(ASSETS.GOOGLE_LOGO)};
`;

export const ContinueWithMicrosoftButton = styled.div`
  ${generateSocialButton(ASSETS.MICROSOFT_LOGO_IMAGE)};
`;
