import { FC, forwardRef, Ref } from 'react';
import { TransitionProps } from '@mui/material/transitions';
import { useTranslation } from 'react-i18next';
import {
  PerfectButtonHoverVariants,
  PerfectButtonVariants,
} from '../../../Common/Buttons/perfect-base-button/perfect-base-button.enums';
import STRING_KEYS from '../../../../language/keys';
import {
  ActionButtonContainer, ActionButtonsWrapper,
  CloseButtonContainer,
  CloseIcon,
  FooterContainer,
} from './notification-dialog-footer.styled';

const NotificationDialogFooter = forwardRef((
  props: TransitionProps & {
    onCloseClick: () => void;
    onActionClick?: () => void;
    onSecondaryActionClick?: () => void;
    actionTitle?: string;
    secondaryActionTitle?: string;
    ActionIcon?: FC;
    SecondaryActionIcon?: FC;
    closeTitle?: string;
  },
  ref: Ref<HTMLDivElement>,
) => {
  const {
    onCloseClick, onActionClick, onSecondaryActionClick, actionTitle, secondaryActionTitle, ActionIcon, SecondaryActionIcon, closeTitle,
  } = props;
  const { t: translate } = useTranslation();

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FooterContainer { ...props } ref={ ref } >
      <CloseButtonContainer
        onClick={ onCloseClick }
        StartIcon={ CloseIcon }
        variant={ PerfectButtonVariants.Ghost }
        hoverVariant={ PerfectButtonHoverVariants.Grow }
      >
        { closeTitle || translate(STRING_KEYS.CLOSE) }
      </CloseButtonContainer>
      <ActionButtonsWrapper>
        { !!onSecondaryActionClick && (
          <ActionButtonContainer
            onClick={ onSecondaryActionClick }
            StartIcon={ SecondaryActionIcon }
            variant={ PerfectButtonVariants.Ghost }
            hoverVariant={ PerfectButtonHoverVariants.Grow }
          >
            { secondaryActionTitle || translate(STRING_KEYS.ACTION) }
          </ActionButtonContainer>
        )}
        { !!onActionClick && (
          <ActionButtonContainer
            onClick={ onActionClick }
            StartIcon={ ActionIcon }
            variant={ PerfectButtonVariants.Ghost }
            hoverVariant={ PerfectButtonHoverVariants.Grow }
          >
            { actionTitle || translate(STRING_KEYS.ACTION) }
          </ActionButtonContainer>
        )}
      </ActionButtonsWrapper>
    </FooterContainer>
  );
});

NotificationDialogFooter.displayName = 'NotificationDialogFooter';

NotificationDialogFooter.defaultProps = {
  onActionClick: undefined,
  onSecondaryActionClick: undefined,
  actionTitle: undefined,
  secondaryActionTitle: undefined,
  ActionIcon: undefined,
  SecondaryActionIcon: undefined,
  closeTitle: undefined,
};

export default NotificationDialogFooter;
