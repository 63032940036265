import { outreachQuery } from '../outreach.toolkit-api';
import { EmailSignatureResponse } from '../dto/response/email-signature.response';

type UseGetSignatureQuery = {
  skip?: boolean,
  refetchOnMountOrArgChange?: boolean,
  pollingRunning?: boolean,
}

export const useGetSignatureQuery = (
  args: UseGetSignatureQuery | void,
): AppSelectorType<EmailSignatureResponse | undefined> => {
  const {
    data,
    isLoading,
    isError,
    isSuccess,
    error,
    refetch,
    isFetching,
  } = outreachQuery.useGetSignatureQuery(
    undefined,
    {
      skip: !!args?.skip,
      refetchOnMountOrArgChange: !!args?.refetchOnMountOrArgChange,
      pollingInterval: args?.pollingRunning ? 3000 : undefined,
    },
  );

  return {
    data,
    error,
    isLoading,
    isError,
    isSuccess,
    refetch,
    isFetching,
  };
};
