import { useTranslation } from 'react-i18next';
import { OutreachInitializationButton } from '../../../dialogs/outreach-initialization-dialog/outreach-initialization-dialog.styled';
import STRING_KEYS from '../../../../language/keys';
import { MicrosoftLogo } from './microsoft-login.styled';

export const MicrosoftLogin = ({ openPopupHandler, successHandler, errorHandler }: MicrosoftLoginProps): JSX.Element | null => {
  const { t: translate } = useTranslation();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.onSuccess = (value) => {
    successHandler(value);
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.onError = (error, errorDescription) => {
    errorHandler(error, errorDescription);
  };

  return (
    <OutreachInitializationButton onClick={ openPopupHandler }>
      <MicrosoftLogo />
      {translate(STRING_KEYS.CONTINUE_WITH_MICROSOFT)}
    </OutreachInitializationButton>
  );
};
