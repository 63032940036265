import styled from 'styled-components';
import Dialog from '../../../../../../components/dialogs/base-dialog/base-dialog.component';
import { THEME } from '../../../../../../themes';
import { Body2Font, HeadlineFont, Title2Font } from '../../../../../../themes/fonts';
import { hexToRgbA } from '../../../../../../utils/colors';
import BaseButton from '../../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.component';
import { ASSETS } from '../../../../../../assets';
import IconButton from '../../../../../../components/Common/Buttons/icon-button/icon-button.component';
import { Scrollbar } from '../../../../../../themes/scrollbar';

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const CustomDialog = styled(Dialog)`
  padding: 0;
  height: 750px;
`;

export const IntercomLink = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

export const HeaderContainer = styled(Dialog.Header)`
  ${Title2Font};
  line-height: 1.38;
  font-weight: 600;
  padding: ${THEME.SPACING.XLOOSE} 0 0 ${THEME.SPACING.LOOSE};
  display: flex;
  color: ${THEME.COLORS.CONTENT_A};
  height: fit-content;
`;

export const BodyContainer = styled(Dialog.Body)`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.MEDIUM};
  padding-left: ${THEME.SPACING.LOOSE};
  padding-right: ${THEME.SPACING.LOOSE};
  overflow: hidden;
`;

export const Footer = styled(Dialog.Footer)`
  border-top: solid 1px ${hexToRgbA(THEME.COLORS.CONTENT_PRIMARY, THEME.OPACITY.GHOST)};
  display: inline-flex;
  width: 100%;
  height: fit-content;
`;

export const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: ${THEME.SPACING.LOOSE};
  margin-right: ${THEME.SPACING.LOOSE};
  margin-top: ${THEME.SPACING.BASE};
  margin-bottom: ${THEME.SPACING.BASE};
  width: 100%;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.TINY};
`;

export const Title = styled.div`
  ${HeadlineFont};
  font-weight: 600;
  letter-spacing: 0.15px;
`;

export const RefreshToSignatureContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Description = styled.div`
  ${Body2Font};
  opacity: ${THEME.OPACITY.MED};
  align-items: center;
`;

export const EmailContainer = styled.div`
  background-color: ${hexToRgbA(THEME.COLORS.PRIMARY_DARK, THEME.OPACITY.DIVIDER)};
  border-radius: ${THEME.RADIUS.ROUNDISH};
  height: 47px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${THEME.SPACING.BASE};
`;

export const SignatureContainer = styled.div`
  ${Scrollbar};
  border-radius: ${THEME.RADIUS.ROUND};
  height: 200px;
  border: solid 1px ${hexToRgbA(THEME.COLORS.PRIMARY_DARK, THEME.OPACITY.FOCUS)};
  overflow-y: auto;
  overflow-x: hidden;
`;

export const Email = styled.div`
  ${Body2Font};
  font-weight: 500;
  color: ${hexToRgbA(THEME.COLORS.PRIMARY_DARK, THEME.OPACITY.MED)};
`;

export const CopyIcon = styled(ASSETS.COPY_TEXT)`
  width: 16px;
  height: 16px;
  fill: ${THEME.COLORS.ACCENT};
`;

export const ArrowLoopIcon = styled(ASSETS.ARROW_LOOP)`
  width: 18px;
  height: 18px;
  fill: ${THEME.COLORS.ACCENT};
`;

export const CancelButton = styled(BaseButton)`
  color: ${THEME.COLORS.ACCENT};
  width: 64px;
  height: 20px;
`;

export const RefreshButton = styled(BaseButton)`
  color: ${THEME.COLORS.ACCENT};
`;

export const SaveButton = styled(BaseButton)`
  background-color: ${THEME.COLORS.ACCENT};
  width: 221px;
  height: 48px;
`;

export const CopyButton = styled(IconButton)`
  color: ${THEME.COLORS.ACCENT};
  background-color: unset;
`;
