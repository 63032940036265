import { outreachQuery } from '../outreach.toolkit-api';
import { ApiError } from '../../../../../consts/api';
import { EmailSignatureProcessResponse } from '../dto/response/email-signature-process.response';

type UseInitializeMicrosoftSignatureProcessMutationType = [
  () => void,
  AppSelectorType<EmailSignatureProcessResponse | undefined, ApiError>
];

export const useInitializeMicrosoftSignatureProcessMutation = (): UseInitializeMicrosoftSignatureProcessMutationType => {
  const [
    initializeProcess,
    {
      data, isLoading, isError, error, isSuccess,
    }] = outreachQuery.useInitializeSignatureProcessMutation();

  const meta = {
    isLoading, isError, error: error as ApiError, isSuccess, data,
  };

  return [
    initializeProcess,
    { ...meta },
  ];
};
