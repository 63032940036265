import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { PerfectButtonVariants } from '../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.enums';
import { ANIMATED_FLUENT_UI_EMOJIS } from '../../../../../assets';
import { OutreachBannerProps } from './outreach-banner';
import {
  ActionButtons,
  BannerActionButton,
  BannerLottieWrapper,
  ConnectEmailImg,
  Container,
  ConversationBannerDescription,
  ConversationBannerStartWrapper,
  ConversationBannerTitle,
  OutreachIcon,
  PauseIcon,
} from './outreach-banner.styled';
import { OutreachBannerSizeEnum, OutreachBannerTypeEnum } from './outreach-banner-type.enum';
import { getBannerConfiguration } from './outreach-banner.utils';

const OutreachBanner = ({
  type,
  size = OutreachBannerSizeEnum.Large,
  actionButtonHandler,
  secondaryActionButtonHandler,
  additionalInfo,
}: OutreachBannerProps): JSX.Element | null => {
  const { t: translate } = useTranslation();

  const configuration = useMemo(() => getBannerConfiguration(type, additionalInfo), [type, additionalInfo]);

  if (!configuration) {
    return null;
  }

  const renderIcon = () => {
    switch (type) {
      case OutreachBannerTypeEnum.UpgradeToPro:
        return (<OutreachIcon />);
      case OutreachBannerTypeEnum.SetupGenAi:
      case OutreachBannerTypeEnum.CampaignOnHoldUserEmailConnection:
      case OutreachBannerTypeEnum.CampaignOnHoldEmailConnection:
      case OutreachBannerTypeEnum.CampaignOnHoldInternalError:
        return (<PauseIcon />);
      case OutreachBannerTypeEnum.ConnectEmail:
      case OutreachBannerTypeEnum.ReconnectEmail:
      default:
        return (
          <BannerLottieWrapper>
            <ConnectEmailImg
              src={ ANIMATED_FLUENT_UI_EMOJIS.ELECTRIC_PLUG }
              alt="Electric Plug"
            />
          </BannerLottieWrapper>
        );
    }
  };

  const renderDescription = () => {
    if (!configuration.description) {
      return null;
    }

    if (typeof configuration.description === 'string') {
      return translate(configuration.description, additionalInfo);
    }

    return configuration.description;
  };

  return (
    <Container
      color={ configuration.color }
      size={ size }
      actionButtonColor={ configuration.actionButtonColor }
    >
      <ConversationBannerStartWrapper>
        { renderIcon() }
        <div>
          <ConversationBannerTitle>{ translate(configuration.title) }</ConversationBannerTitle>
          <ConversationBannerDescription>{ renderDescription() }</ConversationBannerDescription>
        </div>
      </ConversationBannerStartWrapper>
      <ActionButtons>
        { configuration.secondaryActionButtonText && secondaryActionButtonHandler && (
          <BannerActionButton
            variant={ configuration.secondaryActionButtonVariant || PerfectButtonVariants.Ghost }
            onClick={ secondaryActionButtonHandler }
          >
            { translate(configuration.secondaryActionButtonText) }
          </BannerActionButton>
        ) }
        { configuration.actionButtonText && actionButtonHandler && (
          <BannerActionButton
            variant={ configuration.actionButtonVariant || PerfectButtonVariants.Ghost }
            onClick={ actionButtonHandler }
          >
            { translate(configuration.actionButtonText) }
          </BannerActionButton>
        ) }
      </ActionButtons>
    </Container>
  );
};

export default OutreachBanner;
