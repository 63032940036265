import { outreachQuery } from '../outreach.toolkit-api';
import { ApiError } from '../../../../../consts/api';
import { MicrosoftEmailSignatureResponse } from '../dto/response/microsoft-email-signature.response';

type UseApproveSignatureMutationType = [
  () => void,
  AppSelectorType<MicrosoftEmailSignatureResponse | undefined, ApiError>
];

export const useApproveSignatureMutation = (): UseApproveSignatureMutationType => {
  const [
    approveSignature,
    {
      data, isLoading, isError, error, isSuccess,
    }] = outreachQuery.useApproveSignatureMutation();

  const meta = {
    isLoading, isError, error: error as ApiError, isSuccess, data,
  };

  return [
    approveSignature,
    { ...meta },
  ];
};
