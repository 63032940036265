/* eslint-disable react/jsx-props-no-spreading */
import Portal from '../../Common/Portal/portal.commponent';
import { Divider } from '../../Common/Divider';
import {
  NotificationDialogContainer,
} from './notification-dialog.styled';
import NotificationDialogFooter from './notification-dialog-footer/notification-dialog-footer.component';

const CONTAINER_ID = 'NOTIFICATION_DIALOG';

const NotificationDialog = ({
  isOpen,
  onClose,
  onAction,
  onSecondaryAction,
  actionTitle,
  secondaryActionTitle,
  ActionIcon,
  SecondaryActionIcon,
  closeTitle,
  showDivider = true,
  children,
  ...otherProps
}: DialogProps): JSX.Element => {
  return (
    <>
      {isOpen && (
        <Portal containerId={ CONTAINER_ID } isGlobalPortal>
          <NotificationDialogContainer { ...otherProps }>
            { children }
            { showDivider && <Divider /> }
            <NotificationDialogFooter
              onCloseClick={ onClose }
              onActionClick={ onAction }
              onSecondaryActionClick={ onSecondaryAction }
              actionTitle={ actionTitle }
              secondaryActionTitle={ secondaryActionTitle }
              ActionIcon={ ActionIcon }
              SecondaryActionIcon={ SecondaryActionIcon }
              closeTitle={ closeTitle }
            />
          </NotificationDialogContainer>
        </Portal>
      )}
    </>
  );
};

export default NotificationDialog;
