import { useMemo } from 'react';
import OutreachBanner from '../outreach-banner/outreach-banner.component';
import { OutreachBannerTypeEnum } from '../outreach-banner/outreach-banner-type.enum';
import { SequenceStatusEnum } from '../../../../../store/rtk-query/apis/sequence/enums/sequence-status.enum';
import { SequenceOnHoldReasonTypeEnum } from '../../../../../store/rtk-query/apis/sequence/enums/sequence-on-hold-reason-type.enum';
import { useMyUserSelector } from '../../../../../store/rtk-query/apis/user/selectors/get-my-user.selector';
import { useShouldLockFeature } from '../../../../../hooks/should-lock-feature.hook';
import {
  EmailSenderIntegrationStatusEnum,
  useGetEmailSenderIntegrationStatus,
} from '../../../../../store/rtk-query/apis/outreach/hooks/get-email-sender.query-hook';
import { useGetPositionSequencePreferencesQuery } from '../../../../../store/rtk-query/apis/sequence/hooks/get-position-sequence-preferences.query-hook';

type UseOutreachBannerArguments = {
  positionId: string;
  actionButtonClickHandler: (bannerType: OutreachBannerTypeEnum) => void;
  secondaryActionButtonClickHandler: (bannerType: OutreachBannerTypeEnum) => void;
};

type UseOutreachBannerReturnType = {
  Banner: JSX.Element;
  bannerType: OutreachBannerTypeEnum;
};

export const useOutreachBanner = (
  { positionId, actionButtonClickHandler, secondaryActionButtonClickHandler }: UseOutreachBannerArguments,
): UseOutreachBannerReturnType | null => {
  const { data: currentUser } = useMyUserSelector();
  const isLocked = useShouldLockFeature();
  const { data: preferences, isLoading: isPreferencesLoading } = useGetPositionSequencePreferencesQuery(positionId);
  const emailSenderStatus = useGetEmailSenderIntegrationStatus();

  const onActionButtonClick = (bannerType?: OutreachBannerTypeEnum) => {
    if (bannerType) {
      actionButtonClickHandler(bannerType);
    }
  };

  const onSecondaryActionButtonClick = (bannerType?: OutreachBannerTypeEnum) => {
    if (bannerType) {
      secondaryActionButtonClickHandler(bannerType);
    }
  };

  return useMemo(() => {
    let bannerType: OutreachBannerTypeEnum | undefined;
    let bannerAdditionalInfo: Record<string, string> | undefined;

    if (isLocked) {
      bannerType = OutreachBannerTypeEnum.UpgradeToPro;
    } else if (preferences.sequencePreferences?.status === SequenceStatusEnum.OnHold) {
      if (preferences.sequencePreferences?.onHoldReason?.type === SequenceOnHoldReasonTypeEnum.EmailConnection) {
        const primaryEmailSender = preferences.sequencePreferences?.senders?.find((sender) => sender.isPrimarySender);
        if (primaryEmailSender?.user?.id === currentUser?.id) {
          bannerType = OutreachBannerTypeEnum.CampaignOnHoldUserEmailConnection;
        } else {
          bannerType = OutreachBannerTypeEnum.CampaignOnHoldEmailConnection;
          bannerAdditionalInfo = { userName: primaryEmailSender?.user?.fullName || '' };
        }
        bannerType = primaryEmailSender?.user?.id === currentUser?.id ?
          OutreachBannerTypeEnum.CampaignOnHoldUserEmailConnection :
          OutreachBannerTypeEnum.CampaignOnHoldEmailConnection;
      }
      if (preferences.sequencePreferences?.onHoldReason?.type === SequenceOnHoldReasonTypeEnum.InternalError) {
        bannerType = OutreachBannerTypeEnum.CampaignOnHoldInternalError;
      }
    } else if (emailSenderStatus === EmailSenderIntegrationStatusEnum.NotConnected) {
      bannerType = OutreachBannerTypeEnum.ConnectEmail;
    } else if (emailSenderStatus === EmailSenderIntegrationStatusEnum.Invalid) {
      bannerType = OutreachBannerTypeEnum.ReconnectEmail;
    } else if (!preferences?.contentPreferences && !isPreferencesLoading) {
      bannerType = OutreachBannerTypeEnum.SetupGenAi;
    }

    if (!bannerType) {
      return null;
    }

    return ({
      Banner: (
        <OutreachBanner
          type={ bannerType }
          actionButtonHandler={ () => onActionButtonClick(bannerType) }
          secondaryActionButtonHandler={ () => onSecondaryActionButtonClick(bannerType) }
          additionalInfo={ bannerAdditionalInfo }
        />
      ),
      bannerType,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentUser?.id,
    emailSenderStatus,
    isPreferencesLoading,
    isLocked,
    preferences?.contentPreferences,
    preferences?.sequencePreferences?.onHoldReason?.type,
    preferences?.sequencePreferences?.senders,
    preferences?.sequencePreferences?.status,
  ]);
};
