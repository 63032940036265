import { Trans, useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';
import { DialogWidthSize } from '../../../../../../components/dialogs/base-dialog/base-dialog.enums';
import { Divider } from '../../../../../../components/Common/Divider';
import STRING_KEYS from '../../../../../../language/keys';
import {
  PerfectButtonHoverVariants,
  PerfectButtonVariants,
} from '../../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.enums';
import { IconButtonVariants } from '../../../../../../components/Common/Buttons/icon-button/icon-button.enums';
import {
  useGetSignatureExtractionProcessQuery,
} from '../../../../../../store/rtk-query/apis/outreach/hooks/get-signature-extraction-process.query-hook';
import { DotsLoaderVariation } from '../../../../../../components/Common/Loaders/dots-loader/dots-loader.enums';
import DotsLoader from '../../../../../../components/Common/Loaders/dots-loader/dots-loader.component';
import {
  useInitializeMicrosoftSignatureProcessMutation,
} from '../../../../../../store/rtk-query/apis/outreach/hooks/initialize-signature-extraction-process.mutation-hook';
import {
  MicrosoftEmailSignatureProcessStatus,
} from '../../../../../../store/rtk-query/apis/outreach/enums/microsoft-email-signature-process-status.enum';
import {
  useApproveSignatureMutation,
} from '../../../../../../store/rtk-query/apis/outreach/hooks/approve-signature.mutation-hook';
import { ANALYTICS_EVENTS, EVENT_TYPE } from '../../../../../../consts/analytics';
import { RudderStackDestination } from '../../../../../../services/rudderStackAnalyticsAgent';
import { useAnalyticsContext } from '../../../../../../contexts/analytics/useAnalyticsContext';
import { Signature } from './signature/signature.component';
import {
  BodyContainer,
  CancelButton,
  CopyIcon,
  CustomDialog,
  Description,
  Email,
  EmailContainer,
  Footer,
  FooterContainer,
  HeaderContainer,
  SaveButton,
  Section,
  Title,
  CopyButton,
  SignatureContainer,
  RefreshButton,
  RefreshToSignatureContainer,
  ArrowLoopIcon,
  LoaderContainer,
  IntercomLink,
} from './preview-signature-dialog.styled';

export const PreviewSignatureDialog = ({
  closeDialogHandler,
  isDialogOpen,
}: PreviewSignatureDialogProps): JSX.Element => {
  const { analyticsTrackEvent } = useAnalyticsContext();
  const { t: translate } = useTranslation();
  const [shouldPollingSegnatureProcess, setShouldPollingSegnatureProcess] = useState(false);
  const {
    data: microsoftSignatureProcess,
    isLoading: getSignatureProcessLoading,
    refetch,
    isSuccess: isMicrosoftSignatureProcessSuccess,
  } = useGetSignatureExtractionProcessQuery({
    skip: !isDialogOpen,
    refetchOnMountOrArgChange: true,
    pollingRunning: shouldPollingSegnatureProcess,
  });

  const [initializeProcess, { isLoading: initializeProcessLoading }] = useInitializeMicrosoftSignatureProcessMutation();
  const [approveSignature, { isLoading: approveProcessLoading, isSuccess: signatureConfirmed }] = useApproveSignatureMutation();

  useEffect(() => {
    if (isMicrosoftSignatureProcessSuccess && !microsoftSignatureProcess) {
      initializeProcess();
    }
  }, [initializeProcess, isMicrosoftSignatureProcessSuccess, microsoftSignatureProcess]);

  useEffect(() => {
    if (signatureConfirmed) {
      closeDialogHandler(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signatureConfirmed]);

  useEffect(() => {
    if (isMicrosoftSignatureProcessSuccess && microsoftSignatureProcess?.status !== MicrosoftEmailSignatureProcessStatus.Finished) {
      setShouldPollingSegnatureProcess(true);
    }
    if (isMicrosoftSignatureProcessSuccess && microsoftSignatureProcess?.status === MicrosoftEmailSignatureProcessStatus.Finished) {
      setShouldPollingSegnatureProcess(false);
    }
  }, [microsoftSignatureProcess, isMicrosoftSignatureProcessSuccess]);

  const onApproveSignatureHandler = useCallback(() => {
    setShouldPollingSegnatureProcess(false);
    approveSignature();
  }, [approveSignature]);

  const onCloseDialogClick = useCallback(() => {
    closeDialogHandler();
  }, [closeDialogHandler]);

  const onCopyClick = useCallback(() => {
    if (microsoftSignatureProcess?.toEmailAddress) {
      navigator?.clipboard?.writeText(microsoftSignatureProcess.toEmailAddress);
    }
  }, [microsoftSignatureProcess]);

  const onHelpButtonClick = useCallback(() => {
    const payload = {
      eventName: ANALYTICS_EVENTS.INTERCOM_TRIGGER_ADD_SIGNATURE_HELP,
      event_type: EVENT_TYPE.INTERCOM_TRIGGER,
    };
    analyticsTrackEvent(payload, [RudderStackDestination.INTERCOM]);
  }, [analyticsTrackEvent]);

  const renderDialogContent = useCallback(() => {
    if (getSignatureProcessLoading || initializeProcessLoading) {
      return (
        <LoaderContainer>
          <DotsLoader variation={ DotsLoaderVariation.Section } />
        </LoaderContainer>
      );
    }

    return (
      <>
        <HeaderContainer>{translate(STRING_KEYS.OUTREACH_PAGE.SIGNATURE_DIALOG.TITLE)}</HeaderContainer>
        <BodyContainer>
          <Section>
            <Title>{translate(STRING_KEYS.OUTREACH_PAGE.SIGNATURE_DIALOG.STEP_1.TITLE)}</Title>
            <Description>
              {translate(STRING_KEYS.OUTREACH_PAGE.SIGNATURE_DIALOG.STEP_1.DESCRIPTION)}
            </Description>
            <EmailContainer>
              <Email>
                { microsoftSignatureProcess?.toEmailAddress }
              </Email>
              <CopyButton
                type="button"
                onClick={ onCopyClick }
                variant={ IconButtonVariants.Ghost }
                hoverVariant={ PerfectButtonHoverVariants.Grow }
                StartIcon={ CopyIcon }
              />
            </EmailContainer>
          </Section>
          <Divider />
          <Section>
            <Title>{translate(STRING_KEYS.OUTREACH_PAGE.SIGNATURE_DIALOG.STEP_2.TITLE)}</Title>
            <RefreshToSignatureContainer>
              <Description>
                {translate(STRING_KEYS.OUTREACH_PAGE.SIGNATURE_DIALOG.STEP_2.DESCRIPTION)}
              </Description>
              <RefreshButton
                type="button"
                variant={ PerfectButtonVariants.Ghost }
                hoverVariant={ PerfectButtonHoverVariants.Grow }
                onClick={ refetch }
                StartIcon={ ArrowLoopIcon }
              >
                {translate(STRING_KEYS.OUTREACH_PAGE.SIGNATURE_DIALOG.ACTION.REFRESH)}
              </RefreshButton>
            </RefreshToSignatureContainer>
            <SignatureContainer>
              <Signature
                htmlContent={ microsoftSignatureProcess?.signature || '' }
              />
            </SignatureContainer>
          </Section>
          <Divider />
          <Section>
            <Description>
              <span>
                <Trans i18nKey={ STRING_KEYS.OUTREACH_PAGE.SIGNATURE_DIALOG.STEP_3.DESCRIPTION } />
                <Trans
                  defaults={ STRING_KEYS.OUTREACH_PAGE.SIGNATURE_DIALOG.STEP_3.LINK_TO_SUPPORT }
                  components={ { IntercomLink: <IntercomLink onClick={ onHelpButtonClick } /> } }
                />
              </span>
            </Description>
          </Section>
        </BodyContainer>
        <Footer>
          <FooterContainer>
            <CancelButton
              type="button"
              variant={ PerfectButtonVariants.Ghost }
              hoverVariant={ PerfectButtonHoverVariants.Grow }
              onClick={ onCloseDialogClick }
            >
              {translate(STRING_KEYS.OUTREACH_PAGE.SIGNATURE_DIALOG.ACTION.CANCEL)}
            </CancelButton>
            <SaveButton
              type="button"
              hoverVariant={ PerfectButtonHoverVariants.Grow }
              onClick={ onApproveSignatureHandler }
              disabled={ !microsoftSignatureProcess?.signature || microsoftSignatureProcess?.status !== MicrosoftEmailSignatureProcessStatus.InProgress }
              isLoading={ approveProcessLoading }
            >
              {translate(STRING_KEYS.OUTREACH_PAGE.SIGNATURE_DIALOG.ACTION.CONFIRM)}
            </SaveButton>
          </FooterContainer>
        </Footer>
      </>
    );
  }, [
    getSignatureProcessLoading,
    initializeProcessLoading,
    translate,
    microsoftSignatureProcess?.toEmailAddress,
    microsoftSignatureProcess?.signature,
    microsoftSignatureProcess?.status,
    onCopyClick,
    refetch,
    onHelpButtonClick,
    onCloseDialogClick,
    onApproveSignatureHandler,
    approveProcessLoading,
  ]);

  return (
    <CustomDialog
      isOpen={ isDialogOpen }
      onClose={ onCloseDialogClick }
      widthSize={ DialogWidthSize.Large }
    >
      { renderDialogContent() }
    </CustomDialog>
  );
};
