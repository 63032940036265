import log from 'loglevel';
import Cookies from 'js-cookie';
import { useCallback, useEffect, useState } from 'react';
import { CONFIG } from '../../../config';
import { extractDomainFromUrl } from '../../../utils';
import { useCustomWindow } from '../../../hooks/custom-window.hook';
import { MicrosoftLogin } from './microsoft-login/microsoft-login.component';

const logger = log.getLogger('MICROSOFT_EMAIL_OAUTH_FRAME');
const AUTH_CODE_COOKIE_NAME = 'microsoft_oauth_code';
const AUTH_ERROR_COOKIE_NAME = 'microsoft_oauth_error';

// this component is used within iframe!
export const MicrosoftEmailOAuthFrame = (): JSX.Element | null => {
  const clientId = CONFIG.OUTREACH_MICROSOFT_CLIENT_ID;
  const redirectUri = CONFIG.MICROSOFT_EMAIL_OAUTH_URL; // Replace with your app's redirect URI
  const scope = 'openid profile User.Read email offline_access https://graph.microsoft.com/Mail.Send'; // Replace with the scopes you need
  const searchParams = new URLSearchParams(window.location.search);
  const readPermissionsApproved = searchParams.get('readPermissionsApproved') || true;

  const scopeWithReadPermissions = readPermissionsApproved !== 'false' ? `${scope} https://graph.microsoft.com/Mail.ReadWrite` : scope;
  const loginUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}&scope=${scopeWithReadPermissions}&prompt=select_account`;

  const [parentOrigin, setParentOrigin] = useState<string | null>(null);
  const { openPopup, isOpen } = useCustomWindow(loginUrl);

  const onMicrosoftLoginSuccess = useCallback((code: string) => {
    if (parentOrigin) {
      window.parent.postMessage({ code }, parentOrigin);
    }
  }, [parentOrigin]);

  const onMicrosoftLoginError =  useCallback((error: string, errorDescription: string) => {
    logger.error('Error on microsoft login', error, errorDescription);

    if (parentOrigin) {
      window.parent.postMessage({ error: errorDescription }, parentOrigin);
    }
  }, [parentOrigin]);

  const handleNewMessage = (message: MessageEvent) => {
    if (message.type !== 'message') {
      logger.debug('skipping new message of type', message.type);

      return;
    }

    const senderOrigin = extractDomainFromUrl(message.origin);

    if (senderOrigin !== CONFIG.URL_DOMAIN) {
      logger.debug(`ignoring messages of unknown origins. senderOrigin: ${senderOrigin}`);

      return;
    }

    // todo: skip messages by origin (when servers are ready)
    if (!message.data.parentOrigin) {
      logger.debug('skipping message without expected data', { message });

      return;
    }

    logger.debug('got new message with data', { message });

    setParentOrigin(message.data.parentOrigin);
  };

  useEffect(() => {
    window.addEventListener('message', handleNewMessage);

    return () => {
      window.removeEventListener('message', handleNewMessage);
    };
  }, []);

  // Fallback logic when parent window is unavailable
  useEffect(() => {
    const codeCookie = Cookies.get(AUTH_CODE_COOKIE_NAME);
    const errorCookie = Cookies.get(AUTH_ERROR_COOKIE_NAME);
    if (codeCookie) {
      logger.debug('Microsoft auth code retrieved from cookie after popup closure - forwarding to web application');
      onMicrosoftLoginSuccess(codeCookie);
      Cookies.remove(AUTH_CODE_COOKIE_NAME);
    }

    if (errorCookie) {
      try {
        const { error, errorDescription } = JSON.parse(errorCookie);
        onMicrosoftLoginError(error, errorDescription);
      } catch (e) {
        logger.error(`${AUTH_CODE_COOKIE_NAME} parsing error: ${e}`);
      } finally {
        Cookies.remove(AUTH_ERROR_COOKIE_NAME);
      }
    }
  }, [isOpen, onMicrosoftLoginSuccess, onMicrosoftLoginError]);

  useEffect(() => {
    // Check if we have an authorization code in the query string
    const params = new URLSearchParams(window.location.search);
    const parentWindow = window.opener;
    const code = params.get('code');

    if (code) {
      if (parentWindow) {
        parentWindow.onSuccess(code);
      } else {
        logger.debug('Unable to access parent window (reason unknown) - storing auth code in cookie');
        // Store auth code in cookie (expires in 1 minute)
        Cookies.set(AUTH_CODE_COOKIE_NAME, code, { expires: 1 / (24 * 60) });
      }
      window.close();
    }

    const error = params.get('error');
    if (error) {
      const errorDescription = params.get('error_description');
      if (parentWindow) {
        parentWindow.onError(error, errorDescription);
      } else {
        logger.debug('Unable to access parent window (reason unknown) - storing auth error in cookie');
        // Store auth error in cookie (expires in 1 minute)
        try {
          const stringifyError = JSON.stringify({ error, errorDescription });
          Cookies.set(AUTH_ERROR_COOKIE_NAME, stringifyError, { expires: 1 / (24 * 60) });
        } catch (e) {
          logger.error('Stringify error state failed', e, { error, errorDescription });
        }
      }
      window.close();
    }
  }, []);

  if (!CONFIG.OUTREACH_MICROSOFT_CLIENT_ID) {
    return null;
  }

  if (!parentOrigin) {
    return null;
  }

  return (
    <MicrosoftLogin openPopupHandler={ openPopup } successHandler={ onMicrosoftLoginSuccess } errorHandler={ onMicrosoftLoginError } />
  );
};
