import { outreachQuery } from '../outreach.toolkit-api';
import { EmailSignatureProcessResponse } from '../dto/response/email-signature-process.response';

type UseGetSignatureExtractionProcessQuery = {
  skip?: boolean,
  refetchOnMountOrArgChange?: boolean,
  pollingRunning?: boolean,
}

export const useGetSignatureExtractionProcessQuery = (
  args: UseGetSignatureExtractionProcessQuery | void,
): AppSelectorType<EmailSignatureProcessResponse | undefined> => {
  const {
    data,
    isLoading,
    isError,
    isSuccess,
    error,
    refetch,
    isFetching,
  } = outreachQuery.useGetSignatureExtractionProcessQuery(
    undefined,
    {
      skip: !!args?.skip,
      refetchOnMountOrArgChange: !!args?.refetchOnMountOrArgChange,
      pollingInterval: args?.pollingRunning ? 3000 : undefined,
    },
  );

  return {
    data,
    error,
    isLoading,
    isError,
    isSuccess,
    refetch,
    isFetching,
  };
};
