import { PerfectTextEditorBlockAlignment } from './perfect-text-editor.enums';

export const DRAFT_CHANGE_TYPE = 'change-inline-style';
export const DRAFT_HANDLE_VALUE = 'handled';
export const DRAFT_NOT_HANDLE_VALUE = 'not-handled';

export const DRAFT_ALIGNMENT_CLASS_NAME = 'editor-alignment';
export const DRAFT_DIRECTION_CLASS_NAME = 'editor-direction';

export const DRAFT_ALIGNMENT_STYLE_LIST = [
  PerfectTextEditorBlockAlignment.Left,
  PerfectTextEditorBlockAlignment.Right,
  PerfectTextEditorBlockAlignment.Center,
];

export const EDITOR_COMMAND = {
  BACKSPACE: 'backspace',
  SPLIT_BLOCK: 'split-block',
};
