import { createApi } from '@reduxjs/toolkit/query/react';
import { setSequenceMode } from '../../../slices/outreach-state/outreach-state.toolkit-slice';
import { axiosBaseQuery } from '../../utils/base-query';
import { outreachQuery } from '../outreach/outreach.toolkit-api';
import { ConversationResponseDto } from '../outreach/dto/response/conversation.response';
import { LinkedinTaskStatusEnum } from '../outreach/enums/linkedin-task-status.enum';
import { LinkedinTaskResponseDto } from '../outreach/dto/response/conversation-base.response';
import { REDUCER_PATH } from './sequence.consts';
import { PositionSequencePreferencesResponseDto } from './dto/response/position-sequence-preferences.response';
import {
  cancelConversationSequenceQuery,
  createMessagePreviewQuery,
  createPositionSequencePreferencesQuery,
  getPositionSequencePreferencesQuery,
  updateScheduledMessageQuery,
  updateLinkedinTaskQuery,
  updatePositionSequencePreferencesQuery,
  createConversationSequenceQuery,
  getConversationNextScheduledMessageQuery,
  updateCustomStepsQuery,
  regenerateConversationSequenceQuery,
  resumeCampaignQuery,
} from './sequence.toolkit-queries';
import { GetPositionSequencePreferencesQueryArguments } from './dto/query-arguments/get-position-sequence-preferences.query-arguments';
import { CreateOrUpdatePositionSequencePreferencesQueryArguments } from './dto/query-arguments/create-or-update-position-sequence-preferences.query-arguments';
import { MessagePreviewResponseDto } from './dto/response/message-preview.response';
import { CreateMessagePreviewQueryArguments } from './dto/query-arguments/create-message-preview.query-arguments';
import { UpdateScheduledMessageQueryArguments } from './dto/query-arguments/update-scheduled-message.query-arguments';
import { CancelConversationSequenceQueryArguments } from './dto/query-arguments/cancel-conversation-sequence.query-arguments';
import { UpdateLinkedinTaskQueryArguments } from './dto/query-arguments/update-linkedin-task.query-arguments';
import { CreateConversationSequenceQueryArguments } from './dto/query-arguments/create-conversation-sequence.query-arguments';
import { ContentPreferencesDto } from './dto/content-preferences.dto';
import { ConversationNextScheduledMessageResponseDto } from './dto/response/conversation-next-scheduled-message.response';
import { GetConversationNextScheduledMessageQueryArguments } from './dto/query-arguments/get-conversation-next-scheduled-message.query-arguments';
import {
  addLinkedinTaskInConversationByConversationIdOptimisticHandler,
  addLinkedinTaskInConversationsByConversationIdOptimisticHandler,
} from './optimistic-updates/mark-linkedin-task-as-done.optimistic';
import { UpdateCustomStepsQueryArguments } from './dto/query-arguments/update-custom-steps.query-arguments';
import { CustomStep } from './dto/sequence-preferences.dto';
import { SequenceModeEnum } from './enums/sequence-mode.enum';
import { ResumeCampaignQueryArguments } from './dto/query-arguments/resume-campaign-query.arguments';

export const sequenceQuery = createApi({
  reducerPath: REDUCER_PATH,
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getPositionSequencePreferences: builder.query<PositionSequencePreferencesResponseDto, GetPositionSequencePreferencesQueryArguments>({
      query: getPositionSequencePreferencesQuery,
    }),
    getConversationNextScheduledMessage: builder.query<ConversationNextScheduledMessageResponseDto, GetConversationNextScheduledMessageQueryArguments>({
      query: getConversationNextScheduledMessageQuery,
    }),
    createMessagePreview: builder.mutation<MessagePreviewResponseDto, CreateMessagePreviewQueryArguments>({
      query: createMessagePreviewQuery,
      async onQueryStarted(
        { positionId, data, accountId }, { dispatch, queryFulfilled },
      ) {
        const { data: updatedSequencePreferences } = await queryFulfilled;
        dispatch(
          sequenceQuery.util.updateQueryData('getPositionSequencePreferences', { positionId },
            // eslint-disable-next-line consistent-return
            (preferences) => {
              if (!preferences) {
                return {
                  id: '',
                  positionId,
                  accountId,
                  contentPreferences: {
                    toneOfVoice: data.toneOfVoice,
                    messageContent: data.messageContent,
                    spices: data.spices,
                    preview: {
                      subject: updatedSequencePreferences.subject,
                      message: updatedSequencePreferences.message,
                    },
                  } as ContentPreferencesDto,
                  createdAt: new Date(),
                  updatedAt: new Date(),
                };
              }
              if (preferences.contentPreferences) {
                preferences.contentPreferences.preview = {
                  subject: updatedSequencePreferences.subject,
                  message: updatedSequencePreferences.message,
                };
              }
            }),
        );
      },
    }),
    createPositionSequencePreferences: builder.mutation<PositionSequencePreferencesResponseDto, CreateOrUpdatePositionSequencePreferencesQueryArguments>({
      query: createPositionSequencePreferencesQuery,
      async onQueryStarted(
        { positionId }, { dispatch, queryFulfilled },
      ) {
        const { data: updatedSequencePreferences } = await queryFulfilled;
        dispatch(
          sequenceQuery.util.upsertQueryData('getPositionSequencePreferences', { positionId }, updatedSequencePreferences),
        );
      },
    }),
    createConversationSequence: builder.mutation<ConversationResponseDto, CreateConversationSequenceQueryArguments>({
      query: createConversationSequenceQuery,
      async onQueryStarted(
        { conversationId }, { dispatch, queryFulfilled },
      ) {
        const { data: conversation } = await queryFulfilled;
        dispatch(
          outreachQuery.util.updateQueryData('getConversationById', { conversationId }, () => {
            return conversation;
          }));
      },
    }),
    regenerateConversationSequence: builder.mutation<ConversationResponseDto, CreateConversationSequenceQueryArguments>({
      query: regenerateConversationSequenceQuery,
      async onQueryStarted(
        { conversationId }, { dispatch, queryFulfilled },
      ) {
        const { data: conversation } = await queryFulfilled;
        dispatch(
          outreachQuery.util.updateQueryData('getConversationById', { conversationId }, () => {
            return conversation;
          }));
      },
    }),
    updatePositionSequencePreferences: builder.mutation<PositionSequencePreferencesResponseDto, CreateOrUpdatePositionSequencePreferencesQueryArguments>({
      query: updatePositionSequencePreferencesQuery,
      async onQueryStarted(
        { positionId }, { dispatch, queryFulfilled },
      ) {
        let sequencePreferencesPatchResult;
        try {
          const { data: updatedSequencePreferences } = await queryFulfilled;
          sequencePreferencesPatchResult = dispatch(
            sequenceQuery.util.updateQueryData('getPositionSequencePreferences', { positionId }, () => {
              return updatedSequencePreferences;
            }),
          );
        } catch (e) {
          sequencePreferencesPatchResult?.undo();
        }
      },
    }),
    updateScheduledMessage: builder.mutation<void, UpdateScheduledMessageQueryArguments>({
      query: updateScheduledMessageQuery,
    }),
    cancelConversationSequence: builder.mutation<void, CancelConversationSequenceQueryArguments>({
      query: cancelConversationSequenceQuery,
      async onQueryStarted(
        { positionId, conversationId }, { dispatch, queryFulfilled },
      ) {
        const conversationsByPositionPatchResult = dispatch(
          outreachQuery.util.updateQueryData('getConversationsByPositionId', { positionId }, ({ conversations }) => {
            const foundConversation = conversations.find((conversation) => conversation.id === conversationId);
            if (foundConversation) {
              foundConversation.nextOpenLinkedinTask = undefined;
              foundConversation.nextScheduledMessage = undefined;
            }
          }));
        const conversationsPatchResult = dispatch(
          outreachQuery.util.updateQueryData('getConversations', undefined, ({ conversations }) => {
            const foundConversation = conversations.find((conversation) => conversation.id === conversationId);
            if (foundConversation) {
              foundConversation.nextOpenLinkedinTask = undefined;
              foundConversation.nextScheduledMessage = undefined;
            }
          }));
        const getConversationByIdPatchResult = dispatch(
          outreachQuery.util.updateQueryData('getConversationById', { positionId, conversationId }, (conversation) => {
            if (conversation) {
              conversation.nextOpenLinkedinTask = undefined;
              conversation.nextScheduledMessage = undefined;
            }
          }));
        try {
          await queryFulfilled;
        } catch (e) {
          conversationsByPositionPatchResult?.undo();
          conversationsPatchResult?.undo();
          getConversationByIdPatchResult?.undo();
        }
      },
    }),
    updateLinkedinTask: builder.mutation<LinkedinTaskResponseDto, UpdateLinkedinTaskQueryArguments>({
      query: updateLinkedinTaskQuery,
      async onQueryStarted(
        {
          positionId,
          conversationId,
          status,
          currentUser,
          task,
        }, { dispatch, queryFulfilled },
      ) {
        const conversationPatchResult = dispatch(
          outreachQuery.util.updateQueryData('getConversationById', { conversationId }, (conversation) => {
            if (conversation.nextOpenLinkedinTask && conversation.nextOpenLinkedinTask.id === task.id) {
              if (status === LinkedinTaskStatusEnum.Done) {
                addLinkedinTaskInConversationByConversationIdOptimisticHandler(task, conversation, currentUser);
                conversation.nextOpenLinkedinTask = undefined;
              } else {
                conversation.nextOpenLinkedinTask.status = status;
              }
            }
          }),
        );
        const conversationsPatchResult = dispatch(
          outreachQuery.util.updateQueryData('getConversationsByPositionId', { positionId }, ({ conversations }) => {
            if (status === LinkedinTaskStatusEnum.Done) {
              addLinkedinTaskInConversationsByConversationIdOptimisticHandler(task, conversationId, currentUser, conversations);
            }
          }),
        );
        try {
          await queryFulfilled;
        } catch (e) {
          conversationPatchResult?.undo();
          conversationsPatchResult?.undo();
        }
      },
    }),
    updateCustomSteps: builder.mutation<CustomStep[], UpdateCustomStepsQueryArguments>({
      query: updateCustomStepsQuery,
      async onQueryStarted(
        { positionId }, { dispatch, queryFulfilled },
      ) {
        const { data: updatedSequencePreferences } = await queryFulfilled;
        dispatch(
          sequenceQuery.util.updateQueryData('getPositionSequencePreferences', { positionId }, (draftSequencePreferences) => {
            return {
              ...draftSequencePreferences,
              sequencePreferences: {
                ...(draftSequencePreferences.sequencePreferences || {}),
                customSteps: updatedSequencePreferences,
              },
            } as PositionSequencePreferencesResponseDto;
          }),
        );
        dispatch(setSequenceMode(SequenceModeEnum.Custom));
      },
    }),
    resumeCampaign: builder.mutation<void, ResumeCampaignQueryArguments>({
      query: resumeCampaignQuery,
      async onQueryStarted({ positionId }, { dispatch, queryFulfilled }) {
        let sequencePreferencesPatchResult;
        try {
          const { data: updatedSequencePreferences } = await queryFulfilled;
          sequencePreferencesPatchResult = dispatch(
            sequenceQuery.util.updateQueryData('getPositionSequencePreferences', { positionId }, () => {
              return updatedSequencePreferences;
            }),
          );
        } catch (e) {
          sequencePreferencesPatchResult?.undo();
        }
      },
    }),
  }),
});
