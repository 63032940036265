/* eslint-disable no-template-curly-in-string */
import KEYS from './keys';

const STRINGS = {
  [KEYS.PLEASE_ENTER_INPUT_VALUE]: 'Please enter your {{inputName}}',
  [KEYS.PLEASE_ENTER_VALID_VALUE]: 'Please enter a valid {{valueName}}',
  [KEYS.EMAIL]: 'email',
  [KEYS.EMAIL_ADDRESS]: 'email address',
  [KEYS.PASSWORD]: 'password',
  [KEYS.LOGO_ALT]: '{{name}} Logo',
  [KEYS.CONTINUE]: 'Continue',
  [KEYS.CONFIRM]: 'Confirm',
  [KEYS.ENGAGED]: 'Liked',
  [KEYS.RECOMMENDED]: 'Recommended',
  [KEYS.DO]: 'Do',
  [KEYS.DONT]: 'Don\'t',
  [KEYS.MATCH_ENGAGEMENT_NOT_ALLOWED_ERROR]: 'Feedback was already given for this match. You can change it by clicking "Undo".',
  [KEYS.CANDIDATES_REVIEW]: 'Candidates To Review',
  [KEYS.TO_REVIEW]: 'To Review',
  [KEYS.TO_REACHOUT]: 'To Reachout',
  [KEYS.PRO]: 'Pro',
  [KEYS.SCALE]: 'Scale',
  [KEYS.SENT]: 'Sent',
  [KEYS.SEEN]: 'Seen',
  [KEYS.VIEW_INSIGHTS]: 'View insights',
  [KEYS.SUPPORT]: 'Support',
  [KEYS.LOGOUT]: 'Logout',
  [KEYS.ACTIVE]: 'Active',
  [KEYS.SELECT_ALL]: 'Select All',
  [KEYS.UNKNOWN_PLAN]: 'Unknown plan',
  [KEYS.COMING_SOON]: 'Coming Soon',
  [KEYS.DECLINED]: 'Passed',
  [KEYS.FACEBOOK]: 'Facebook',
  [KEYS.GITHUB]: 'GitHub',
  [KEYS.LINKEDIN]: 'LinkedIn',
  [KEYS.MEDIUM]: 'Medium',
  [KEYS.TWITTER]: 'Twitter',
  [KEYS.ENGAGE]: 'Like',
  [KEYS.DECLINE]: 'Pass',
  [KEYS.ACTION_UNDO]: 'Undo',
  [KEYS.REACH_OUT]: 'Reach out',
  [KEYS.CLOSE]: 'Close',
  [KEYS.ACTION]: 'Action',
  [KEYS.LATER]: 'Later',
  [KEYS.SKIP]: 'Skip',
  [KEYS.COPY]: 'Copy',
  [KEYS.EXPERIENCE]: 'Experience',
  [KEYS.EDUCATION]: 'Education',
  [KEYS.SKILLS]: 'Skills',
  [KEYS.PRESENT]: 'Present',
  [KEYS.UNKNOWN]: 'Unknown',
  [KEYS.GO_BACK_TO_HOMEPAGE]: 'Go back to homepage',
  [KEYS.GO_BACK_TO_AUTH]: 'Go back to login page',
  [KEYS.BACK_TO_LOGIN]: 'Back to login',
  [KEYS.BACK_TO_LOGIN_DESCRIPTION]: 'Just remembered? No worries just go to the login page',
  [KEYS.CANT_FIND_PAGE]: 'We can’t find the page you’re looking for',
  [KEYS.PAGE_MOVED_RENAMED_DELETED]:
    'The page you were looking may have been moved, renamed, or deleted.',
  [KEYS.NOVICE]: 'Novice',
  [KEYS.BEGINNER]: 'Begginer',
  [KEYS.COMPETENT]: 'Competent',
  [KEYS.PROFICIENT]: 'Proficient',
  [KEYS.EXPERT]: 'Expert',
  [KEYS.TODAY]: 'Today',
  [KEYS.TOMORROW]: 'Tomorrow',
  [KEYS.RESET_MY_PASSWORD]: 'Reset my password',
  [KEYS.ACTION_FAILED]: 'Action Failed',
  [KEYS.WHOOPS]: 'Whoops',
  [KEYS.OOPSIE]: 'Oopsie!',
  [KEYS.INVALID_LINK]: 'This link is not valid',
  [KEYS.OTHER]: 'other',
  [KEYS.SEE_LESS]: 'See Less',
  [KEYS.MOBILE_MESSAGE_NOT_US]: 'Its not you, its us...',
  [KEYS.MOBILE_MESSAGE_SUGGESTION]: 'But in the meantime, you are welcome to access ' +
  'it through any standard web-browser on your computer.',
  [KEYS.MOBILE_MESSAGE_NOT_SUPPORTED]: 'Perfect is not yet supported on mobile.',
  [KEYS.NOT_INTERESTED]: 'Not interested',
  [KEYS.SOCIAL_PROFILES]: 'Social Profiles',
  [KEYS.SEE_MORE]: 'See More',
  [KEYS.SEE_X_MORE]: 'See {{count}} More',
  [KEYS.AND_X_MORE]: 'and {{count}} more',
  [KEYS.LIKELIHOOD]: 'Likelihood to Move',
  [KEYS.LIKELY_TO_MOVE]: 'Likely To Move',
  [KEYS.SOMEWHAT_LIKELY]: 'Somewhat Likely',
  [KEYS.VERY_LIKELY]: 'Very Likely',
  [KEYS.UNLIKELY]: 'Unlikely',
  [KEYS.YEARS_EXPERIENCE_TITLE]: 'Relevant Experience',
  [KEYS.YEARS_EXPERIENCE_SUBTITLE]: 'Among {{totalYears}} years of total work experience.',
  [KEYS.START_ENGAGEMENT]: 'Start Engagement',
  [KEYS.ENGAGE_DIALOG_TITLE]: 'How would you like to move forward?',
  [KEYS.ENGAGE_DIALOG_SUBTITLE]: 'Pick your preferred option',
  [KEYS.NEW_MATCH_TALENT_BIO_SENTENCE_1_1]:
  '{{firstName}} is {{currentJobTitlePrefix}} <strong>{{currentJobTitle}}</strong>',
  [KEYS.NEW_MATCH_TALENT_BIO_SENTENCE_2_1]:
  '. For the past 🗓️ <strong>{{currentRolePeriod}}</strong>, {{firstName}} has been ' +
  'working as {{currentJobTitlePrefix}} <strong>💼 {{currentRole}} at {{currentEmployer}}.</strong>',
  [KEYS.NEW_MATCH_TALENT_BIO_SENTENCE_2_2]:
  '. {{firstName}} has been working for 🗓️ {{currentRolePeriod}} as {{currentJobTitlePrefix}} ' +
  '<strong>💼 {{currentRole}}.</strong>',
  [KEYS.NEW_MATCH_TALENT_BIO_SENTENCE_2_3]:
  '. {{firstName}} has been working for 🗓️ {{currentRolePeriod}} ' +
  '<strong>at {{currentEmployer}}.</strong>',
  [KEYS.NEW_MATCH_TALENT_BIO_SENTENCE_2_4]:
  ', and has been working as {{currentJobTitlePrefix}} ' +
  '💼 <strong>{{currentRole}} at {{currentEmployer}}.</strong>',
  [KEYS.X_YEARS]: '{{value}} Years',
  [KEYS.X_MONTHS]: '{{value}} Months',
  [KEYS.ONE_MONTH]: '1 Month',
  [KEYS.ONE_YEAR]: '1 Year',
  [KEYS.REFERENCE]: 'Reference',
  [KEYS.WORKED_WITH]: 'Worked with',
  [KEYS.IN]: 'in',
  [KEYS.PEOPLE]: '{{counter}} people',
  [KEYS.AND_MORE]: 'and more ...',
  [KEYS.AT]: 'at',
  [KEYS.AT_COMPANY]: 'at {{companyName}}',
  [KEYS.YEARS]: 'Years',
  [KEYS.COULD_NOT_REACH_TALENT]: "We couldn't reach {{firstName}}",
  [KEYS.ITS_YOUR_TURN]: "Sorry, perhaps it's your turn to try!",
  [KEYS.TALENT_NETWORK_BADGE_HIGH_SCORE_DESCRIPTION]: 'Knows well people in your network',
  [KEYS.TALENT_NETWORK_BADGE_MED_LOW_SCORE_DESCRIPTION]: 'Shared history and background with people from your company.',
  [KEYS.BILINGUAL]: 'Bilingual',
  [KEYS.MULTILINGUAL]: 'Multilingual',
  [KEYS.TRILINGUAL]: 'Trilingual',
  [KEYS.BILINGUAL_DESCRIPTION]: 'Fluent in {{languages}} and {{lastLanguage}}',
  [KEYS.TRILINGUAL_DESCRIPTION]: 'Fluent in {{languages}} and {{lastLanguage}}',
  [KEYS.MULTILINGUAL_DESCRIPTION]: 'Fluent in {{languages}} and more',
  [KEYS.EXPERIENCE_BADGE_TITLE]: 'Well Rounded Experience',
  [KEYS.WORKED_AT]: 'Worked at {{companies}}',
  [KEYS.ACADEMIC_EXCELLENCE]: 'Academic Excellence',
  [KEYS.ACADEMIC_EXCELLENCE_DESCRIPTION]: 'Attended an elite university',
  [KEYS.WORKED_AT_MULTI]: 'Worked at {{companies}} and more',
  [KEYS.CAREER_UPGRADE]: 'Career Upgrade',
  [KEYS.CAREER_UPGRADE_DESCRIPTION]: 'May be a step-up from current position.',
  [KEYS.SOMETHING_WENT_WRONG]:
    'Whoops, Something went wrong on our end 😔. Please refresh the page and try again.',
  [KEYS.ACTIVELY_LOOKING]: 'Actively Looking',
  [KEYS.ACTIVELY_LOOKING_DESCRIPTION]: 'Based on current LinkedIn status',
  [KEYS.ACTIVELY_LOOKING_LAYOFF_DESCRIPTION]: 'Due to recent layoffs at {{ companyName }}',
  [KEYS.CRUNCHBASE_RANKING]: 'Crunchbase Ranking',
  [KEYS.CRUNCHBASE_RANKING_DESCRIPTION]: 'Current company has lower ranking',
  [KEYS.SELECT_FROM_LIST]: 'Select from list',
  [KEYS.PROMOTION]: 'Promotion',
  [KEYS.A]: 'a',
  [KEYS.AN]: 'an',
  [KEYS.CURRENT_JOB_EXPERIENCE_YEARS]: '~{{currentJobExperienceYears}} years',
  [KEYS.CURRENT_JOB_EXPERIENCE_MONTHS]: '{{currentJobExperienceMonths}} months',
  [KEYS.CURRENT_JOB_EXPERIENCE_MONTH]: '{{currentJobExperienceMonth}} month',
  [KEYS.CURRENT_JOB_EXPERIENCE_YEAR]: '~{{currentJobExperienceYear}} year',
  [KEYS.TENURE_PERIOD]: 'Significant Tenure',
  [KEYS.REACHED_AVERAGE]: 'Is close to reaching the average tenure at {{companyName}}',
  [KEYS.TRIAL_END_BANNER.TITLE]: '{{firstName}}, your Perfect free trial has ended 😔',
  [KEYS.TRIAL_END_BANNER.DESCRIPTION]:
  'We can\'t really put a price on 🪄magic and rainbows. But… your free ' +
  'trial just expired. Upgrade to one of our paid plans to continue enjoying Perfect.',
  [KEYS.TRIAL_END_BANNER.BUTTON]: 'Keep Using Perfect',
  [KEYS.TRIAL_END_BANNER.ABOUT_TO_END.TITLE]:
  '{{firstName}}, your Perfect trial will end in a few days ⌛️',
  [KEYS.TRIAL_END_BANNER.ABOUT_TO_END.DESCRIPTION]:
  'We hope you love the taste of the pro experience. \n' +
  'To continue making the most out of Perfect, you\'ll need to upgrade to our pro plan, \n' +
  'It was made especially for teams like yours!',
  [KEYS.TRIAL_END_BANNER.KEEP_USING_TOGGLED]: 'We got your request to continue using Perfect, ' +
  '\n We\'ll get back to you in a jiffy!',
  [KEYS.TRIAL_END_BANNER.SCHEDULE_MEETING]: 'You can either schedule a quick call via <0>this link.</0>',
  [KEYS.CURRENT_PRODUCT_PLAN]: 'Current Plan:',
  [KEYS.DAYS_LEFT_ONE]: '{{count}} day left',
  [KEYS.DAYS_LEFT_PLURAL]: '{{count}} days left',
  [KEYS.PAID as string]: 'Paid Plan',
  [KEYS.GET_CONTACT_INFO]: 'Get contact info',
  [KEYS.REQUEST_ADDITIONAL_INFO]: 'YES! KEEP ON SEARCHIN’',
  [KEYS.GOT_REQUEST]: 'Alrighty, off we go',
  [KEYS.NOTIFY_YOU]: 'We\'ll notify you once we have found them.',
  [KEYS.REQUEST_CONTACT_INFO]: 'Request contact info',
  [KEYS.WE_CANT_FIND_CONTACT_INFO]: 'Bummer, we couldn\'t find the info of this contact.',
  [KEYS.SEARCH_CONTACT_INFO_FOR_YOU]: `But be sure - we'll keep on
  searching. Once we dig up something, you'll receive an update.`,
  [KEYS.ENGAGING_WITH]: 'We are engaging with {{name}}',
  [KEYS.CURRENTLY_ENGAGING]: 'We are currently engaging with {{name}} and' +
  ' will soon let you know if there is interest.',
  [KEYS.TALENT_IS_NOT_INTERESTED]: '{{firstName}} isn\'t interested',
  [KEYS.TAP_FOR_DETAILS]: 'Tap for details',
  [KEYS.TALENT_IS_WAITING_TO_HEAR_FROM_YOU]: 'Waiting to hear from you',
  [KEYS.TAP_FOR_DETAILS_AND_CONTACT_INFO]: 'Tap for details & contact info',
  [KEYS.ENGAGEMENT_REQUEST_BY]: 'Engagement requested by {{firstName}}, ',
  [KEYS.MATCH_ENGAGEMENT_STATUS_NOT_INTERESTED.NOT_LOOKING_FOR_NEW_OPPORTUNITIES]:
  'Not looking for a new opportunity',
  [KEYS.MATCH_ENGAGEMENT_STATUS_NOT_INTERESTED.OPEN_TO_OTHER_OPPORTUNITIES]:
  'Not interested but open to other opportunities',
  [KEYS.MATCH_ENGAGEMENT_STATUS_NOT_INTERESTED.OTHER_REASON]: 'Other',
  [KEYS.REQUESTED_ADDITIONAL_DETAILS]: 'We got your request for additional info.' +
  ' We\'ll get back to you soon.',
  [KEYS.ENGAGEMENT_IN_PROGRESS]: 'Engagement in progress',
  [KEYS.UPDATE_YOU_SOON]: 'We`ll update you soon...',
  [KEYS.DECLINED_DESCRIPTION]: 'by {{userName}} at {{timeStamp}}',
  [KEYS.ENGAGED_DESCRIPTION]: 'by {{userName}}, at {{timestamp}}',
  [KEYS.DEGREES.MASTER]: 'Master’s Degree',
  [KEYS.DEGREES.BACHELOR]: 'Bachelor’s Degree',
  [KEYS.DEGREES.PHD]: 'Doctorate (Ph.D.)',
  [KEYS.DEGREES.ASSOCIATE]: 'Associate Degree',
  [KEYS.ONBOARDING.ONBOARDING_LETS_BEGIN_TITLE]: 'Just how easy? This easy.',
  [KEYS.ONBOARDING.ONBOARDING_JOB_DESCRIPTION_TITLE]: 'Got a job description? (Optional)',
  [KEYS.ONBOARDING.ONBOARDING_JOB_DESCRIPTION_SUBTITLE]: 'Sharing it would help us fine-tune the search results. Sounds good, right?',
  [KEYS.ONBOARDING.ONBOARDING_CONTINUE_BUTTON]: 'Continue',
  [KEYS.ONBOARDING.ONBOARDING_ROLE_PLACEHOLDER]: 'Choose your role',
  [KEYS.ONBOARDING.ONBOARDING_JOBTITLE_SENTENCE]: 'I am looking for the perfect',
  [KEYS.ONBOARDING.ONBOARDING_I_AM]: 'and I’m',
  [KEYS.ONBOARDING.ONBOARDING_MY_NAME_IS]: 'My name is',
  [KEYS.ONBOARDING.ONBOARDING_COMPANY_PLACEHOLDER]: 'Type your company url',
  [KEYS.ONBOARDING.ONBOARDING_NAME_PLACEHOLDER]: 'Type your name',
  [KEYS.ONBOARDING.ONBOARDING_LOCATION_PLACEHOLDER]: 'Enter position location',
  [KEYS.ONBOARDING.ONBOARDING_EXPERIENCE_SENTENCE]: 'My {{role}} should be',
  [KEYS.ONBOARDING.ONBOARDING_EXPERIENCE_TOTAL_YEARS_ENTRY_LEVEL]: '{{from}} to {{to}} months (Fewer matches)',
  [KEYS.ONBOARDING.ONBOARDING_EXPERIENCE_TOTAL_YEARS]: '{{from}} to {{to}} years',
  [KEYS.ONBOARDING.ONBOARDING_TELL_US_MORE_PAGE_TITLE]:
    'Sweet. What are you looking for\nin this {{role}}',
  [KEYS.ONBOARDING.ONBOARDING_EXPERIENCE_PAGE_TITLE]:
    'Select the <0> level of </0> <1> relevant </1> <0> experience </0> \n needed for the role:',
  [KEYS.ONBOARDING.ONBOARDING_EXPERIENCE_PAGE_TITLE_TOOLTIP]:
    '\'Relevant\' means experience in similar roles or tasks important for this position. It helps match candidates who’ve done this role before or a similar one.',
  [KEYS.ONBOARDING.ONBOARDING_EDUCATION_PAGE_TITLE]:
    'Is there a 🎓 <0> degree </0> or 📚 <0> field of study </0>\n' +
    'needed for the role?\n',
  [KEYS.ONBOARDING.ONBOARDING_LETS_FIND_EM_BUTTON]: 'Let\'s find`Em',
  [KEYS.ONBOARDING.ONBOARDING_UPLOAD_JOB_DESCRIPTION_FIRST_TITLE]: 'Want to get super-specific?',
  [KEYS.ONBOARDING.ONBOARDING_UPLOAD_JOB_DESCRIPTION_SECOND_TITLE]: 'Upload a job description',
  [KEYS.ONBOARDING.ONBOARDING_UPLOAD_JOB_DESCRIPTION_PASTE_URL]:
   'https://example.com/jobs/job-id',
  [KEYS.OR]: 'Or',
  [KEYS.ONBOARDING.ONBOARDING_UPLOAD_JOB_DESCRIPTION_DRAG_DROP]: 'Drag n\' drop a PDF file',
  [KEYS.ONBOARDING.ONBOARDING_UPLOAD_JOB_DESCRIPTION_PDF]: 'of your job description',
  [KEYS.ONBOARDING.ONBOARDING_COMPANY_LINKEDIN_PROFILE]: 'Add your company\'s LinkedIn profile:',
  [KEYS.ONBOARDING.ONBOARDING_ADD_YOUR_COMPANY]:
    '<button>Add your company to our database</button>',
  [KEYS.ONBOARDING.ONBOARDING_VERIFICATION_TITLE]:
    'Check your email - we\'ve sent you a verification code.',
  [KEYS.ONBOARDING.ONBOARDING_VERIFICATION_DESCRIPTION]:
  'To create a tailor-made experience for your company, we\'ll need\n' +
  'to verify that you\'re you.',
  [KEYS.ONBOARDING.ONBOARDING_VERIFICATION_CODE_EMAILED]:
  'Place the code - and we\'re good to go:',
  [KEYS.ONBOARDING.ONBOARDING_VERIFICATION_CODE_VERIFICATION_FAILED]:
  'Nope, that’s not the code we sent. Please try again.',
  [KEYS.ONBOARDING.ONBOARDING_VERIFICATION_CODE_NOT_RECEIVED]: "Didn't get our email? Click here",
  [KEYS.ONBOARDING.ONBOARDING_VERIFICATION_CODE_SENT_AGAIN]:
   'We sent the code again.\nMake sure to refresh your inbox and check your spam folder.',
  [KEYS.ONBOARDING.ONBOARDING_VERIFICATION_CODE_ASK_HUMAN_HELP]:
   'We\'re here to the rescue',
  [KEYS.ONBOARDING.ONBOARDING_VERIFICATION_CODE_NO_LUCK]: 'No luck? No worries.',
  [KEYS.ONBOARDING.ONBOARDING_BUTTON_PERFECT]: 'Perfect',
  [KEYS.EXPORT_MATCHES.ACTION]: 'Export liked matches',
  [KEYS.EXPORT_MATCHES.SUCCESS_MESSAGE]: 'Export completed',
  [KEYS.EXPORT_MATCHES.SIDE_PANEL_BUTTON]: 'Download profile (.csv)',
  [KEYS.CLOSE_POSITION.ACTION]: 'Close this position',
  [KEYS.CLOSE_POSITION.TITLE]: 'Please choose a reason\n for closing this position',
  [KEYS.CLOSE_POSITION.POSITION_CANCELED]: 'The position was canceled',
  [KEYS.CLOSE_POSITION.POSITION_ALREADY_FULFILLED]: 'The position was already fulfilled',
  [KEYS.CLOSE_POSITION.OTHER]: 'Other reason',
  [KEYS.CLOSE_POSITION.BUTTON]: 'CLOSE POSITION',
  [KEYS.ONBOARDING.ONBOARDING_NOT_FOUND_COMPANY]: 'Hey! You used a link that we don\'t recognize.' +
  ' Would you mind checking that it\'s correct?',
  [KEYS.ONBOARDING.ONBOARDING_COMPANY_MODAL_PLACEHOLDER]:
    'Type (or paste) your company\'s Linkedin URL',
  [KEYS.ONBOARDING.ONBOARDING_UPLOAD_JOB_PUT_IN_CLOUD]: 'We\'ve put your file in the cloud.',
  [KEYS.CLOSE_POSITION.SOMETHING_ELSE_PLACEHOLDER]: 'Please type your other reason',
  [KEYS.ONBOARDING.ONBOARDING_FILE_TYPE_ERROR]: 'File type must be PDF',
  [KEYS.ONBOARDING.ONBOARDING_FILE_TOO_LARGE_ERROR]: 'Sorry, the file must be smaller than 5MB.',
  [KEYS.ONBOARDING.ONBOARDING_REMOVE_FILE]: 'Remove',
  [KEYS.ONBOARDING.ONBOARDING_SIGN_UP_TITLE]: 'Let\'s get started',
  [KEYS.ONBOARDING.ONBOARDING_SIGN_UP_SUBTITLE]: 'It will only take a minute ✨',
  [KEYS.ONBOARDING.ONBOARDING_SIGN_UP_CONTINUE_WITH_GOOGLE]: 'Continue with Google',
  [KEYS.ONBOARDING.ONBOARDING_SIGN_UP_CONTINUE_WITH_MICROSOFT]: 'Continue with Microsoft',
  [KEYS.ONBOARDING.ONBOARDING_SIGN_UP_DESCRIPTION]: 'or continue with work email',
  [KEYS.ONBOARDING.ONBOARDING_SIGN_UP_WORK_EMAIL_PLACEHOLDER]:
    'Your work email address',
  [KEYS.ONBOARDING.ONBOARDING_SIGN_UP_LETS_GO_BUTTON]: 'LET\'S GO',
  [KEYS.ONBOARDING.ONBOARDING_SIGN_UP_TERMS_OF_USE]:
    'By signing up, you agree to our <underline>Terms of Use</underline>',
  [KEYS.ONBOARDING.ONBOARDING_NOT_ALLOWED_COMPANY]:
    'Sign in with a work email to select.',
  [KEYS.ONBOARDING.ONBOARDING_SIGN_UP_CHOOSE_YOUR_PASSWORD]: 'Choose your password',
  [KEYS.ONBOARDING.ONBOARDING_SIGN_UP_TERMS_OF_USE_TITLE]: 'Terms of use',
  [KEYS.ONBOARDING.ONBOARDING_SIGN_UP_TERMS_OF_USE_SUBTITLE]:
  'We\'ve got some very basic privacy policies in place to protect you.',
  [KEYS.ONBOARDING.PROGRESS_BAR_STEPS.BASIC_INFO]: 'Basic info',
  [KEYS.ONBOARDING.PROGRESS_BAR_STEPS.EXPERIENCE]: 'Experience',
  [KEYS.ONBOARDING.PROGRESS_BAR_STEPS.BACKGROUND]: 'Background & Skills',
  [KEYS.ONBOARDING.PROGRESS_BAR_STEPS.EDUCATION]: 'Education',
  [KEYS.ONBOARDING.PROGRESS_BAR_STEPS.JOB_DESCRIPTION]: 'Job Description',
  [KEYS.ACCEPT]: 'accept',
  [KEYS.ACTIVATED]: 'Activated',
  [KEYS.NOT_ACTIVATED]: 'Not Activated',
  [KEYS.REQUEST_FAILED_TRY_AGAIN]: 'Failed to send your request, please try again',
  [KEYS.LOADING]: 'Loading...',
  [KEYS.ALERT]: 'Alert',
  [KEYS.EXIT]: 'Exit',
  [KEYS.TRY_AGAIN]: 'Try again',
  [KEYS.GLOBAL_ERROR_MESSAGE]: 'Oops, something went wrong. please try again.',
  [KEYS.START_ENGAGING_UPGRADE]: 'Do you want us to start engaging? No sweat.',
  [KEYS.INVITE_YOUR_TEAMMATES.INVITE_A_TEAMMATE]: 'Invite a teammate',
  [KEYS.INVITE_YOUR_TEAMMATES.INVITE_YOUR_TEAMMATES]: 'Invite your teammates',
  [KEYS.INVITE_YOUR_TEAMMATES.MODAL_DESCRIPTION]:
   ' Just type their work email address, and we\'ll do the rest.',
  [KEYS.INVITE_YOUR_TEAMMATES.INVITE_BUTTON]: 'invite',
  [KEYS.INVITE_YOUR_TEAMMATES.PLACEHOLDER]: 'Email address',
  [KEYS.INVITE_YOUR_TEAMMATES.EMAIL_NOT_VALID]: 'Email address is not valid.',
  [KEYS.INVITE_YOUR_TEAMMATES.EMAIL_EXISTS]:
   'Looks like <bold>{{firstName}}</bold> was already invited 😁',
  [KEYS.INVITE_YOUR_TEAMMATES.MY_TEAMMATES_TITLE]: 'My teammates ({{counter}})',
  [KEYS.INVITE_YOUR_TEAMMATES.INVITE_PENDING]: 'Invite pending',
  [KEYS.INVITE_YOUR_TEAMMATES.RESEND_INVITE]: 'resend invite',
  [KEYS.INVITE_YOUR_TEAMMATES.SIGN_UP_TITLE]: 'Set up your account',
  [KEYS.INVITE_YOUR_TEAMMATES.SIGN_UP_SUBTITLE]: 'Tell us a little bit about yourself',
  [KEYS.INVITE_YOUR_TEAMMATES.CURRENT_ROLE]: 'Your current role at {{companyName}}',
  [KEYS.INVITE_YOUR_TEAMMATES.START_USING_PERFECT]: 'Start using perfect',
  [KEYS.INVITE_YOUR_TEAMMATES.NAME_ERROR]:
  'Please enter your name so we\'ll know how to call you.',
  [KEYS.INVITE_YOUR_TEAMMATES.ACCOUNT_NAME_ERROR]:
  'Please enter your role so we can personalize your experience.',
  [KEYS.LEARN_MORE]: 'Learn More',
  [KEYS.WAITING_PAGE_TITLE]: 'Kick back & relax',
  [KEYS.WAITING_PAGE_SUBTITLE]: 'We’ll email you as soon as we find your matches.',
  [KEYS.ATS_INITIALIZATION_DIALOG.TITLE]: 'The way to carefree recruiting\n starts with automatic sync.',
  [KEYS.ATS_INITIALIZATION_DIALOG.DESCRIPTION]:
    'Integrating with your ATS/CRM tools is only a tap away. Get your liked talents’ contact info and personalized messaging flowing automatically, starting now.',
  [KEYS.ATS_INITIALIZATION_DIALOG.CHOOSE_ATS]: 'Choose your ATS to continue:',
  [KEYS.ATS_INITIALIZATION_DIALOG.CONTINUE_WITH]: 'Continue with',
  [KEYS.ATS_INITIALIZATION_DIALOG.TWO_WAY_ATS_INFO_TEXT]:
    '<strong>Two-Way ATS Sync</strong> - seamlessly sync and update candidate data between our Perfect platform and your ATS for enriched, up-to-date profiles.',
  [KEYS.ATS_INITIALIZATION_DIALOG.ENTER_API_KEY_SCREEN.TITLE]: 'Connect with {{atsName}}',
  [KEYS.ATS_INITIALIZATION_DIALOG.ENTER_API_KEY_SCREEN.DESCRIPTION]:
    'Link your ATS account using API key authentication.\n Having trouble getting api key? <0>Contact our CS</0>',
  [KEYS.ATS_INITIALIZATION_DIALOG.ENTER_API_KEY_SCREEN.INPUT_TITLE]: 'Enter your {{atsName}} API key',
  [KEYS.ATS_INITIALIZATION_DIALOG.ENTER_API_KEY_SCREEN.INPUT_PLACEHOLDER]: 'API Key',
  [KEYS.ATS_INITIALIZATION_DIALOG.ENTER_API_KEY_SCREEN.INVALID_API_KEY_ERROR_MESSAGE]: 'Invalid API Key',
  [KEYS.ATS_INITIALIZATION_DIALOG.CUSTOM_INTEGRATION_NAMES.COMEET]: 'Comeet',
  [KEYS.ATS_INITIALIZATION_DIALOG.CUSTOM_INTEGRATION_NAMES.GREENHOUSE]: 'Greenhouse',
  [KEYS.ATS_INITIALIZATION_DIALOG.CUSTOM_INTEGRATION_NAMES.ASHBY]: 'Ashby',
  [KEYS.YOUR_ATS]: 'your ATS',
  [KEYS.ADDING_TALENT_TO_ATS]: 'Adding talent to ATS...',
  [KEYS.ATS_AUTO_SYNC_TITLE]: 'Automatically add liked matches to: {{atsName}}',
  [KEYS.ATS_AUTO_INFO_TEXT]: 'All data related to your liked matches will be transferred and synced automatically between Perfect App to your ATS/CRM system.',
  [KEYS.ATS_ADD_TALENT_BUTTON_TEXT]: 'Add talent to ATS',
  [KEYS.ATS_TALENT_ADDED]: 'Talent added to ATS',
  [KEYS.ATS_TALENT_SYNCED_BY]: 'by {{name}}, at {{date}}',
  [KEYS.ATS_ADD_TALENT_ERROR_MESSAGE]: 'Cannot add talent to ats',
  [KEYS.ATS_LINK_POSITION_ERROR_MESSAGE]: 'Cannot link position to ats',
  [KEYS.ATS_LINK_POSITION_JOB_AUTOCOMPLETE_PLACEHOLDER]: 'Pick a role from ATS',
  [KEYS.ATS_LINK_POSITION_HELP_TEXT]: 'Have a question? <0>Ask away</0>',
  [KEYS.ATS_LINK_POSITION_MENU_ITEM]: 'Connect position to ATS',
  [KEYS.ATS_MANAGE_POSITION_LINK_MENU_ITEM]: 'Manage ATS sync',
  [KEYS.ATS_LINK_POSITION_MODAL_TITLE]: 'Sync position with ATS',
  [KEYS.ATS_LINK_POSITION_MODAL_DESCRIPTION]: 'Pick a role from the ATS list and easily connect to Perfect.',
  [KEYS.OPEN_MATCH_IN_ATS_BUTTON_TEXT]: 'Open in {{atsName}}',
  [KEYS.WAITING_PAGE_DESCRIPTIONS]:
  `This shouldn’t take more than a few hours.
    In the meantime, why don’t you make yourself a well-earned cappuccino ☕️`,
  [KEYS.SAVE_AND_SYNC]: 'Save & Sync',
  [KEYS.SAVE]: 'Save',
  [KEYS.ACTIVATE]: 'Activate',
  [KEYS.PRO_FEATURE_TITLE]: 'The things we do when you go Pro:',
  [KEYS.PRO_FEATURE_NOTE_1_TITLE]: 'Reach the best-fitting people via multiple channels.',
  [KEYS.PRO_FEATURE_NOTE_1_DESCRIPTION]: `Armed with our smart outreach engine,
  we’ll start with initial chitchat
  to get them on board and include all the info regarding the position and your company. `,
  [KEYS.PRO_FEATURE_NOTE_2_TITLE]: 'Avoid awkward cold calls.',
  [KEYS.PRO_FEATURE_NOTE_2_DESCRIPTION]: `We spare you the inconvenient initial reaching out
  to strangers and do the first step for you
  - through emails, phone calls, text messages,
  Linkedin, or Morse code (excuse us for the dad-joke).`,
  [KEYS.PRO_FEATURE_NOTE_3_TITLE]: 'Save precious time.',
  [KEYS.PRO_FEATURE_NOTE_3_DESCRIPTION]: `By reaching the right people for you,
  we place the foundations
   for the optimal recruiting process in the most effective way.`,
  [KEYS.UPGRADE]: 'Upgrade',
  [KEYS.REMOTE]: 'Remote',
  [KEYS.SPECIFIC_COUNTRY_REMOTE]: 'Anywhere in {{countryCode}}',
  [KEYS.US_UK_COUNTRY_REMOTE]: 'Anywhere in the {{countryCode}}',
  [KEYS.START_ENGAGEMENT_SUB_DESCRIPTION]: `Spoiler alert: we’ve
  got highly effective engagement methods
  and fantastic icebreakers.`,
  [KEYS.FIRST_NAME]: 'First name',
  [KEYS.LAST_NAME]: 'Last name',
  [KEYS.ACCOUNT_ACTIVATION.TITLE]: 'Welcome to perfect',
  [KEYS.ACCOUNT_ACTIVATION.SUBTITLE]: 'Set up your account',
  [KEYS.CONTINUE_WITH_GOOGLE_ACCOUNT]: 'Continue with Google account',
  [KEYS.CONTINUE_WITH_GOOGLE]: 'Continue with Google',
  [KEYS.CONTINUE_WITH_MICROSOFT]: 'Continue with Microsoft',
  [KEYS.START_EXPERIENCE_WITH_EMAIL]: 'or start the experience with email',
  [KEYS.CHOOSE_YOUR_PASSWORD]: 'Choose your password',
  [KEYS.CONFIRM_YOUR_PASSWORD]: 'Confirm your password',
  [KEYS.SIGN_UP_TERMS_OF_USE]:
  'By signing up, you agree to our <underline>Terms of Use</underline>',
  [KEYS.SIGN_UP_TERMS_OF_USE_TITLE]: 'Terms of use',
  [KEYS.SIGN_UP_TERMS_OF_USE_SUBTITLE]:
  'We\'ve got some very basic privacy policies in place to protect you.',
  [KEYS.PASSWORD_SHOULD]: 'Password should',
  [KEYS.RECRUITER_TITLE]: 'a recruiter',
  [KEYS.MANAGER_TITLE]: 'hiring for my team',
  [KEYS.ONBOARDING.ONBOARDING_SIGN_UP_ENTER_WORK_EMAIL]:
  'Sorry we couldn\'t find a user with that email. ' +
  'Please make sure you\'re typing your work email.',
  [KEYS.ONBOARDING.WELCOME_SCREEN_HELLO]: '👋 Hello {{name}}',
  [KEYS.ONBOARDING.WELCOME_SCREEN_STARTING_TODAY]: 'We\'re gonna make this',
  [KEYS.ONBOARDING.WELCOME_SCREEN_EASIEST_JOB]: 'ridiculously easy for you',
  [KEYS.ONBOARDING.DONE_SCREEN_MATCHING_PROGRESS]: 'Generating your first matches...',
  [KEYS.ONBOARDING.ONBOARDING_SIGN_UP_ENTER_VALID_EMAIL]: 'Please enter a valid email address.',
  [KEYS.ONBOARDING.ONBOARDING_SIGN_UP_ENTER_VALID_PASSWORD]:
   'Password should have at least 8 characters',
  [KEYS.ONBOARDING.USER_EXIST]: `You already have an account,
  so there's no need to sign up again. Just log in.`,
  [KEYS.ONBOARDING.ALREADY_HAVE_AN_ACCOUNT]: 'Already have an account?',
  [KEYS.ONBOARDING.CONTAINER_POSITION_INFO]: '<strong>{{ jobTitle }}</strong> • <strong><0 /></strong>',
  [KEYS.ONBOARDING.MULTI_SENTENCE_INPUT_AUTOCOMPLETE_SKIP_MESSAGE]: 'Not relevant?<0>Skip</0><1/>',
  [KEYS.ONBOARDING.EDUCATION_STEP.DEGREE_TITLE]: 'Choose Level of Degree',
  [KEYS.ONBOARDING.EDUCATION_STEP.FIELDS_OF_STUDY_TITLE]: 'Specify Field of Study',
  [KEYS.ONBOARDING.EDUCATION_STEP.FIELDS_OF_STUDY_INPUT_PLACEHOLDER]: 'Type any Field of study',
  [KEYS.ONBOARDING.BACKGROUND_STEP.TITLE]: 'Craft your ideal <0>candidate profile</0>',
  [KEYS.ONBOARDING.BACKGROUND_STEP.SENTENCE_INTRO]: 'My ideal candidate will have past',
  [KEYS.ONBOARDING.BACKGROUND_STEP.COMPANY_BACKGROUND_SECTION.INPUT_PLACEHOLDER]: 'e.g. Fin-tech, Healthcare...',
  [KEYS.ONBOARDING.BACKGROUND_STEP.COMPANY_BACKGROUND_SECTION.SECONDARY_INPUT_PLACEHOLDER]: 'Type another or skip',
  [KEYS.ONBOARDING.BACKGROUND_STEP.COMPANY_BACKGROUND_SECTION.SENTENCE_TEXT]: 'Industry experience in',
  [KEYS.ONBOARDING.BACKGROUND_STEP.COMPANY_BACKGROUND_SECTION.BLANK_INPUT_TEXT]: 'Any Background',
  [KEYS.ONBOARDING.BACKGROUND_STEP.SKILLS_SECTION.INPUT_PLACEHOLDER]: 'e.g. Python, Social marketing, Hubspot',
  [KEYS.ONBOARDING.BACKGROUND_STEP.SKILLS_SECTION.SECONDARY_INPUT_PLACEHOLDER]: 'Type another or skip',
  [KEYS.ONBOARDING.BACKGROUND_STEP.SKILLS_SECTION.SENTENCE_TEXT]: 'and be highly skilled in',
  [KEYS.ONBOARDING.BACKGROUND_STEP.SKILLS_SECTION.BLANK_INPUT_TEXT]: 'Any Skill',
  [KEYS.ONBOARDING.LOGIN]: 'Login',
  [KEYS.ONBOARDING.LINKEDIN_WRONG_FORMAT]:
    'Oops, it seems the link is invalid. Look for misspells or try again.',
  [KEYS.ONBOARDING.ONBOARDING_CUSTOM_EDUCATION_PLACEHOLDER]: 'Just type in your own words…',
  [KEYS.PRICE_PLAN.SCALE]: 'Scale',
  [KEYS.PRICE_PLAN.PRO]: 'Pro',
  [KEYS.PRICE_PLAN.TRIAL]: 'Pro Trial',
  [KEYS.PRICE_PLAN.BASIC]: 'Basic',
  [KEYS.PRICE_PLAN.LITE]: 'Lite',
  [KEYS.STATUS_TENURE_COMPARISON.EXCEEDING_TENURE]: 'exceeded',
  [KEYS.STATUS_TENURE_COMPARISON.APPROACHING_TENURE]: 'approach',
  [KEYS.STATUS_TENURE_COMPARISON_TITLE_EXCEEDING]: `{{ userName }} has
    <strong> exceeded </strong> their average tenure time.`,
  [KEYS.STATUS_TENURE_COMPARISON_TITLE_APPROACHING]: `{{ userName }} is
    about to <strong> approach </strong> their average tenure time.`,
  [KEYS.PERSONAL_TENURE]: 'Personal tenure',
  [KEYS.TIME_AT_COMPANY]: 'Time at {{ currentCompanyName }}',
  [KEYS.AVG_TENURE_TIME]: 'Avg tenure time ',
  [KEYS.PERSONAL_TENURE_COMPARISON]: 'Personal Tenure Comparison',
  [KEYS.ONBOARDING.JOB_TITLE_PLACEHOLDER]: 'Type the position name',
  [KEYS.LAYOFF_TALENT_ALERT_DESCRIPTION_PERCENTAGE]: `{{ companyName }} has downsized its workforce by
   {{ layoffPercentage }}%.
  Maybe looking for new opportunities`,
  [KEYS.LEARN_MORE_TITLE]: 'How it works',
  [KEYS.LEARN_MORE_DESCRIPTIONS]: `Based on each talent’s career path,
  professional background, and impactful industry-related events,
  we estimate their willingness to leave their workplace and join your forces.`,
  [KEYS.DO_NOT_HAVE_IT]: 'Don\'t have it?',
  [KEYS.ONBOARDING.COMPANY_SELECTION_ERROR]: 'Select a company from the list',
  [KEYS.ONBOARDING.LOCATION_SELECTION_ERROR]: 'Select a location from the list',
  [KEYS.ONBOARDING.JOB_REQUIREMENTS_SELECTION_ERROR]: 'Please select an option from the list.',
  [KEYS.PASSWORD_LENGTH_VALIDATION_ERROR]: 'Password should have at least 8 characters.',
  [KEYS.AT_LEAST_EIGHT_CHARACTERS]: 'At least 8 characters',
  [KEYS.AT_LEAST_ONE_UPPER_OR_LOWER_LETTER]: 'Include at least one upper or lower case letters',
  [KEYS.AT_LEAST_ONE_UPPER_AND_LOWER_LETTER]: 'Include at least one upper and lower case letters',
  [KEYS.CONTAINS_NUMBER_OR_LETTER]: 'Contain a number or symbol',
  [KEYS.PERFECT_PASSWORD]: 'Perfect password!',
  [KEYS.PERFECT]: 'Perfect',
  [KEYS.WELCOME_BACK_TO]: 'Welcome back to',
  [KEYS.SIGNUP]: 'Signup',
  [KEYS.JUST_NOW]: 'just now',
  [KEYS.ONBOARDING.PERSONAL_EMAIL_ERROR]: 'Something’s wrong. Make sure to use work email.',
  [KEYS.FROM_DATE]: 'From {{date}}',
  [KEYS.PRESS_ENTER]: 'Press <bold>enter</bold>',
  [KEYS.TYPE_AND_SELECT]: 'Type & Select from the <bold>list</bold>',
  [KEYS.SELECT_OR_PRESS_ENTER]: 'Select or press <bold>enter</bold>',
  [KEYS.DONT_HAVE_ACCOUNT]: 'Don’t have an account yet?',
  [KEYS.ONBOARDING.SELECT_FROM_THE_LIST]: 'Select from the <bold>list</bold>',
  [KEYS.WORK_EMAIL]: 'Your WORK email address',
  [KEYS.YOUR_PASSWORD]: 'Your password',
  [KEYS.YOUR_EMAIL]: 'Your email address',
  [KEYS.CONNECTED]: 'Connected',
  [KEYS.CONNECT]: 'Connect',
  [KEYS.LETS_GO]: 'Lets go',
  [KEYS.SHOW]: 'Show',
  [KEYS.HIDE]: 'Hide',
  [KEYS.LETS_ENGAGE_TITLE]: 'Awesome! Let’s engage with {{name}}',
  [KEYS.LET_YOU_KNOW]: 'We’ll update you once we track down the contact info.',
  [KEYS.WITHIN]: 'Within',
  [KEYS.NO_CONTACTS_MESSAGE]:
  `Unluckily, we couldn’t find the contact info in our database.
  But! We can broaden the search and look in external databases. Interested?`,
  [KEYS.SUGGESTED_MESSAGE]: 'Perfect’s suggested message',
  [KEYS.SUBJECT]: 'Subject',
  [KEYS.MESSAGE]: 'Message',
  [KEYS.COPY_MESSAGE]: 'Copy Message',
  [KEYS.OPEN_IN]: 'Open in',
  [KEYS.REQUEST_ADDITIONAL_GENERIC_ERROR]: 'Failed to request additional info, please try again',
  [KEYS.FORGOT_PASSWORD]: 'Forgot Password?',
  [KEYS.FORGOT_PASSWORD_PAGE_DESCRIPTION]: `That’s okay, just enter your email and
  we’ll send you an email with a link to create a new password.`,
  [KEYS.RESET_PASSWORD_PAGE_DESCRIPTION]: `It’s easy. Just enter a new password and
  click "Confirm"`,
  [KEYS.PASSWORD_UPDATED_MESSAGE]: 'Your new password has been updated',
  [KEYS.FORGOT_PASSWORD_EMAIL_SENT_MESSAGE]: 'We sent you a link to reset your password',
  [KEYS.CHECK_YOUR_EMAIL]: 'Check your email',
  [KEYS.FORGOT_YOUR]: 'Forgot your',
  [KEYS.CHOOSE_YOUR_NEW]: 'Choose your new',
  [KEYS.SIGNUP_ERRORS.UNDEFINED]: 'Action failed',
  [KEYS.SIGNUP_ERRORS.GOOGLE_LOGIN_FAILED]: 'There was an issue with Google login',
  [KEYS.SIGNUP_ERRORS.WORK_EMAIL]: 'Please connect with your work email',
  [KEYS.COPIED_SUCCESSFULLY]: 'Copied successfully',
  [KEYS.COPIED]: 'Copied',
  [KEYS.SEND_VIA_ATS]: 'Send & sync with ATS',
  [KEYS.SEND_VIA_PERFECT]: 'Send with Perfect',
  [KEYS.ATS_NOTE_1_DESCRIPTION]: `Once we integrate with your ATS/CRM tools,
  all candidate-related data is updated and synced automatically - from profiles to contact info.`,
  [KEYS.ATS_NOTE_2_DESCRIPTION]:
  'We keep your candidates, messages, and interviews flowing smoothly between platforms.',
  [KEYS.ATS_NOTE_3_DESCRIPTION]:
  'Easily send Perfect’s personalized message directly from your ATS/CRM.',
  [KEYS.PERFECT_MODAL_TITLE]: 'Do it ALL with Perfect messages',
  [KEYS.ATS_MODAL_TITLE]: 'Adios manual profile updating, hello automation! 🤖 ',
  [KEYS.REQUEST_MORE_MATCHES.REQUEST_MORE_MATCHES_TITLE]: 'In need of extra matches?',
  [KEYS.REQUEST_MORE_MATCHES.REQUEST_MORE_MATCHES_DESCRIPTION]:
  'Click below so we’ll carry on with the search. \n' +
  'Promise we’ll leave no stone unturned.',
  [KEYS.REQUEST_MORE_MATCHES.REQUESTED_MATCHES_TITLE]: 'Off we go!',
  [KEYS.REQUEST_MORE_MATCHES.REQUESTED_MATCHES_DESCRIPTION]:
  'Brand new matches are on their way to you. \n' +
  'We’ll send you a notification once they’re ready.',
  [KEYS.REQUEST_MORE_MATCHES.GET_MORE_MATCHES]: 'Get more matches',
  [KEYS.ADDITIONAL_FEEDBACK_MODAL.TITLE]: 'How\'s your experience so far?',
  [KEYS.ADDITIONAL_FEEDBACK_MODAL.SUBTITLE]: 'We want to hear your honest feedback.',
  [KEYS.ADDITIONAL_FEEDBACK_MODAL.INPUT_PLACEHOLDER]: 'Share your thoughts with us.',
  [KEYS.ADDITIONAL_FEEDBACK_MODAL.INPUT_ERROR]: 'Please enter your feedback.',
  [KEYS.FEEDBACK_NEEDED_MODAL.TITLE]: 'Your feedback is needed',
  [KEYS.FEEDBACK_NEEDED_MODAL.SUBTITLE]:
  'Giving feedback on the matches we’ve found will help us optimize the \n' +
  'results for you (a classic win-win situation).',
  [KEYS.FEEDBACK_NEEDED_MODAL.BOTTOM_TITLE]: 'Review all the matches to continue:',
  [KEYS.FEEDBACK_NEEDED_MODAL.IMAGE_ALT]: 'Feedback needed',
  [KEYS.INSIGHTS_COMPONENTS.FIRST_BULLET_EXPLANATION_TITLE]: 'Forming an ideal talent profile',
  [KEYS.INSIGHTS_COMPONENTS.FIRST_BULLET_EXPLANATION_DESCRIPTION]: 'To fit your vision perfectly.',
  [KEYS.INSIGHTS_COMPONENTS.SECOND_BULLET_EXPLANATION_TITLE]: 'Looking through our data',
  [KEYS.INSIGHTS_COMPONENTS.SECOND_BULLET_EXPLANATION_DESCRIPTION]: 'More precisely, from 160M profiles at 65K companies.',
  [KEYS.INSIGHTS_COMPONENTS.THIRD_BULLET_EXPLANATION_TITLE]: 'Picking incredible talents',
  [KEYS.INSIGHTS_COMPONENTS.THIRD_BULLET_EXPLANATION_DESCRIPTION]: 'That fit your ideal profile.',
  [KEYS.INSIGHTS_COMPONENTS.FOURTH_BULLET_EXPLANATION_TITLE]: 'Improving as we go',
  [KEYS.INSIGHTS_COMPONENTS.FOURTH_BULLET_EXPLANATION_DESCRIPTION]:
    'Based on your feedback, we find the talents that fit your changing hiring needs and make results more accurate.',
  [KEYS.INSIGHTS_COMPONENTS.LAST_BULLET_LABEL]: 'Yay! Matches are ready for review',
  [KEYS.INSIGHT_WIDGETS.BACK_TO_MATCHES_BUTTON_TEXT]: 'Back to matches',
  [KEYS.INSIGHT_WIDGETS.POSITION_INSIGHTS_PAGE_TITLE]: 'Position Insights',
  [KEYS.INSIGHT_WIDGETS.POSITION_OVERVIEW_CARD_TITLE]: 'Position\'s overview',
  [KEYS.INSIGHT_WIDGETS.COMPANY_INSIGHTS_CARD_TITLE]: 'Company Insights',
  [KEYS.INSIGHT_WIDGETS.INDUSTRY_INSIGHTS_CARD_TITLE]: 'Industry Insights',
  [KEYS.INSIGHT_WIDGETS.EXPERIENCE_INSIGHT_CARD_TITLE]: 'Experience Insight',
  [KEYS.INSIGHT_WIDGETS.ROLE_INSIGHT_CARD_TITLE]: 'Role Insight',
  [KEYS.INSIGHT_WIDGETS.SIMILAR_TITLES_CARD_TITLE]: 'Similar Titles',
  [KEYS.INSIGHT_WIDGETS.EDUCATION_CARD_TITLE]: 'Education',
  [KEYS.INSIGHT_WIDGETS.PREVIOUS_COMPANIES_CARD_TEXT]: 'The most common companies <0>{{ companyName }}</0> employees <0>used to work for:</0>',
  [KEYS.INSIGHT_WIDGETS.EMPLOYER_BY_INDUSTRY_CARD_TEXT]: '<0>{{ companyName }}</0> is the top employer in the <1>{{ industryName }}</1> industry.',
  [KEYS.INSIGHT_WIDGETS.EDUCATION_SCHOOLS_CARD_TEXT]: 'Most of the {{ jobTitle }}s <0>studied</0> at <1>{{ schoolName }}</1>.',
  [KEYS.INSIGHT_WIDGETS.INDUSTRIES_INSIGHT_CARD_TEXT]:
    '<0>{{ percentage }}%</0> of {{ jobTitle }}s are employed in the <1>{{ industryName }} industry</1>.',
  [KEYS.INSIGHT_WIDGETS.MEDIAN_EXPERIENCE_CARD_TEXT]:
    '<0>{{ medianYearTenure }} Years</0> is the <0><1>median experience</1></0> for {{ jobTitle }}s',
  [KEYS.INSIGHT_WIDGETS.FUNNEL_OVERVIEW_CARD_TEXT]: 'Here’s what we found on <0>{{ jobTitle }}s:',
  [KEYS.INSIGHT_WIDGETS.MEDIAN_EXPERIENCE_THIS_ROLE_LABEL]: 'This role',
  [KEYS.INSIGHT_WIDGETS.MEDIAN_EXPERIENCE_OTHER_ROLES_LABEL]: 'Other tech roles',
  [KEYS.INSIGHT_WIDGETS.FIELD_OF_STUDY_CARD_TEXT]:
    '<0>{{ percentage }}%</0> of {{ jobTitle }}s are graduates of <1>{{ fieldOfStudyName }} studies</1>.',
  [KEYS.INSIGHT_WIDGETS.EDUCATION_DEGREE_CARD_TEXT]: 'Most of the {{ jobTitle }}s are <0>{{ degreeName }}’s degree graduates.</0>',
  [KEYS.INSIGHT_WIDGETS.SIMILAR_TITLES_CARD_TEXT]: '<0>Positions</0> that {{ jobTitle }} share qualities with:',
  [KEYS.INSIGHT_WIDGETS.ROLE_JOURNEY_CARD_TEXT]: '<0>A common career path</0> for {{ jobTitle }}s:',
  [KEYS.INSIGHT_WIDGETS.FUNNEL_OVERVIEW_FIRST_BAR_TEXT]: 'Talents in this role',
  [KEYS.INSIGHT_WIDGETS.FUNNEL_OVERVIEW_SECOND_BAR_TEXT]: 'With required qualifications',
  [KEYS.INSIGHT_WIDGETS.FUNNEL_OVERVIEW_THIRD_BAR_TEXT]: 'Located in {{ cityName }}',
  [KEYS.INSIGHT_WIDGETS.FUNNEL_OVERVIEW_FOURTH_BAR_TEXT]: 'May consider workplace change',
  [KEYS.INSIGHT_WIDGETS.INSIGHTS_DISCLAIMER]: 'Insights are solely based on US data.',
  [KEYS.CREATE_POSITION.ERRORS.TITLE]: 'Whoops, Something went wrong on our end. Please try again',
  [KEYS.CREATE_POSITION.ERRORS.TITLE_QUOTA]: 'You’ve used up the positions-quota',
  [KEYS.CREATE_POSITION.ERRORS.TITLE_EXPIRED]: 'Your Trial has expired',
  [KEYS.CREATE_POSITION.ERRORS.ACTION_TITLE]: 'Upgrade',
  [KEYS.CREATE_POSITION.ERRORS.DESCRIPTION]: 'To create a new position:',
  [KEYS.CREATE_POSITION.CREATE_POSITION_OPEN_BUTTON]: 'create position',
  [KEYS.CREATE_POSITION.CREATE_POSITION_LETS_BEGIN_TITLE]: 'Let’s create your position:',
  [KEYS.CREATE_POSITION.CREATE_POSITION_CONFIRM_MESSAGE]: 'Are you sure you want to leave? your changes will be lost.',
  [KEYS.UPGRADE_PLAN_DIALOG.TRIAL_ENDED.SUBTITLE]: 'Time’s Up!',
  [KEYS.UPGRADE_PLAN_DIALOG.TRIAL_ENDED.TITLE]: '✨ Magic and 🌈 Rainbows Aren\'t Free, But Almost!',
  [KEYS.UPGRADE_PLAN_DIALOG.TRIAL_ENDED.DESCRIPTION]:
    'Looks like your plan has just expired. We’ve enjoyed helping you make hiring almost magical. ' +
    'To continue your journey and keep all the features you love, let’s find the perfect plan for you.',
  [KEYS.UPGRADE_PLAN_DIALOG.MATCH_DAILY_LIMIT.SUBTITLE]: 'You’ve Hit Your Daily Limit!',
  [KEYS.UPGRADE_PLAN_DIALOG.MATCH_DAILY_LIMIT.TITLE]: '✨ Unlock More Daily Matches & More!',
  [KEYS.UPGRADE_PLAN_DIALOG.MATCH_DAILY_LIMIT.DESCRIPTION]:
    'Ready to elevate your experience? Upgrade now and transform how you achieve success with Perfect. Choose the plan that best fits your journey.',
  [KEYS.UPGRADE_PLAN_DIALOG.EXCEED_POSITION_LIMIT.SUBTITLE]: 'You’ve Hit Your Position Limit!',
  [KEYS.UPGRADE_PLAN_DIALOG.NEED_MORE_MATCHES.SUBTITLE]: 'Need more matches?',
  [KEYS.UPGRADE_PLAN_DIALOG.DEFAULT.SUBTITLE]: 'Upgrade your plan',
  [KEYS.UPGRADE_PLAN_DIALOG.DEFAULT.TITLE]: '✨ Unlock More Magic!',
  [KEYS.UPGRADE_PLAN_DIALOG.DEFAULT.DESCRIPTION]:
    'Ready to elevate your experience? Upgrade now and transform how you achieve success with Perfect. Choose the plan that best fits your journey.',
  // BUY MORE POSITION DIALOG
  [KEYS.PRO_DOWNGRADED_DIALOG.FOOTER_TITLE]: 'Only 749$/month',
  [KEYS.PRO_DOWNGRADED_DIALOG.FOOTER_SUB_TITLE]: 'Billed annually, 30-day money back guarantee.',
  [KEYS.PRO_DOWNGRADED_DIALOG.HEADER_TITLE]: 'Miss the PRO benefits?',
  [KEYS.PRO_DOWNGRADED_DIALOG.HEADER_SUB_TITLE]:
    'You’re always welcome to make a comeback and return to Pro Plan. The features remained awesome plus new features are constantly added:',
  [KEYS.PRO_DOWNGRADED_DIALOG.BUTTON_TITLE]: 'Upgrade to PRO',
  [KEYS.PRO_DOWNGRADED_DIALOG.SUGGESTIONS.UNLIMITED_POSITIONS]: 'Unlimited positions (up to 5 in parallel)',
  [KEYS.PRO_DOWNGRADED_DIALOG.SUGGESTIONS.UNLIMITED_DAILY_MATCHES]: 'Double amount of daily matches',
  [KEYS.PRO_DOWNGRADED_DIALOG.SUGGESTIONS.ACCESS_ALL_FEATURES]: 'Access to all features',
  // DAILY LIMIT
  [KEYS.DAILY_LIMIT.NEXT_MATCHES_BATCH_TIME]: 'More matches will be available approximately {{day}} at {{hour}}',
  [KEYS.DAILY_LIMIT.PRO_USER.TITLE]: 'Daily Match Limit Reached',
  [KEYS.DAILY_LIMIT.PRO_USER.DESCRIPTION]:
    'You’ve reached today’s {{dailyLimitSize}} match quota for this position. Need even more flexibility? ' +
    'Consider upgrading to our Custom plan for unrestricted matching.',
  [KEYS.DAILY_LIMIT.PRO_USER.PRIMARY_BUTTON]: 'View Upgrade Options',
  [KEYS.DAILY_LIMIT.PRO_USER.SECONDARY_BUTTON]: 'Reachout to your matches',
  [KEYS.DAILY_LIMIT.FREE_USER.TITLE]: 'You’ve Reached Today’s Match Limit!',
  [KEYS.DAILY_LIMIT.FREE_USER.DESCRIPTION]:
    'You’ve hit the {{dailyLimitSize}} match limit for this position under your {{planName}} plan. ' +
    'Ready for more daily matches? Upgrade to a plan with increased limits or consider our Custom plan for the ultimate flexibility.',
  [KEYS.DAILY_LIMIT.FREE_USER.UPGRADE_TO_PRO_BUTTON]: 'get more matches',
  [KEYS.DAILY_LIMIT.FREE_USER.PRIMARY_BUTTON]: 'View Upgrade Options',
  [KEYS.DAILY_LIMIT.FREE_USER.SECONDARY_BUTTON]: 'Reachout to your matches',
  [KEYS.DAILY_LIMIT.INSIGHTS.TITLE]: 'Position Insights',
  [KEYS.DAILY_LIMIT.INSIGHTS.ENGAGED_MATCHES_TITLE]: 'liked matches',
  [KEYS.DAILY_LIMIT.INSIGHTS.ENGAGED_MATCHES_ACTION_TITLE]: 'reach out',
  [KEYS.DAILY_LIMIT.INSIGHTS.ACCEPTANCE_RATE_MATCHES_TITLE]: 'acceptance rate',
  [KEYS.DAILY_LIMIT.INSIGHTS.ACCEPTANCE_RATE_ACTION_TITLE]: 'raise numbers',
  [KEYS.DAILY_LIMIT.INSIGHTS.SCREENING_RATE_TITLE]: 'talent rarity',
  [KEYS.DAILY_LIMIT.INSIGHTS.SCREENING_RATE_DESCRIPTION]: 'Of the talents suit your unique preferences',
  [KEYS.DAILY_LIMIT.INSIGHTS.TIME_SAVED_TITLE]: 'time saved',
  [KEYS.DAILY_LIMIT.INSIGHTS.TIME_SAVED_DESCRIPTION]: 'Compared to sourcing talents on any other place',
  // Engaged side panel
  [KEYS.ENGAGE_PANEL.TITLE]: '{{ name }}\'s Details',
  [KEYS.ENGAGE_PANEL.ABOUT_TITLE]: 'About',
  [KEYS.ENGAGE_PANEL.CONTACT_DETAILS_TITLE]: 'Contact Details',
  [KEYS.ENGAGE_PANEL.CONTACT_DETAILS_GO_TO_MATCH_BUTTON]: 'Full Profile',
  [KEYS.ENGAGE_PANEL.SOCIAL_INFO_TITLE]: 'Social Profiles',
  [KEYS.ENGAGE_PANEL.MISSING_CONTACTS.BOLD_TEXT]: 'Oops! We couldn’t find any contact details for this match, ',
  [KEYS.ENGAGE_PANEL.MISSING_CONTACTS.TEXT]: 'but you can reach out to them on their socials.',
  [KEYS.ENGAGE_PANEL.UPLOAD_ATS_BUTTON.UPLOAD]: 'Upload to ATS',
  [KEYS.ENGAGE_PANEL.UPLOAD_ATS_BUTTON.UPLOADING]: 'Adding to {{ name }}…',
  [KEYS.ENGAGE_PANEL.UPLOAD_ATS_BUTTON.UPLOADED]: '<0>Uploaded to </0> <1>{{ name }}</1>',
  [KEYS.ENGAGE_PANEL.SEE_MORE_BUTTON.SHOW]: 'see more...',
  [KEYS.ENGAGE_PANEL.SEE_MORE_BUTTON.HIDE]: 'see less...',
  [KEYS.ENGAGE_PANEL.SUGGESTED_MESSAGE.TITLE]: 'Suggested Message',
  [KEYS.ENGAGE_PANEL.TALENT_NETWORK.TITLE]: 'Possible connections',
  // payment success dialog
  [KEYS.PAYMENT_SUCCESS_DIALOG.TITLE]: 'Welcome to Pro!',
  [KEYS.PAYMENT_SUCCESS_DIALOG.DESCRIPTION]:
    'Congratulations on upgrading to the Pro Plan!\n' +
    'You’re all set to enjoy expanded capabilities and enhanced features designed to supercharge your recruitment efforts.',
  [KEYS.PAYMENT_SUCCESS_DIALOG.BUTTON_TITLE]: 'Continue to perfect',
  [KEYS.BUY_POSITION_DIALOG.TITLE]: 'Need More Positions?',
  [KEYS.BUY_POSITION_DIALOG.DESCRIPTION]:
    'You’ve reached your plan’s limit of active positions.\nYou can add another position for ${{positionPrice}}\nor close an existing position to free up space.',
  [KEYS.BUY_POSITION_DIALOG.PRIMARY_BUTTON_TITLE]: 'Add for ${{positionPrice}}',
  [KEYS.BUY_POSITION_DIALOG.SECONDARY_BUTTON_TITLE]: 'Back to homepage',
  [KEYS.ACTIVE_POSITION_PURCHASE_DIALOG.TITLE]: 'Position Added Successfully!',
  [KEYS.ACTIVE_POSITION_PURCHASE_DIALOG.DESCRIPTION]: 'You’ve successfully expanded your recruitment capabilities.\n Your new position is ready for setup.',
  [KEYS.ACTIVE_POSITION_PURCHASE_DIALOG.PRIMARY_BUTTON_TITLE]: 'Create position',
  [KEYS.ACTIVE_POSITION_PURCHASE_DIALOG.SECONDARY_BUTTON_TITLE]: 'Back to homepage',
  [KEYS.UPGRADE_TO_SCALE_DIALOG.TITLE]: 'Ready for More?',
  [KEYS.UPGRADE_TO_SCALE_DIALOG.DESCRIPTION]:
    'You’ve reached the maximum number of positions for your plan.\n Contact our sales team to tailor your plan to your growing needs.',
  [KEYS.UPGRADE_TO_SCALE_DIALOG.PRIMARY_BUTTON_TITLE]: 'Contact sales',
  [KEYS.UPGRADE_TO_SCALE_DIALOG.SECONDARY_BUTTON_TITLE]: 'Go Back',
  // payment success dialog
  // matches were almost gone dialog
  [KEYS.MATCHES_WERE_ALMOST_GONE_DIALOG.CLOSE_TITLE]: 'alrighty',
  [KEYS.MATCHES_WERE_ALMOST_GONE_DIALOG.HEADER_EMOJI]: '😮‍💨',
  [KEYS.MATCHES_WERE_ALMOST_GONE_DIALOG.TITLE]: 'Your matches are almost gone',
  [KEYS.MATCHES_WERE_ALMOST_GONE_DIALOG.DESCRIPTION]: 'Make sure to Like or Pass them before they’re gone for good, and to keep new matches coming.',
  // first time experience dialog
  [KEYS.POSITION_INTRODUCTION_PAGE.TOP_TITLE]: 'Your matches are here',
  [KEYS.POSITION_INTRODUCTION_PAGE.TITLE]: '<0>Like or Pass to make it </0> <1> your own </1> <2/>',
  [KEYS.POSITION_INTRODUCTION_PAGE.FOOTER_TITLE]: 'Are you ready?',
  [KEYS.POSITION_INTRODUCTION_PAGE.BUTTON_TEXT]: 'Yes! Let’s hit the road',
  [KEYS.POSITION_INTRODUCTION_PAGE.DESCRIPTION]:
  '<0>We learned A LOT about {{ companyName }} and tracked down matches that fit your organization. </0><br>' +
  '<1/><0> Like or </0><2/> <0>Pass them to add your personal taste and find the perfect fit!</0>',
  [KEYS.POSITION_INTRODUCTION_SCHEDULE_MEETING_PAGE.TITLE]: 'We’re working on your matches',
  [KEYS.POSITION_INTRODUCTION_SCHEDULE_MEETING_PAGE.SUBTITLE]:
    'Your position requires some deeper learning, so you can expect matches soon - We’ll notify you once they’ve arrived!',
  [KEYS.MATCH_TUNE.MATCHES_READY]: 'Matches Ready!',
  [KEYS.MATCH_TUNE.TITLE]: 'Noticed your matches could be sharper.\n What area would you like to tweak?',
  [KEYS.MATCH_TUNE.SUBTITLE]: 'A Little Nudge for Better Matches',
  [KEYS.MATCH_TUNE.TITLE_MANUAL]: 'What area would you like to tweak',
  [KEYS.MATCH_TUNE.SUBTITLE_MANUAL]: 'Edit your matching preferences',
  [KEYS.MATCH_TUNE.CONFIRM_BUTTON]: 'Confirm & Rematch',
  [KEYS.MATCH_TUNE.SKIP_BUTTON]: 'No Tweaks Needed',
  [KEYS.MATCH_TUNE.STEPS_CARDS.LOCATIONS]: 'Candidate Location',
  [KEYS.MATCH_TUNE.STEPS_CARDS.LEVELS_OF_EXPERIENCE]: 'Level of Experience',
  [KEYS.MATCH_TUNE.STEPS_CARDS.COMPANY_BACKGROUND]: 'Industry Experience',
  [KEYS.MATCH_TUNE.STEPS_CARDS.SKILLS]: 'Specific Skill',
  [KEYS.MATCH_TUNE.STEPS_CARDS.COMPANY_SIZES]: 'Company Size',
  [KEYS.MATCH_TUNE.STEPS_CARDS.JOB_TITLE]: 'Role Name',
  [KEYS.MATCH_TUNE.STEPS_CARDS.EDUCATION]: 'Education',
  [KEYS.MATCH_TUNE.STEPS_CARDS.POSITION_FEEDBACK]: 'Something Else',
  [KEYS.MATCH_TUNE.REQUIREMENT_CONTAINER.INPUT_PLACEHOLDER]: 'Type to add another',
  [KEYS.MATCH_TUNE.REQUIREMENT_CONTAINER.NOTIFICATION_TIP.INFO_LABEL]: 'Tip',
  [KEYS.MATCH_TUNE.REQUIREMENT_CONTAINER.NOTIFICATION_TIP.TITLE]: 'Super important? Must have?',
  [KEYS.MATCH_TUNE.REQUIREMENT_CONTAINER.NOTIFICATION_TIP.DESCRIPTION]: 'Click to mark it as important',
  [KEYS.MATCH_TUNE.REQUIREMENT_CONTAINER.NOTIFICATION_TIP.BUTTON_TEXT]: 'Got it',
  [KEYS.MATCH_TUNE.LOCATIONS_STEP.TITLE]: 'Location, Location, Location!',
  [KEYS.MATCH_TUNE.LOCATIONS_STEP.SUBTITLE]: 'Are these locations spot on?\n Update them if needed.',
  [KEYS.MATCH_TUNE.LOCATIONS_STEP.PRIMARY_LOCATION_PLACEHOLDER]: 'Enter primary location',
  [KEYS.MATCH_TUNE.LOCATIONS_STEP.PRIMARY_LOCATION_LABEL]: 'Primary location',
  [KEYS.MATCH_TUNE.LOCATIONS_STEP.SECONDARY_LOCATION_PLACEHOLDER]: 'Enter secondary location',
  [KEYS.MATCH_TUNE.LOCATIONS_STEP.SECONDARY_LOCATION_LABEL]: 'Secondary location',
  [KEYS.MATCH_TUNE.LOCATIONS_STEP.ADD_ANOTHER_LOCATION_BUTTON_TEXT]: 'Add another location',
  [KEYS.MATCH_TUNE.LOCATIONS_STEP.MILE_RADIUS_SELECT_LABEL]: 'Radius',
  [KEYS.MATCH_TUNE.LOCATIONS_STEP.MILE_RADIUS_SELECT_VALUE]: '{{mileRadius}} Miles',
  [KEYS.MATCH_TUNE.LOCATIONS_STEP.MILE_RADIUS_SELECT_VALUE_EXACT_LOCATION]: 'Exact location',
  [KEYS.MATCH_TUNE.LOCATIONS_STEP.LOCATION_MENU_ITEM_TYPE.AREA]: 'Area',
  [KEYS.MATCH_TUNE.LOCATIONS_STEP.LOCATION_MENU_ITEM_TYPE.CITY]: 'City',
  [KEYS.MATCH_TUNE.LOCATIONS_STEP.LOCATION_MENU_ITEM_TYPE.STATE]: 'State',
  [KEYS.MATCH_TUNE.LEVEL_OF_EXPERIENCE_STEP.TITLE]: 'Let’s Revisit Experience',
  [KEYS.MATCH_TUNE.LEVEL_OF_EXPERIENCE_STEP.SUBTITLE]: 'Is Your <0>{{relevanceType}}</0> Experience Criteria Spot On?',
  [KEYS.MATCH_TUNE.LEVEL_OF_EXPERIENCE_STEP.RELEVANT_LABEL]: 'Relevant',
  [KEYS.MATCH_TUNE.LEVEL_OF_EXPERIENCE_STEP.OVERALL_LABEL]: 'Overall',
  [KEYS.MATCH_TUNE.LEVEL_OF_EXPERIENCE_STEP.RELEVANCE_MENU.RELEVANT_TITLE]: 'Relevant experience',
  [KEYS.MATCH_TUNE.LEVEL_OF_EXPERIENCE_STEP.RELEVANCE_MENU.RELEVANT_DESCRIPTION]:
    'Experience in similar roles or tasks important for this position. It helps match candidates who’ve done this role before or a similar one.',
  [KEYS.MATCH_TUNE.LEVEL_OF_EXPERIENCE_STEP.RELEVANCE_MENU.OVERALL_TITLE]: 'Overall experience',
  [KEYS.MATCH_TUNE.LEVEL_OF_EXPERIENCE_STEP.RELEVANCE_MENU.OVERALL_DESCRIPTION]:
    'Total years of professional experience in any capacity. It helps match candidates with a broad range of experiences, not limited to specific roles.',
  [KEYS.MATCH_TUNE.LEVEL_OF_EXPERIENCE_STEP.SUBTITLE]: 'Is Your <0>{{relevanceType}}</0> Experience Criteria Spot On?',
  [KEYS.MATCH_TUNE.EDUCATION_STEP.TITLE]: 'Education',
  [KEYS.MATCH_TUNE.EDUCATION_STEP.SUBTITLE]: 'Do these degree levels and fields of study match your needs?',
  [KEYS.MATCH_TUNE.EDUCATION_STEP.FIELD_OF_STUDY_EMPTY_STATE_TEXT]: 'No Fields of Study Added',
  [KEYS.MATCH_TUNE.EDUCATION_STEP.DEGREE_TITLE]: 'Choose Level of Degree',
  [KEYS.MATCH_TUNE.EDUCATION_STEP.FIELD_OF_STUDY_TITLE]: 'Specify Field of Study',
  [KEYS.MATCH_TUNE.INDUSTRY_EXPERIENCE_STEP.TITLE]: 'Industry Experience',
  [KEYS.MATCH_TUNE.INDUSTRY_EXPERIENCE_STEP.SUBTITLE]: 'Right industry experience?\n Add or adjust as needed.',
  [KEYS.MATCH_TUNE.INDUSTRY_EXPERIENCE_STEP.EMPTY_STATE_TEXT]: 'You haven’t selected any industry backgrounds yet',
  [KEYS.MATCH_TUNE.COMPANY_SIZE_STEP.TITLE]: 'Company sizes',
  [KEYS.MATCH_TUNE.COMPANY_SIZE_STEP.SUBTITLE]: 'Are these company sizes\n the right fit for your needs?',
  [KEYS.MATCH_TUNE.SKILLS_STEP.TITLE]: 'Skill Spotlight',
  [KEYS.MATCH_TUNE.SKILLS_STEP.SUBTITLE]: 'Are these skills exactly what you need?\n Want to add or change any?',
  [KEYS.MATCH_TUNE.SKILLS_STEP.EMPTY_STATE_TEXT]: 'You haven’t selected any skills yet',
  [KEYS.MATCH_TUNE.JOB_TITLE_STEP.TITLE]: 'Role Title',
  [KEYS.MATCH_TUNE.JOB_TITLE_STEP.SUBTITLE]: 'Role Title Check: Spot-on or Needs Tweaks?',
  [KEYS.MATCH_TUNE.JOB_TITLE_STEP.JOB_TITLE_INPUT_TITLE]: 'Your current role title is',
  [KEYS.MATCH_TUNE.JOB_TITLE_STEP.JOB_TITLE_INPUT_PLACEHOLDER]: 'Type to add another title',
  [KEYS.MATCH_TUNE.JOB_TITLE_STEP.SIMILAR_TITLES_TITLE]: 'Similar Titles Included in Your Search',
  [KEYS.MATCH_TUNE.JOB_TITLE_STEP.SIMILAR_TITLES_DESCRIPTION]: 'Press ‘X’ to exclude any title, or ‘STAR’ to mark it as a favorite for more precise matches.',
  [KEYS.MATCH_TUNE.JOB_TITLE_STEP.SIMILAR_TITLES_LOADING_TEXT]: 'Analysing similar titles...',
  [KEYS.MATCH_TUNE.POSITION_FEEDBACK_STEP.TITLE]: 'Tell Us More!',
  [KEYS.MATCH_TUNE.POSITION_FEEDBACK_STEP.SUBTITLE]: 'Got any other thoughts\n or tweaks in mind?',
  [KEYS.MATCH_TUNE.POSITION_FEEDBACK_STEP.INPUT_PLACEHOLDER]: 'Type your feedback',
  [KEYS.MATCH_TUNE.POSITION_FEEDBACK_STEP.SUCCESS_SNACKBAR_TITLE]: 'Thanks',
  [KEYS.MATCH_TUNE.POSITION_FEEDBACK_STEP.SUCCESS_SNACKBAR_DESCRIPTION]: 'Diving Into Your Detailed Feedback',
  [KEYS.MATCH_TUNE.PREFERENCES_BUTTON_TEXT]: 'Preferences',
  [KEYS.MATCH_TUNE.BYEBIAS_BUTTON_TEXT]: '{{ByeBias}}',
  [KEYS.MATCH_TUNE.BYEBIAS_POPUP_TITLE]: 'Reduce Unconscious Bias',
  [KEYS.MATCH_TUNE.BYEBIAS_POPUP_DESCRIPTION]: 'ByeBias hides candidate names and images to help reduce unconscious bias related to gender, ' +
  'race, or ethnicity, letting you focus on skills and experiences.',
  [KEYS.MATCH_TUNE.BYEBIAS_POPUP_LEARN_MORE]: 'Learn more',
  [KEYS.MATCH_TUNE.BYEBIAS_POPUP_ACTIVATED]: '{{ByeBias}} Activated',
  [KEYS.MATCH_TUNE.BYEBIAS_POPUP_DISABLED]: '{{ByeBias}} Disabled',
  [KEYS.MATCH_TUNE.BYEBIAS_TOOLTIP_TITLE]: '{{ByeBias}} mode hides the name and photo to reduce bias.',
  [KEYS.MATCH_TUNE.BYEBIAS_TOOLTIP_DESCRIPTION]: 'Add to Outreach to reveal. You can always disable {{ByeBias}} if needed.',
  [KEYS.TALENT_NETWORK.TITLE.LOW]: 'Low connection',
  [KEYS.TALENT_NETWORK.TITLE.MEDIUM]: 'Medium connection',
  [KEYS.TALENT_NETWORK.TITLE.STRONG]: 'Strong connection',
  [KEYS.TALENT_NETWORK.HIGH_SCORE_TITLE]: 'Highly reachable',
  [KEYS.TALENT_NETWORK.MED_LOW_SCORE_TITLE]: 'Somewhat Reachable',
  [KEYS.TALENT_NETWORK.TIPS.LABEL]: 'Our tip: ',
  [KEYS.TALENT_NETWORK.TIPS.LOW_CONNECTION]: 'Reference this joint history between the talent and your company employees in the message',
  [KEYS.TALENT_NETWORK.TIPS.MEDIUM_CONNECTION]: 'Mention {{name}} and elaborate on the shared background in your message',
  [KEYS.TALENT_NETWORK.TIPS.STRONG_CONNECTION]: 'Ask {{name}} for an introduction.',
  [KEYS.TALENT_NETWORK.TIPS.UPGRADE_TO_PRO]: 'Unlock access to Pro to enjoy this feature and many more.',
  [KEYS.TALENT_NETWORK.TIMELINE.MULTIPLE]: ' and <0>{{talentsCount}} more connections.</0>',
  [KEYS.TALENT_NETWORK.TIMELINE.EXPERIENCE.WORKED_WITH]: '👩‍💻 Worked with <0>{{name}}</0> from your company.',
  [KEYS.TALENT_NETWORK.TIMELINE.EXPERIENCE.SAME_ROLE]: '🤟 Worked at the same role with <0>{{name}}</0> from your company.',
  [KEYS.TALENT_NETWORK.TIMELINE.EXPERIENCE.SIMILAR_ROLE]: '🤟 Shared a similar role with <0>{{name}}</0> at {{companyName}}',
  [KEYS.TALENT_NETWORK.TIMELINE.EXPERIENCE.SAME_COMPANY_SITE]: '🤟 Worked at the same {{companyName}} site with <0>{{name}}</0> from your company.',
  [KEYS.TALENT_NETWORK.TIMELINE.EDUCATION.SAME_CLASS]: '🎓 A former classmate of <0>{{name}}</0> from your company.',
  [KEYS.TALENT_NETWORK.TIMELINE.EDUCATION.SAME_MAJOR]: '🎓 Majored in the same department as <0>{{name}}</0> from your company.',
  [KEYS.TALENT_NETWORK.TIMELINE.EDUCATION.SAME_UNIVERSITY]: '🎓 Graduate of {{institutionName}} like <0>{{name}}</0> from your company.',
  [KEYS.TALENT_NETWORK.TIMELINE.EDUCATION.SAME_TIME]: '🎓 Went to {{institutionName}} at the same time as <0>{{name}}</0> from your company.',
  [KEYS.TALENT_NETWORK.TIMELINE.FIRST_TIME_EXPERIENCE.TITLE]: 'Insider Network',
  [KEYS.TALENT_NETWORK.TIMELINE.FIRST_TIME_EXPERIENCE.TEXT]: '<0>Your Insider Network, working for you:</0> Find your next talent using those you\'ve already got.',
  [KEYS.TALENT_NETWORK.DIALOG.TITLE]: 'Insider Network',
  [KEYS.TALENT_NETWORK.DIALOG.SUBTITLE]: '{{scoreTitle}}: {{connectionsAmount}} mutual contacts',
  [KEYS.TALENT_NETWORK.DIALOG.INFO_TEXT]:
    'Your insider network is based on contacts you meet along your career path, from classmates to teammates. <0>learn more about Insider Network</0>',
  [KEYS.TALENT_NETWORK.CARDS.EDUCATION_AND_EXPERIENCE]: '✌️ Both worked at {{companyName}} and studied at {{institutionName}}.',
  [KEYS.TALENT_NETWORK.CARDS.EXPERIENCE.WORKED_WITH]: '👯‍ Both worked in the same role at {{companyName}}',
  [KEYS.TALENT_NETWORK.CARDS.EXPERIENCE.SAME_ROLE]: '🤟 Were on the same {{jobTitle}} team at {{companyName}}',
  [KEYS.TALENT_NETWORK.CARDS.EXPERIENCE.SIMILAR_ROLE]: '🤟 Shared a similar role with {{talentName}} at {{companyName}}.',
  [KEYS.TALENT_NETWORK.CARDS.EDUCATION.SAME_UNIVERSITY]: '🎓 Alumni of {{institutionName}}',
  [KEYS.OUTREACH_PAGE.FILTER.STATUS_ITEM_TITLE]: 'Status',
  [KEYS.OUTREACH_PAGE.ARCHIVED_CONVERSATION.DEFAULT.TITLE]: 'Conversation Archived',
  [KEYS.OUTREACH_PAGE.ARCHIVED_CONVERSATION.EMAIL_LESS.TITLE]: 'Archived automatically: No email found',
  [KEYS.OUTREACH_PAGE.ARCHIVED_CONVERSATION.EMAIL_LESS.TOOLTIP]: 'This match was tucked away into the archives because we couldn’t find an email address. ' +
  'You can always revisit or adjust your <Underline>Auto-Archive Email-Less Matches</Underline> setting.',
  [KEYS.OUTREACH_PAGE.EMAILS.EMAIL_LESS.TITLE]: 'Email-Less Filter',
  [KEYS.OUTREACH_PAGE.EMAILS.EMAIL_LESS.TOOLTIP]: 'Prefer outreach with direct email? ' +
  'Enable the <Underline>Email-Less Filter</Underline> to exclude matches without email addresses, keeping your outreach panel neat tidy.',
  [KEYS.OUTREACH_PAGE.CONVERSATION_HEADER.ARCHIVED_CONVERSATION.TOOLTIP]: 'Conversation Archived',
  [KEYS.OUTREACH_PAGE.CONVERSATION_HEADER.ARCHIVED_CONVERSATION.CONFIRMATION.TITLE]: 'Confirm Conversation Archive',
  [KEYS.OUTREACH_PAGE.CONVERSATION_HEADER.ARCHIVED_CONVERSATION.CONFIRMATION.DESCRIPTION]:
    'Archiving will move this conversation to ‘Archived’. Please note, this action is irreversible.',
  [KEYS.OUTREACH_PAGE.CONVERSATION_HEADER.ARCHIVED_CONVERSATION.CONFIRMATION.CONFIRM_BUTTON_TITLE]: 'Confirm Archive',
  [KEYS.OUTREACH_PAGE.CONVERSATION_HEADER.ARCHIVED_CONVERSATION.CONFIRMATION.CANCEL_BUTTON_TITLE]: 'Cancel',
  [KEYS.OUTREACH_PAGE.SIGNATURE_DIALOG.TITLE]: 'Import your email signature',
  [KEYS.OUTREACH_PAGE.SIGNATURE_DIALOG.STEP_1.TITLE]: 'Step 1 - Send Your Signature',
  [KEYS.OUTREACH_PAGE.SIGNATURE_DIALOG.STEP_1.DESCRIPTION]: 'Send an email from your account with your signature to the unique address below.',
  [KEYS.OUTREACH_PAGE.SIGNATURE_DIALOG.STEP_2.TITLE]: 'Step 2 - Preview Your Signature',
  [KEYS.OUTREACH_PAGE.SIGNATURE_DIALOG.STEP_2.DESCRIPTION]: 'Your signature will appear here after you send the email.',
  [KEYS.OUTREACH_PAGE.SIGNATURE_DIALOG.STEP_3.DESCRIPTION]:
    '<strong>Having trouble?</strong> Make sure the email was sent from the correct address (the one connected to Outreach). if you still don’t see your signature, ',
  [KEYS.OUTREACH_PAGE.SIGNATURE_DIALOG.STEP_3.LINK_TO_SUPPORT]: '<IntercomLink>let us know—we’d love to help!</IntercomLink>',
  [KEYS.OUTREACH_PAGE.SIGNATURE_DIALOG.ACTION.CANCEL]: 'Cancel',
  [KEYS.OUTREACH_PAGE.SIGNATURE_DIALOG.ACTION.CONFIRM]: 'Confirm Signature',
  [KEYS.OUTREACH_PAGE.SIGNATURE_DIALOG.ACTION.REFRESH]: 'Refresh now',
  [KEYS.OUTREACH_PAGE.PREFERENCES_CONFIRMATION_MESSAGE]: 'Are you sure you want to leave this page without saving? This will leave things as they were.',
  [KEYS.OUTREACH_PAGE.CONVERSATIONS_GROUP.PREFERENCES.GEN_AI_TITLE]: 'Gen-AI Preferences',
  [KEYS.OUTREACH_PAGE.CONVERSATIONS_GROUP.PREFERENCES.CAMPAIGN_TITLE]: 'Campaigns',
  [KEYS.OUTREACH_PAGE.CONVERSATIONS_GROUP.PREFERENCES.MANUAL_LABEL]: 'manual',
  [KEYS.OUTREACH_PAGE.CONVERSATIONS_GROUP.PREFERENCES.AUTO_LABEL]: 'auto',
  [KEYS.OUTREACH_PAGE.CONVERSATIONS_GROUP.PREFERENCES.CUSTOM_LABEL]: 'custom',
  [KEYS.OUTREACH_PAGE.CONVERSATIONS_GROUP.SECTION_TITLE]: 'Inbox',
  [KEYS.OUTREACH_PAGE.CONVERSATIONS_GROUP.NESTED_PANEL.TITLE]: '{{ number }} Matches',
  [KEYS.OUTREACH_PAGE.CONVERSATIONS_GROUP.NESTED_PANEL.OPEN_TASKS_TITLE]: 'Open tasks',
  [KEYS.OUTREACH_PAGE.CONVERSATIONS_GROUP.NESTED_PANEL.CONVERSATIONS_TITLE]: 'Conversations',
  [KEYS.OUTREACH_PAGE.CONVERSATIONS_GROUP.GROUP_NAME.REPLIED]: 'Replied',
  [KEYS.OUTREACH_PAGE.CONVERSATIONS_GROUP.GROUP_NAME.TO_OUTREACH]: 'To Outreach',
  [KEYS.OUTREACH_PAGE.CONVERSATIONS_GROUP.GROUP_NAME.TO_FOLLOWUP]: 'To Follow Up',
  [KEYS.OUTREACH_PAGE.CONVERSATIONS_GROUP.GROUP_NAME.SENT]: 'Sent',
  [KEYS.OUTREACH_PAGE.CONVERSATIONS_GROUP.GROUP_NAME.ARCHIVED]: 'Archived',
  [KEYS.OUTREACH_PAGE.CONVERSATIONS_GROUP.GROUP_NAME.ALL]: 'All steps',
  [KEYS.OUTREACH_PAGE.CONVERSATIONS_GROUP.GROUP_NAME.COMPLETED]: 'Completed',
  [KEYS.OUTREACH_PAGE.CONVERSATIONS_GROUP.GROUP_NAME.STEP]: 'Step {{stepNumber}}',
  [KEYS.OUTREACH_PAGE.FILTER.LIKED_BY_ITEM_TITLE]: 'Matches liked by',
  [KEYS.OUTREACH_PAGE.FILTER.LICKED_BY_SUB_MENU_TITLE]: 'Select user',
  [KEYS.OUTREACH_PAGE.FILTER.FILTER_BY_STATUS.NEW_TITLE]: 'New',
  [KEYS.OUTREACH_PAGE.FILTER.FILTER_BY_STATUS.SENT_TITLE]: 'Sent',
  [KEYS.OUTREACH_PAGE.FILTER.FILTER_BY_STATUS.REPLIED_TITLE]: 'Replied',
  [KEYS.OUTREACH_PAGE.FILTER.CLEAR_ITEM_TITLE]: 'Clear',
  [KEYS.OUTREACH_PAGE.CONVERSATION_ITEM.SEND_MESSAGE]: 'Send a message',
  [KEYS.OUTREACH_PAGE.CONVERSATION_ITEM.TASKS_MESSAGE]: 'Send via linkedin',
  [KEYS.OUTREACH_PAGE.CONVERSATION_ITEM.SCHEDULED_MESSAGE]: 'Scheduled',
  [KEYS.OUTREACH_PAGE.CONVERSATION_ITEM.ARCHIVED]: 'Archived',
  [KEYS.OUTREACH_PAGE.CONVERSATION_ITEM.ERROR_MESSAGE]: 'Failed to send 😞',
  [KEYS.OUTREACH_PAGE.CONVERSATION_ITEM.BOUNCED_ERROR_MESSAGE]: 'Email Delivery Failure',
  [KEYS.OUTREACH_PAGE.CONVERSATION_MESSAGE.TRIGGER_TYPE.SUGGESTED_FOLLOWUP]: 'Suggested followup',
  [KEYS.OUTREACH_PAGE.CONVERSATION_MESSAGE.TRIGGER_TYPE.LINKEDIN_TASK]: 'Task',
  [KEYS.OUTREACH_PAGE.CONVERSATION_MESSAGE.TRIGGER_TYPE.AUTOMATED]: 'Automated',
  [KEYS.OUTREACH_PAGE.SPICES.insiderNetwork]: 'Insider network',
  [KEYS.OUTREACH_PAGE.SPICES.likelyhoodToMove]: 'Likelihood to move',
  [KEYS.OUTREACH_PAGE.SPICES.relevantSkills]: 'Relevant skills',
  [KEYS.OUTREACH_PAGE.SPICES.relevantEducation]: 'Relevant education',
  [KEYS.OUTREACH_PAGE.SPICES.similarCompanies]: 'Similar companies',
  [KEYS.OUTREACH_PAGE.TONE_OF_VOICE.AUTO]: 'Auto',
  [KEYS.OUTREACH_PAGE.TONE_OF_VOICE.PROFESSIONAL_AND_FORMAL]: 'Professional and Formal',
  [KEYS.OUTREACH_PAGE.TONE_OF_VOICE.FRIENDLY_AND_CONVERSATIONAL]: 'Friendly and Conversational',
  [KEYS.OUTREACH_PAGE.TONE_OF_VOICE.ENTHUSIASTIC_AND_ENERGETIC]: 'Enthusiastic and Energetic',
  [KEYS.OUTREACH_PAGE.TONE_OF_VOICE.INSPIRATIONAL_AND_ASPIRATIONAL]: 'Inspirational and Aspirational',
  [KEYS.OUTREACH_PAGE.TONE_OF_VOICE.DIRECT_AND_CONCISE]: 'Direct and Concise',
  [KEYS.OUTREACH_PAGE.TONE_OF_VOICE.EMPATHETIC_AND_UNDERSTANDING]: 'Empathetic and Understanding',
  [KEYS.OUTREACH_PAGE.TONE_OF_VOICE.INFORMATIVE_AND_DETAILED]: 'Informative and Detailed',
  [KEYS.OUTREACH_PAGE.TONE_OF_VOICE.LIGHTHEARTED_AND_HUMOROUS]: 'Lighthearted and Humorous',
  [KEYS.OUTREACH_PAGE.TONE_OF_VOICE.PROFESSIONAL_POLISHED]: 'Professional and polished',
  [KEYS.OUTREACH_PAGE.SEARCH.TITLE]: 'Messages',
  [KEYS.OUTREACH_PAGE.SEARCH.SEARCH_PLACEHOLDER]: 'Find in outreach',
  [KEYS.OUTREACH_PAGE.FILTER_CARDS.TITLE_LIKED]: 'recently liked',
  [KEYS.OUTREACH_PAGE.FILTER_CARDS.TITLE_REPLIES]: 'new replies',
  [KEYS.OUTREACH_PAGE.EMPTY_CONVERSATION.TITLE]: 'Reach out with some AI magic:',
  [KEYS.OUTREACH_PAGE.EMPTY_CONVERSATION.TITLE_LABEL]: 'Outreach',
  [KEYS.OUTREACH_PAGE.EMPTY_CONVERSATION.DESCRIPTION]: 'Increase your chance of connecting with potential future colleagues, minus the effort.\n' +
  'Approach liked matches with generated messages while keeping your data private and safe.',
  [KEYS.OUTREACH_PAGE.EMPTY_CONVERSATION_NO_INTEGRATION.TITLE]: 'Talent Outreach AI',
  [KEYS.OUTREACH_PAGE.EMPTY_CONVERSATION_NO_INTEGRATION.TITLE_LABEL]: 'Elevate your reach-out game with',
  [KEYS.OUTREACH_PAGE.EMPTY_CONVERSATION_NO_INTEGRATION.DESCRIPTION]: 'Connect your email to let us approach liked matches using generated messages - and ' +
  'increase your chance of connecting with potential colleagues.',
  [KEYS.OUTREACH_PAGE.EMPTY_CONVERSATION_NO_INTEGRATION.CONNECT_EMAIL_INFO_TEXT]: '(While keeping your data private and safe, of course).',
  [KEYS.OUTREACH_PAGE.EMPTY_CONVERSATION_NO_INTEGRATION.CONNECT_EMAIL_BUTTON]: 'connect email',
  [KEYS.OUTREACH_INITIALIZATION_DIALOG.TITLE]: 'Choose your work email host',
  [KEYS.OUTREACH_INITIALIZATION_DIALOG.DESCRIPTION]: 'And let the fun of AI-generated reach-out messages begin.',
  [KEYS.OUTREACH_INITIALIZATION_DIALOG.BROWSER_COMPATIBILITY]: 'Please continue the email connection on a <bold>Chrome Browser<bold>',
  [KEYS.OUTREACH_INITIALIZATION_DIALOG.GENERAL_ERROR_MESSAGE]: 'Something wrong with your email.',
  [KEYS.OUTREACH_INITIALIZATION_DIALOG.PERMISSIONS_ERROR_MESSAGE]: 'Your mailbox access is denied due to insufficient permissions.',
  [KEYS.OUTREACH_INITIALIZATION_DIALOG.READ_PERMISSIONS_INFO_TEXT]:
    '<strong>Allow Email Read Permissions</strong> - Automatically sync email data for accurate communication tracking.',
  [KEYS.OUTREACH_EMAIL_CONNECTED_SUCCESSFULLY_DIALOG.HEADLINE]: 'Email connected successfully!',
  [KEYS.OUTREACH_EMAIL_CONNECTED_SUCCESSFULLY_DIALOG.TITLE]: 'Welcome to Outreach',
  [KEYS.OUTREACH_EMAIL_CONNECTED_SUCCESSFULLY_DIALOG.TITLE]: 'Welcome to Outreach',
  [KEYS.OUTREACH_EMAIL_CONNECTED_SUCCESSFULLY_DIALOG.DESCRIPTION]: 'Let Outreach AI-generated direct messaging do the hard work of reaching out' +
  'to your liked matches.',
  [KEYS.OUTREACH_EMAIL_CONNECTED_SUCCESSFULLY_DIALOG.ACTION_BUTTON_TEXT]: 'Send a message',
  [KEYS.OUTREACH_PAGE.EDITOR.MENTIONS.MENU_ITEMS_TITLE.TALENT]: 'Match details',
  [KEYS.OUTREACH_PAGE.EDITOR.MENTIONS.MENU_ITEMS_TITLE.SENDER]: 'Sender',
  [KEYS.OUTREACH_PAGE.EDITOR.MENTIONS.MENU_ITEMS.TALENT_FIRST_NAME]: 'First name',
  [KEYS.OUTREACH_PAGE.EDITOR.MENTIONS.MENU_ITEMS.TALENT_LAST_NAME]: 'Last name',
  [KEYS.OUTREACH_PAGE.EDITOR.MENTIONS.MENU_ITEMS.TALENT_CURRENT_COMPANY_NAME]: 'Current company name',
  [KEYS.OUTREACH_PAGE.EDITOR.MENTIONS.MENU_ITEMS.TALENT_YEARS_EXPERIENCE]: 'Total years of relevant experience',
  [KEYS.OUTREACH_PAGE.EDITOR.MENTIONS.MENU_ITEMS.SENDER_NAME]: 'Name',
  [KEYS.OUTREACH_PAGE.EDITOR.MENTIONS.MENU_ITEMS.ACCOUNT_COMPANY_NAME]: 'Company',
  [KEYS.OUTREACH_PAGE.EDITOR.SAVE_CHANGES_BUTTON_TEXT]: 'Save changes',
  [KEYS.OUTREACH_PAGE.EDITOR.MARK_AS_DONE_BUTTON_TEXT]: 'Mark as sent',
  [KEYS.OUTREACH_PAGE.EDITOR.SEND_MESSAGE_NOW_BUTTON_TEXT]: 'Send now',
  [KEYS.OUTREACH_PAGE.EDITOR.FUTURE_TASK_INFO_TEXT]: 'To send in <strong>{{daysCount}} days</strong>',
  [KEYS.OUTREACH_PAGE.EDITOR.GENERATE_MESSAGE_ERROR_CHIP_TEXT]: 'Oops! Something went wrong while generating your message.',
  [KEYS.OUTREACH_PAGE.EDITOR.GENERATE_MESSAGE_ERROR_CHIP_BUTTON_TEXT]: 'Try again',
  [KEYS.OUTREACH_PAGE.EDITOR.REGENERATE_MESSAGE_BUTTON_TEXT]: 'Regenerate',
  [KEYS.OUTREACH_PAGE.EDITOR.MENTIONS_PLACEHOLDER.TITLE]: 'Choose option',
  [KEYS.OUTREACH_PAGE.EDITOR.SUBJECT.PLACEHOLDER]: 'Write your subject here. Use { for writing tokens',
  [KEYS.OUTREACH_PAGE.EDITOR.MESSAGE.PLACEHOLDER]: 'Type your message. Use { for writing tokens',
  [KEYS.OUTREACH_PAGE.EDITOR.SPICES_INFO.HINT_TEXT]: 'Used in this message:',
  [KEYS.OUTREACH_PAGE.EDITOR.SPICES_INFO.TONE_OF_VOICE.TOOLTIP_TITLE]: 'Tone of voice',
  [KEYS.OUTREACH_PAGE.EDITOR.SPICES_INFO.TONE_OF_VOICE.TOOLTIP_DESCRIPTION]: 'Your selected style of language for the automated message',
  [KEYS.OUTREACH_PAGE.EDITOR.SPICES_INFO.SPICE.TOOLTIP_TITLE]: 'Smart Message Spices',
  [KEYS.OUTREACH_PAGE.EDITOR.SPICES_INFO.SPICE.TOOLTIP_DESCRIPTION]:
    'Spices you turn on will be blended in your messages’ content if the talent in question matches those specific attributes.',
  [KEYS.OUTREACH_PAGE.EDITOR.SPICES_INFO.SPICE.TOOLTIP_DESCRIPTION]:
    'Spices you turn on will be blended in your messages’ content if the talent in question matches those specific attributes.',
  [KEYS.OUTREACH_PAGE.EDITOR.ALERT_HEADER.LABEL]: 'Missing email',
  [KEYS.OUTREACH_PAGE.EDITOR.ALERT_HEADER.INFO_TEXT]: 'Copy and send via LinkedIn',
  [KEYS.OUTREACH_PAGE.EDITOR.AUTO_MODE_HEADER.LABEL]: 'Automated Campaign',
  [KEYS.OUTREACH_PAGE.EDITOR.AUTO_MODE_HEADER.INFO_TEXT_PLURAL]: 'Scheduled message in {{daysCount}} days',
  [KEYS.OUTREACH_PAGE.EDITOR.AUTO_MODE_HEADER.INFO_TEXT_SINGULAR]: 'Scheduled message in {{daysCount}} day',
  [KEYS.OUTREACH_PAGE.EDITOR.AUTO_MODE_HEADER.INFO_TEXT_TODAY]: 'Scheduled message today',
  [KEYS.OUTREACH_PAGE.EDITOR.AUTO_MODE_HEADER.INFO_TEXT_NO_TIMESTAMP]: 'Scheduled message',
  [KEYS.OUTREACH_PAGE.EDITOR.AUTO_MODE_HEADER.STEPS_TEXT]: 'Step {{currentStep}} out of {{totalSteps}}',
  [KEYS.OUTREACH_PAGE.EDITOR.AUTO_MODE_HEADER.MENU_OPTIONS.CANCEL_CAMPAIGN]: 'Cancel campaign for this match',
  [KEYS.OUTREACH_PAGE.EDITOR.MANUAL_MODE_HEADER.LABEL]: 'Last message sent <0/>.',
  [KEYS.OUTREACH_PAGE.EDITOR.MANUAL_MODE_HEADER.INFO_TEXT]: 'Suggested follow up:',
  [KEYS.OUTREACH_PAGE.EDITOR.MANUAL_MODE_HEADER.INFO_TEXT_EMPTY_CONVERSATION]: 'Suggested first message:',
  [KEYS.OUTREACH_PAGE.EDITOR.MANUAL_MODE_HEADER.ACTION_BUTTON_TEXT]: 'Automate your follow ups',
  [KEYS.OUTREACH_PAGE.EDITOR.LOADING_TEXT[0]]: '🤖 AI at work... And it\'s not just thinking about 1s and 0s.',
  [KEYS.OUTREACH_PAGE.EDITOR.LOADING_TEXT[1]]: '💬 Fine-tuning for candidate vibes... Because first impressions matter!',
  [KEYS.OUTREACH_PAGE.EDITOR.LOADING_TEXT[2]]: '🧠 Thinking... And yes, even AIs need to think sometimes!',
  [KEYS.OUTREACH_PAGE.EDITOR.LOADING_TEXT[3]]: '🎩 Magic in the making... And no bunnies involved!',
  [KEYS.OUTREACH_PAGE.EDITOR.LOADING_TEXT[4]]: '🍳 Cooking up some outreach magic... No recipe needed!',
  [KEYS.OUTREACH_PAGE.EDITOR.LOADING_TEXT[5]]: '🤝 Forming the perfect handshake, virtually!',
  [KEYS.OUTREACH_PAGE.EDITOR.LOADING_TEXT[6]]: '🚀 Preparing for message lift-off... Buckle up!',
  [KEYS.OUTREACH_PAGE.EDITOR.LOADING_TEXT[7]]: '🤖 AI personalizing outreach... Stay tuned.',
  [KEYS.OUTREACH_PAGE.EDITOR.LOADING_TEXT[8]]: '💌 Crafting your candidate\'s perfect touchpoint...',
  [KEYS.OUTREACH_PAGE.EDITOR.LOADING_TEXT[9]]: '🎯 Honing the ideal pitch... Stand by.',
  [KEYS.OUTREACH_PAGE.EDITOR.LOADING_TEXT[10]]: '🧠 Brainy outreach underway... No caffeine required.',
  [KEYS.OUTREACH_PAGE.EDITOR.LOADING_TEXT[11]]: '🎩 Adding the magic touch... Nope, not fairy dust!',
  [KEYS.OUTREACH_PAGE.EDITOR.LOADING_TEXT[12]]: '🪄 AI\'s magic in motion... Hold onto your wand!',
  [KEYS.OUTREACH_PAGE.EDITOR.LOADING_TEXT[13]]: '🌠 Elevating outreach... Rocket science? Almost.',
  [KEYS.OUTREACH_PAGE.EDITOR.LOADING_TEXT[14]]: '🔄 AI\'s on a roll... Your crafted message is turning up!',
  [KEYS.OUTREACH_PAGE.EDITOR.CONTACT_DETAILS_LOADING_TEXT]: '📧 Scouting far and wide for contact details… Hang tight!',
  [KEYS.OUTREACH_PAGE.EDITOR.SEND_VIA_LINKEDIN_MENU.TITLE]: 'Send via LinkedIn',
  [KEYS.OUTREACH_PAGE.EDITOR.SEND_VIA_LINKEDIN_MENU.DESCRIPTION]:
    '<0>Message copied successfully!</0> You can now visit {{talentName}}’s LinkedIn profile, paste, and send the message. Don’t forget to mark it as sent.',
  [KEYS.OUTREACH_PAGE.EDITOR.SEND_VIA_LINKEDIN_MENU.ITEMS.OPEN_LINKEDIN]: 'Open {{talentName}}’s LinkedIn',
  [KEYS.OUTREACH_PAGE.EDITOR.SEND_VIA_LINKEDIN_MENU.ITEMS.MARK_MESSAGE_AS_SENT]: 'Mark as sent',
  [KEYS.OUTREACH_PAGE.EMAILS.FROM.USER_EMAIL_ALIAS]: 'Me',
  [KEYS.OUTREACH_PAGE.EMAILS.TO.LABEL]: 'To:',
  [KEYS.OUTREACH_PAGE.EMAILS.FROM.LABEL]: 'From:',
  [KEYS.OUTREACH_PAGE.EMAILS.MISSING_ALERT_LABEL]: 'Missing email',
  [KEYS.OUTREACH_PAGE.EMAILS.MISSING_ALERT_DESCRIPTION]: 'Copy and send via LinkedIn or add email',
  [KEYS.OUTREACH_PAGE.EMAILS.MISSING_ALERT_ACTION_BUTTON_TEXT]: 'Go to LinkedIn profile',
  [KEYS.OUTREACH_PAGE.EMAILS.INVALID_ALERT]: 'Email address invalid. Fix or copy and send via social.',
  [KEYS.OUTREACH_PAGE.CONVERSATION_HEADER.FOR_LABEL]: 'For',
  [KEYS.OUTREACH_PAGE.CONVERSATION_HEADER.SHOW_DETAILS_BUTTON]: 'Details',
  [KEYS.OUTREACH_PAGE.CONVERSATION_HEADER.TOOLTIPS.ARCHIVE]: 'Archive',
  [KEYS.OUTREACH_PAGE.CONVERSATION_HEADER.TOOLTIPS.LINKEDIN]: 'Go to linkedin',
  [KEYS.OUTREACH_PAGE.CONVERSATION_HEADER.TOOLTIPS.SHOW_DETAILS]: 'Match details',
  [KEYS.OUTREACH_PAGE.SEND_MESSAGE_ERROR.TITLE]: 'Your message wasn\'t sent',
  [KEYS.OUTREACH_PAGE.SEND_MESSAGE_ERROR.GENERAL_DESCRIPTION]: 'Please try again or return later and give it another shot.',
  [KEYS.OUTREACH_PAGE.SEND_MESSAGE_ERROR.EMAIL_VALIDATION_ERROR_DESCRIPTION]: 'The recipient email address did not pass our validation.',
  [KEYS.OUTREACH_PAGE.UPGRADE_TO_PRO_BANNER.TITLE]: 'Unlock access to Pro',
  [KEYS.OUTREACH_PAGE.UPGRADE_TO_PRO_BANNER.DESCRIPTION]: 'Unlock access to PRO and enjoy all the goodies of the Perfect outreach AI and many more.',
  [KEYS.OUTREACH_PAGE.UPGRADE_TO_PRO_BANNER.BUTTON_TEXT]: 'Upgrade',
  [KEYS.OUTREACH_PAGE.CONNECT_EMAIL_BANNER.TITLE]: 'Connect email to message your liked matches',
  [KEYS.OUTREACH_PAGE.CONNECT_EMAIL_BANNER.DESCRIPTION]: 'Increase your chance of connecting with potential future colleagues.',
  [KEYS.OUTREACH_PAGE.CONNECT_EMAIL_BANNER.BUTTON_TEXT]: 'Connect email',
  [KEYS.OUTREACH_PAGE.RECONNECT_EMAIL_BANNER.TITLE]: 'Reconnect email to message your liked matches',
  [KEYS.OUTREACH_PAGE.RECONNECT_EMAIL_BANNER.DESCRIPTION]: 'Continue where you left off.',
  [KEYS.OUTREACH_PAGE.RECONNECT_EMAIL_BANNER.BUTTON_TEXT]: 'Reconnect',
  [KEYS.OUTREACH_PAGE.SETUP_GEN_AI_BANNER.TITLE]: 'Set up Gen-AI message preferences to activate Full-Auto Mode',
  [KEYS.OUTREACH_PAGE.SETUP_GEN_AI_BANNER.BUTTON_TEXT]: 'Go to Gen-AI Preferences',
  [KEYS.OUTREACH_PAGE.CAMPAIGN_ON_HOLD.TITLE]: 'Your Outreach Campaign is on Hold',
  [KEYS.OUTREACH_PAGE.CAMPAIGN_ON_HOLD.DESCRIPTION_USER_EMAIL_NOT_CONNECTED]:
    'Looks like there’s a problem with your email connection, please reconnect your email or <0>contact support</0>',
  [KEYS.OUTREACH_PAGE.CAMPAIGN_ON_HOLD.DESCRIPTION_EMAIL_NOT_CONNECTED]:
    'There’s an issue with the email connection for {{userName}}. Please ask {{userName}} to reconnect their email or <0>contact support</0>.',
  [KEYS.OUTREACH_PAGE.CAMPAIGN_ON_HOLD.DESCRIPTION_INTERNAL_ERROR]:
    'Outreach messages are paused for now. You can try again or contact support if you need help.',
  [KEYS.OUTREACH_PAGE.CAMPAIGN_ON_HOLD.BUTTON_TEXT]: 'Re-connect email',
  [KEYS.OUTREACH_PAGE.CAMPAIGN_ON_HOLD.RETRY_BUTTON_TEXT]: 'Retry',
  [KEYS.OUTREACH_PAGE.CAMPAIGN_ON_HOLD.SUPPORT_BUTTON]: 'Support',
  [KEYS.OUTREACH_PAGE.CONVERSATIONS_GROUP_EMPTY.EMOJI]: '🥴',
  [KEYS.OUTREACH_PAGE.CONVERSATIONS_GROUP_EMPTY.TITLE]: 'You’ve got no liked matches',
  [KEYS.OUTREACH_PAGE.CONVERSATIONS_GROUP_EMPTY.DESCRIPTION]:
    'To start reaching out to potential colleagues, mark some of the matches we tracked for you as “liked” - and get the OUTREACH magic going.',
  [KEYS.OUTREACH_PAGE.SUGGESTED_MESSAGES.DEFAULT]: '✨ Generate message',
  [KEYS.OUTREACH_PAGE.SUGGESTED_MESSAGES.SHORT_TO_THE_POINT]: 'Short & to the point ⏱️',
  [KEYS.OUTREACH_PAGE.SUGGESTED_MESSAGES.INFORMAL_FRIENDLY]: 'Informal & friendly 👋',
  [KEYS.OUTREACH_PAGE.SUGGESTED_MESSAGES.CAREER_STEP_UP]: 'Career Step Up 🚀',
  [KEYS.OUTREACH_PAGE.SUGGESTED_MESSAGES.INSIDER_NETWORK]: 'Insider Network ✌️',
  [KEYS.OUTREACH_PAGE.SUGGESTED_MESSAGES.DIVERSITY_INCLUSION]: 'Diversity & inclusion 💙',
  [KEYS.OUTREACH_PAGE.SUGGESTED_MESSAGES.COMPANY_CULTURE]: 'Company culture 🧬',
  [KEYS.OUTREACH_PAGE.SUGGESTED_MESSAGES.DESCRIPTION]: 'Select from these AI-generated messages:',
  [KEYS.OUTREACH_PAGE.SEND_MESSAGE_BANNER_ERROR.TITLE]: 'Your message wasn’t sent',
  [KEYS.OUTREACH_PAGE.SEND_MESSAGE_BANNER_ERROR.BOUNCED_TITLE]: 'Email Delivery Failure',
  [KEYS.OUTREACH_PAGE.SEND_MESSAGE_BANNER_ERROR.DESCRIPTION]: 'Try again or come back later.',
  [KEYS.OUTREACH_PAGE.SEND_MESSAGE_BANNER_ERROR.BOUNCED_DESCRIPTION]: 'Try a different email or contact via other channels.',
  [KEYS.OUTREACH_PAGE.SEND_MESSAGE_BANNER_ERROR.BUTTON]: 'Try again',
  [KEYS.OUTREACH_PAGE.SETUP_GEN_AI_CONVERSATION_BANNER.TITLE]: 'Elevate your messaging experience!',
  [KEYS.OUTREACH_PAGE.SETUP_GEN_AI_CONVERSATION_BANNER.DESCRIPTION]:
    "Tailor your company's image, position details, and inject your personal charm into every message you send.",
  [KEYS.OUTREACH_PAGE.SETUP_GEN_AI_CONVERSATION_BANNER.BUTTON_TEXT]: 'Get started',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.TITLE]: 'Message Preferences',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MESSAGE_CONTENT.PLACEHOLDER]: 'Type in your own words',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MORE_OPTIONS.TITLE]: 'More Options',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MORE_OPTIONS.INPUTS.MESSAGE_LENGTH.TITLE]: 'Message Length',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MORE_OPTIONS.INPUTS.MESSAGE_LENGTH.DESCRIPTION]: 'Set the maximum number of characters allowed per outreach message.',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MORE_OPTIONS.INPUTS.MESSAGE_LENGTH.PLACEHOLDER]: 'Auto',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MORE_OPTIONS.INPUTS.MESSAGE_LENGTH.INPUT_LABEL]: 'characters',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MORE_OPTIONS.INPUTS.INCLUDE_EMAIL_SIGNATURE.TITLE]: 'Add My Email Signature',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MORE_OPTIONS.INPUTS.INCLUDE_EMAIL_SIGNATURE.DESCRIPTION]:
    'Enable to include your Gmail/Outlook signature in outreach messages.',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MORE_OPTIONS.INPUTS.INCLUDE_EMAIL_SIGNATURE.ENABLE_READ_PERMISSIONS_BANNER_TEXT]:
  'You need to enable email read permission to use this feature.\n' +
  '<0>Enable Now</0>',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MORE_OPTIONS.INPUTS.INCLUDE_EMAIL_SIGNATURE.PREVIEW_SIGNATURE_BUTTON]: 'Preview & Update',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MORE_OPTIONS.INPUTS.TRACK_EMAIL_OPENS.TITLE]: 'Track Email Opens',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MORE_OPTIONS.INPUTS.TRACK_EMAIL_OPENS.DESCRIPTION]:
    'Choose whether to track when recipients open your outreach emails. When disabled, you won’t see if or when emails are opened.',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MORE_OPTIONS.INPUTS.TRACK_EMAIL_OPENS.BANNER_TEXT]: 'For better email deliverability, consider turning off open tracking. ' +
  'Disabling this feature helps reduce the chance of emails ending up in spam by removing tracking pixels.',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.ROLE.TITLE]: 'The Role',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.ROLE.INPUTS.ROLE_NAME.TITLE]: 'Role name',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.ROLE.INPUTS.ROLE_NAME.DESCRIPTION]: 'Enter the job title to be used in outreach message.',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.ROLE.INPUTS.ROLE_NAME.PLACEHOLDER]: 'Role name',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.ROLE.INPUTS.ABOUT_ROLE.TITLE]: 'About the role ({{positionName}} - <0>{{positionLocation}}</0>)',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.ROLE.INPUTS.ABOUT_ROLE.DESCRIPTION]:
    'Include key details about the role. AI will use this information to generate the message.',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.ROLE.INPUTS.ROLE_LOCATION.TITLE]: 'Preferred Role Location',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.ROLE.INPUTS.ROLE_LOCATION.DESCRIPTION]: 'Specify the location of the role to include in messages.',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.ROLE.INPUTS.ROLE_LOCATION.PLACEHOLDER]: 'Role location',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MESSAGE_CONTENT.AUTO_FILL_BUTTON]: 'Auto-fill',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MESSAGE_SPICES.TITLE]: 'Smart Message Spices',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MESSAGE_SPICES.DESCRIPTION]:
    'Spices add personalization to your messages, making your outreach more engaging and effective in connecting with talents.',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MESSAGE_SPICES.ITEMS_TITLE.INSIDER_NETWORK]: 'Insider Network',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MESSAGE_SPICES.ITEMS_TITLE.LIKELYHOOD_TO_MOVE]: 'Likelihood to move signals',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MESSAGE_SPICES.ITEMS_TITLE.RELEVANT_SKILLS]: 'Relevant Skills',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MESSAGE_SPICES.ITEMS_TITLE.RELEVANT_EDUCATION]: 'Relevant Education',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MESSAGE_SPICES.ITEMS_TITLE.SIMILAR_COMPANIES]: 'Similar Companies',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MESSAGE_SPICES.ITEMS_DESCRIPTION.INSIDER_NETWORK]:
    'Leverage existing connections. Highlight mutual acquaintances to build familiarity with the talent.',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MESSAGE_SPICES.ITEMS_DESCRIPTION.LIKELYHOOD_TO_MOVE]:
    'Utilize potential indicators of the talent’s interest in new opportunities, such as recent layoffs.',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MESSAGE_SPICES.ITEMS_DESCRIPTION.RELEVANT_SKILLS]:
    'Highlight the talent’s specific skills to craft engaging and tailored messages.',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MESSAGE_SPICES.ITEMS_DESCRIPTION.RELEVANT_EDUCATION]:
    'Tailor messages to highlight educational achievements, such as advanced degrees.',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MESSAGE_SPICES.ITEMS_DESCRIPTION.SIMILAR_COMPANIES]:
    'Emphasize the similarities between the talent’s past experiences and your company’s vision.',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MESSAGE_SPICES.ITEMS_MESSAGES.INSIDER_NETWORK]: 'I noticed you’re connected to John Doe from our team',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MESSAGE_SPICES.ITEMS_MESSAGES.LIKELYHOOD_TO_MOVE]:
    'with recent changes in your company’s workforce, we believe you might be open to new career opportunities',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MESSAGE_SPICES.ITEMS_MESSAGES.RELEVANT_SKILLS]: 'your mastery of [Skill] aligns seamlessly with our challenging',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MESSAGE_SPICES.ITEMS_MESSAGES.RELEVANT_EDUCATION]:
    'your distinguished master’s degree in Computer Science positions you perfectly for groundbreaking innovation',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MESSAGE_SPICES.ITEMS_MESSAGES.SIMILAR_COMPANIES]: 'your triumphs at Microsoft resonate profoundly with our vision',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.GEN_AI_ACTIVATION_BANNER.LABEL]: 'Activate your Gen-AI outreach',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.GEN_AI_ACTIVATION_BANNER.TITLE]: 'Personalize Your AI Outreach',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.GEN_AI_ACTIVATION_BANNER.DESCRIPTION]:
    'Set up your AI preferences and style for effortless, effective outreach without the hassle of writing every message.',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.TONE_OF_VOICE.TITLE]: 'Tone of voice',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.TONE_OF_VOICE.SUBTITLE]: 'Select your desired voice',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.TONE_OF_VOICE.DESCRIPTION]: 'Choose the language style for automated messages',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.TONE_OF_VOICE.SELECTION_LABEL]: 'Select from the list',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.AGENCY_PREFERENCES.TITLE]: 'The Company',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.AGENCY_PREFERENCES.INPUTS.AGENCY_MODE_TOGGLE.TITLE]: 'Agency Mode',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.AGENCY_PREFERENCES.INPUTS.AGENCY_NAME.TITLE]: 'Agency Name',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.AGENCY_PREFERENCES.INPUTS.AGENCY_NAME.DESCRIPTION]: 'Enter your agency’s name. This will be shown in outreach messages.',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.AGENCY_PREFERENCES.INPUTS.AGENCY_NAME.PLACEHOLDER]: 'Agency name',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.AGENCY_PREFERENCES.INPUTS.ABOUT_AGENCY.TITLE]: 'About your agency',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.AGENCY_PREFERENCES.INPUTS.ABOUT_AGENCY.DESCRIPTION]:
    'Describe your agency briefly. AI will use this to generate the final message content.',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.AGENCY_PREFERENCES.INPUTS.HIRING_COMPANY_NAME.TITLE]: 'Hiring Company name',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.AGENCY_PREFERENCES.INPUTS.HIRING_COMPANY_NAME.DESCRIPTION]:
    'Enter the hiring company’s name. This will be shown in outreach messages.',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.AGENCY_PREFERENCES.INPUTS.HIRING_COMPANY_NAME.PLACEHOLDER]: 'Company name',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.AGENCY_PREFERENCES.INPUTS.HIRING_COMPANY_NAME_CONFIDENTIAL.TITLE]: 'Confidential',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.AGENCY_PREFERENCES.INPUTS.HIRING_COMPANY_NAME_CONFIDENTIAL.DESCRIPTION]: 'Hide the hiring company’s name',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.AGENCY_PREFERENCES.INPUTS.ABOUT_HIRING_COMPANY.TITLE]: 'About the Hiring Company',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.AGENCY_PREFERENCES.INPUTS.ABOUT_HIRING_COMPANY.DESCRIPTION]:
    'Provide a brief description of the hiring company. AI will use this to craft the message, so exact phrasing isn’t necessary.',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.AI_INSTRUCTIONS.TITLE]: 'AI Instructions (Optional)',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.AI_INSTRUCTIONS.INPUT_TITLE]: 'Spacial Instructions',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.AI_INSTRUCTIONS.INPUT_DESCRIPTION]: 'Enter specific guidelines for the AI to follow when writing messages. <0>See examples</0>',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.AI_INSTRUCTIONS.INPUT_PLACEHOLDER]: 'Example: “Mention that we are a dog-friendly office.”',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MESSAGE_PREVIEW.TITLE]: 'Message Preview',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MESSAGE_PREVIEW.REGENERATE_BUTTON]: 'Regenerate',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MESSAGE_PREVIEW.GENERATE_BUTTON]: 'Generate preview',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MESSAGE_PREVIEW.GENERATE_BUTTON_LOADING]: 'Generating...',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MESSAGE_PREVIEW.BANNER_TEXT]: 'Our Gen-AI creates a unique message for every talent, the content below is an example.',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MESSAGE_PREVIEW.SHOW_PREVIEW_BUTTON]: 'Preview',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MESSAGE_PREVIEW.LOADING_TEXT[0]]: '🧠 Brainpower at work... Crafting your preview!',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MESSAGE_PREVIEW.LOADING_TEXT[1]]: '🧩 Piecing together the puzzle... Preview\'s almost ready!',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MESSAGE_PREVIEW.LOADING_TEXT[2]]: '🏋️‍♀️ Flexing our AI muscles... Lifting up your preview now!',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MESSAGE_PREVIEW.LOADING_TEXT[3]]: '🪄 AI\'s working its magic! Preview soon.',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MESSAGE_PREVIEW.LOADING_TEXT[4]]: '🐢 AI\'s taking the scenic route. Preview\'s coming.',
  [KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MESSAGE_PREVIEW.LOADING_TEXT[5]]: '🌟 AI\'s sprinkling stardust. Preview\'s about to land.',
  [KEYS.CAMPAIGN_PAGE.TITLE]: 'Campaigns',
  [KEYS.CAMPAIGN_PAGE.MODES_SECTION.TITLE]: 'Choose your preferred mode',
  [KEYS.CAMPAIGN_PAGE.MODES_SECTION.DESCRIPTION]:
    'Experience seamless automation with Full-Auto mode! Let AI create and send your message sequence effortlessly. Turn off for manual control.',
  [KEYS.CAMPAIGN_PAGE.MODES_SECTION.BUTTONS.ACTIVE_BUTTON_INDICATION]: 'Active',
  [KEYS.CAMPAIGN_PAGE.MODES_SECTION.BUTTONS.MANUAL.TITLE]: 'Manual',
  [KEYS.CAMPAIGN_PAGE.MODES_SECTION.BUTTONS.MANUAL.SUBTITLE]: 'Send individual messages, receive follow up suggestions',
  [KEYS.CAMPAIGN_PAGE.MODES_SECTION.BUTTONS.MANUAL.INFO_TEXT]: 'Switch to manual mode',
  [KEYS.CAMPAIGN_PAGE.MODES_SECTION.BUTTONS.CUSTOM.TITLE]: 'Custom',
  [KEYS.CAMPAIGN_PAGE.MODES_SECTION.BUTTONS.CUSTOM.SUBTITLE]: 'Customise campaigns, including LinkedIn, Text, and more.',
  [KEYS.CAMPAIGN_PAGE.MODES_SECTION.BUTTONS.CUSTOM.BADGE_TEXT]: 'Coming soon',
  [KEYS.CAMPAIGN_PAGE.MODES_SECTION.BUTTONS.CUSTOM.INFO_TEXT]: 'Setup',
  [KEYS.CAMPAIGN_PAGE.MODES_SECTION.BUTTONS.AUTO.TITLE]: 'Auto',
  [KEYS.CAMPAIGN_PAGE.MODES_SECTION.BUTTONS.AUTO.SUBTITLE]: 'Gen-AI creates campaigns, automatically sends emails.',
  [KEYS.CAMPAIGN_PAGE.MODES_SECTION.BUTTONS.AUTO.INFO_TEXT]: 'Go fully automated',
  [KEYS.CAMPAIGN_PAGE.AUTO_MODE_OPTIONS.TITLE]: 'Auto mode options:',
  [KEYS.CAMPAIGN_PAGE.AUTO_MODE_OPTIONS.SWITCH.AUTO]: 'Auto',
  [KEYS.CAMPAIGN_PAGE.AUTO_MODE_OPTIONS.SWITCH.CUSTOM]: 'Custom',
  [KEYS.CAMPAIGN_PAGE.AUTO_MODE_OPTIONS.EMAIL_SENDER.TITLE]: 'Send from',
  [KEYS.CAMPAIGN_PAGE.AUTO_MODE_OPTIONS.EMAIL_SENDER.DESCRIPTION]: 'Choose who the message should appear to be sent from',
  [KEYS.CAMPAIGN_PAGE.AUTO_MODE_OPTIONS.EMAIL_SENDER.PRIMARY_SENDER_SELECT_PLACEHOLDER]: 'Select sender',
  [KEYS.CAMPAIGN_PAGE.AUTO_MODE_OPTIONS.EMAIL_SENDER.PRIMARY_SENDER_SELECT_LABEL]: 'Primary sender',
  [KEYS.CAMPAIGN_PAGE.AUTO_MODE_OPTIONS.EMAIL_SENDER.PRIMARY_SENDER_SELECT_MENU_TITLE]: 'Select Primary Sender',
  [KEYS.CAMPAIGN_PAGE.AUTO_MODE_OPTIONS.SEND_TIME.TITLE]: 'When to send',
  [KEYS.CAMPAIGN_PAGE.AUTO_MODE_OPTIONS.SEND_TIME.DESCRIPTION]: 'Select the preferred timing for sending the automated message (match timezone)',
  [KEYS.CAMPAIGN_PAGE.AUTO_MODE_OPTIONS.SEND_TIME.INFO_TEXT]: 'Email sent at optimal time, typically start or end of talent’s workday in their timezone.',
  [KEYS.CAMPAIGN_PAGE.AUTO_MODE_OPTIONS.SEND_TIME.DAY_TYPE_SELECT_PLACEHOLDER]: 'Select days',
  [KEYS.CAMPAIGN_PAGE.AUTO_MODE_OPTIONS.SEND_TIME.DAY_TYPE.BUSINESS_DAY]: 'Working days',
  [KEYS.CAMPAIGN_PAGE.AUTO_MODE_OPTIONS.SEND_TIME.DAY_TYPE.ANY_DAY]: 'Any Day',
  [KEYS.CAMPAIGN_PAGE.AUTO_MODE_OPTIONS.FOLLOWUPS_NUMBER.TITLE]: 'Number of Follow-Ups',
  [KEYS.CAMPAIGN_PAGE.AUTO_MODE_OPTIONS.FOLLOWUPS_NUMBER.DESCRIPTION]: 'Decide how many additional messages should be sent',
  [KEYS.CAMPAIGN_PAGE.AUTO_MODE_OPTIONS.FOLLOWUPS_NUMBER.INFO_TEXT]: '2-4 follow ups will be sent depending on the position & additional sender involvement',
  [KEYS.CAMPAIGN_PAGE.MESSAGING_TYPE_SECTION.TITLE]: 'Messaging Type',
  [KEYS.CAMPAIGN_PAGE.MESSAGING_TYPE_SECTION.DESCRIPTION]: 'Choose how you want to reach out to candidates. Note: Manual methods require manual action to send.',
  [KEYS.CAMPAIGN_PAGE.MESSAGING_TYPE_SECTION.PLACEHOLDER]: 'Messaging type',
  [KEYS.CAMPAIGN_PAGE.MESSAGING_TYPE_SECTION.MENU.TITLE]: 'Messaging Types',
  [KEYS.CAMPAIGN_PAGE.MESSAGING_TYPE_SECTION.MENU.SUBTITLE]: 'Select your preferred communication method.',
  [KEYS.CAMPAIGN_PAGE.AUTO_ARCHIVE_CONVERSATION.TITLE]: 'Auto-Archive Email-Less Matches',
  [KEYS.CAMPAIGN_PAGE.AUTO_ARCHIVE_CONVERSATION.DESCRIPTION]:
    'Enable this to automatically archive matches <Underline>without email</Underline> addresses, ensuring your outreach remains tidy.',
  [KEYS.CAMPAIGN_PAGE.AUTO_ARCHIVE_CONVERSATION.SWITCH.ACTIVE]: 'Active',
  [KEYS.CAMPAIGN_PAGE.AUTO_ARCHIVE_CONVERSATION.SWITCH.INACTIVE]: 'Inactive',
  [KEYS.CAMPAIGN_PAGE.FASTER_FOLLOWUPS.TITLE]: 'Faster Follow-Ups',
  [KEYS.CAMPAIGN_PAGE.FASTER_FOLLOWUPS.DESCRIPTION]: 'Minimize the gap between each campaign step to quickly engage candidates.',
  [KEYS.CAMPAIGN_PAGE.FASTER_FOLLOWUPS.SWITCH.ACTIVE]: 'Active',
  [KEYS.CAMPAIGN_PAGE.FASTER_FOLLOWUPS.SWITCH.INACTIVE]: 'Inactive',
  [KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_OPTIONS.TITLE]: 'Campaign steps',
  [KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_OPTIONS.DESCRIPTION]: 'Configure each step of your campaign for precise, targeted outreach.',
  [KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_OPTIONS.EDIT_BUTTON]: 'Edit steps',
  [KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_OPTIONS.STEP_LABEL]: 'Step',
  [KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_OPTIONS.FIRST_STEP_NEXT_BUSINESS_DAY]:
    'Send the first <strong>{{type}}</strong> in the next business day at <strong>{{time}}</strong> with a custom message.',
  [KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_OPTIONS.FIRST_STEP_AS_SOON_AS_POSSIBLE]:
    'Send the first <strong>{{type}}</strong> as soon as possible with a custom message.',
  [KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_OPTIONS.N_STEP]:
    'If there’s no reply <strong>{{prevStepType}}</strong> Follow up via <strong>{{type}}</strong> In <strong>{{days}} days</strong> at <strong>{{time}}</strong>',
  [KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_OPTIONS.MANUEL_LABEL]: 'Manual Task',
  [KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_OPTIONS.MANUEL_LABEL_INFO]:
    '"Manual Task" is a step that requires you to personally complete an action, like sending an InMail message or making a call.',
  [KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.TITLE]: 'Campaign steps',
  [KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.DESCRIPTION]: 'Configure each step of your campaign for precise, targeted outreach.',
  [KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.STEPS.TITLE]: 'After adding a match to outreach:',
  [KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.STEPS.FIRST_DESCRIPTION[0]]: 'Send the first',
  [KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.STEPS.FIRST_DESCRIPTION[1]]: 'in the next business day',
  [KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.STEPS.FIRST_DESCRIPTION[2]]: 'at',
  [KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.STEPS.FOLLOW_UP_DESCRIPTION[0]]: 'Follow up via',
  [KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.STEPS.FOLLOW_UP_DESCRIPTION[1]]: 'in',
  [KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.STEPS.FOLLOW_UP_DESCRIPTION[2]]: 'at',
  [KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.STEPS.EDITOR.SWITCH_LABEL]: 'Send as a reply',
  [KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.STEPS.EDITOR.SUBJECT_AS_REPLY]: 'RE: Original subject',
  [KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.STEPS.VALIDATION_ERROR.TITLE]: 'Please fill in all required information to continue.',
  [KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.STEPS.VALIDATION_ERROR.DESCRIPTION]: 'Oops! Looks like some fields are still empty.',
  [KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.ADD_STEP_BUTTON]: 'Add another step',
  [KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.SAVE_STEPS_BUTTON]: 'Save Campaign',
  [KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.REMOVE_STEP_BUTTON]: 'Remove',
  [KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.FIRST_STEP_MESSAGE_AS_SOON_AS_POSSIBLE]: 'As soon as possible',
  [KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.FIRST_STEP_MESSAGE_NEXT_BUSINESS_DAY]: 'The next business day',
  [KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.STEPS.SELECT_METHOD.NEW]: 'Select Method',
  [KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.STEPS.SELECT_METHOD.EMAIL]: 'Email',
  [KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.STEPS.SELECT_METHOD.PHONE]: 'Phone Call',
  [KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.STEPS.SELECT_METHOD.LINKEDIN_MANUAL]: 'InMail',
  [KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.STEPS.SELECT_METHOD.SMS]: 'Text Message',
  [KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.STEPS.SELECT_TIME_PLACEHOLDER]: 'Time',
  [KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.STEPS.SELECT_DAYS_PLACEHOLDER]: 'Days',
  [KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.STEPS.SELECTION_POPUP.CONFIRM_BUTTON]: 'Confirm',
  [KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.STEPS.SELECTION_POPUP.SELECT_TYPE.TITLE]: 'Pick Outreach Method',
  [KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.STEPS.SELECTION_POPUP.SELECT_TYPE.DESCRIPTION]: 'Select your preferred communication method for this step.',
  [KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.STEPS.SELECTION_POPUP.SELECT_DAYS.TITLE]: 'Select Days After Action',
  [KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.STEPS.SELECTION_POPUP.SELECT_DAYS.DESCRIPTION]:
    'Tip: Follow-up within 1-3 days to keep the connection active and top of mind for talents.',
  [KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.STEPS.SELECTION_POPUP.SELECT_DAYS.TOGGLE_LABEL]: 'Working days only',
  [KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.STEPS.SELECTION_POPUP.SELECT_DAYS.INPUT_LABEL]: 'Days',
  [KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.STEPS.SELECTION_POPUP.SELECT_TIME.TITLE]: 'Select Send Time',
  [KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.STEPS.SELECTION_POPUP.SELECT_TIME.LABEL]: 'Talent time zone',
  [KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.STEPS.SELECTION_POPUP.SELECT_TIME.DESCRIPTION]:
    'Tip: Opt for 9 AM - 11 AM to increase visibility as talents are starting their day.',
  [KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.STEPS.SELECTION_POPUP.SELECT_TYPE.DESCRIPTION]: 'Select your preferred communication method for this step.',
  [KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.STEPS.SELECTION_POPUP.SELECT_TYPE.EMAIL]: 'Email',
  [KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.STEPS.SELECTION_POPUP.SELECT_TYPE.LINKEDIN_MANUAL]: 'LinkedIn InMail <strong>(Manual)</strong>',
  [KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.STEPS.SELECTION_POPUP.SELECT_TYPE.SMS]: 'Text Message',
  [KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.STEPS.SELECTION_POPUP.SELECT_TYPE.PHONE]: 'Phone Call',
  [KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.STEPS.SELECTION_POPUP.SELECT_TYPE.AUTOMATIC_LABEL]: 'Automatic',
  [KEYS.CAMPAIGN_PAGE.CUSTOM_MODE_DIALOG.STEPS.SELECTION_POPUP.SELECT_TYPE.MANUAL_LABEL]: 'Manual',
  [KEYS.CAMPAIGN_PAGE.DISCONNECT_EMAIL.DESCRIPTION]: 'Your outreach is linked to <strong>{{userEmailAddress}}</strong> email account.',
  [KEYS.CAMPAIGN_PAGE.DISCONNECT_EMAIL.LINK]: 'Disconnect',
  [KEYS.CAMPAIGN_PAGE.DISCONNECT_EMAIL.DIALOG.TITLE]: 'Are you sure you want to disconnect {{emailAddress}} email account?',
  [KEYS.CAMPAIGN_PAGE.DISCONNECT_EMAIL.DIALOG.DESCRIPTION]: 'Disconnecting will pause any active outreach campaigns linked to this account. ' +
  'You can reconnect your email at any time to resume outreach activities.',
  [KEYS.CAMPAIGN_PAGE.DISCONNECT_EMAIL.DIALOG.KEEP_CONNECTED]: 'Keep connected',
  [KEYS.CAMPAIGN_PAGE.DISCONNECT_EMAIL.DIALOG.CONFIRM_DISCONNECT]: 'Confirm disconnect',
  [KEYS.MATCHING_PROGRESS_PAGE.TITLE.MAKING]: 'Matching in the making...',
  [KEYS.MATCHING_PROGRESS_PAGE.TITLE.ADJUSTMENT]: 'Tweaks Applied!\n Re-Matching in Progress...',
  [KEYS.MATCHING_PROGRESS_PAGE.TITLE.STILL_WORKING]: 'Still working on it...',
  [KEYS.MATCHING_PROGRESS_PAGE.TITLE.LONGER_THAN_EXPECTED]: 'This is taking longer than expected...',
  [KEYS.MATCHING_PROGRESS_PAGE.SUBTITLE.ANALYZING]: 'Analyzing your latest preferences',
  [KEYS.MATCHING_PROGRESS_PAGE.SUBTITLE.MAKING_SURE]: 'We\'re making sure everything is just right',
  [KEYS.MATCHING_PROGRESS_PAGE.DESCRIPTION.ANALYZING]: 'Analyzing your latest preferences',
  [KEYS.MATCHING_PROGRESS_PAGE.DESCRIPTION.ADJUSTMENT]: 'Hold tight – your updated match list is being crafted with \n your latest choices.',
  [KEYS.MATCHING_PROGRESS_PAGE.DESCRIPTION.MAKING_SURE]: 'We\'re making sure everything is just right',
  [KEYS.MATCHING_PROGRESS_PAGE.DESCRIPTION.CLOSER_LOOK]: 'We\'re taking a close look to ensure everything is in order',
  [KEYS.MATCHING_PROGRESS_PAGE.MATCHING_PROGRESS.TIME_LEFT]: 'Minutes to your match results',
  [KEYS.MATCHING_PROGRESS_PAGE.MATCHING_PROGRESS.FINALIZING]: 'Finalizing matches',
  [KEYS.MATCHING_PROGRESS_PAGE.MATCHING_PROGRESS.CHECK_LATER]: 'Check back later',
  [KEYS.MATCHING_PROGRESS_PAGE.MATCHING_STEPS.FINDING]: 'Finding your best candidates',
  [KEYS.MATCHING_PROGRESS_PAGE.MATCHING_STEPS.ANALYZING]: 'Analyzing your data...',
  [KEYS.MATCHING_PROGRESS_PAGE.MATCHING_STEPS.LEARNING]: 'Learning industries & skills patterns...',
  [KEYS.MATCHING_PROGRESS_PAGE.MATCHING_STEPS.EXTRACTING]: 'Generating ideal profile definition...',
  [KEYS.MATCHING_PROGRESS_PAGE.MATCHING_STEPS.CHERRY_PICKING]: 'Cherry picking your matches...',
  [KEYS.MATCHING_PROGRESS_PAGE.MATCHING_STEPS.ALMOST_THERE]: 'Almost there... Get ready',
  [KEYS.MATCHING_PROGRESS_PAGE.MATCHING_STEPS.THANKS]: 'Thanks for your patience.',
  [KEYS.MATCHING_PROGRESS_PAGE.MATCHING_STEPS.TOO_LONG.NOTIFY]: 'We’ll notify you via email once your matches are ready.',
  [KEYS.MATCHING_PROGRESS_PAGE.MATCHING_STEPS.TOO_LONG.NEED_HELP]: 'Need help? We\'re here for you. <0>Chat with us</0>',
  [KEYS.AUTOMATING_CAMPAIGN_DIALOG.TITLE]: 'Automating Your Campaigns...',
  [KEYS.AUTOMATING_CAMPAIGN_DIALOG.DESCRIPTION]:
    'Get ready for takeoff! Our AI is working tirelessly to set up your campaign. Fasten your seatbelt; your campaign will be soaring to success in just a few moments!',
  [KEYS.COMPANY_SIZE_LABEL]: 'Company size',
  [KEYS.COMPANY_SIZE.SMALL]: 'Small',
  [KEYS.COMPANY_SIZE.MEDIUM]: 'Medium',
  [KEYS.COMPANY_SIZE.LARGE]: 'Corporate',
  [KEYS.COMPANY_SIZE.VERY_LARGE]: 'Large Enterprise',
  [KEYS.LEVEL_OF_EXPERIENCE.ENTRY_LEVEL]: 'Entry-Level Experience',
  [KEYS.LEVEL_OF_EXPERIENCE.STARTING_OUT]: 'Proven Experience',
  [KEYS.LEVEL_OF_EXPERIENCE.SOMEWHAT_EXPERIENCED]: 'Seasoned Experience',
  [KEYS.LEVEL_OF_EXPERIENCE.HIGHLY_EXPERIENCED]: 'Extensive Experience',
  [KEYS.LEVEL_OF_EXPERIENCE.INCREDIBLY_EXPERIENCED]: 'Veteran Experience',
  [KEYS.MATCH_CARD.ENGAGE_BUTTON_TEXT]: 'Add to outreach',
  [KEYS.MATCH_CARD.EDUCATION_LABEL]: 'Education',
  [KEYS.MATCH_CARD.EXPERIENCE.LABEL]: 'Career Path',
  [KEYS.MATCH_CARD.EXPERIENCE.SKILL_ASKED_BY_USER_TOOLTIP]: 'Acquired skill, Extracted from colleagues profiles',
  [KEYS.MATCH_CARD.EXPERIENCE.SKILL_TOOLTIP]: 'Acquired skill',
  [KEYS.MATCH_CARD.EXPERIENCE.INDUSTRY_TOOLTIP]: 'Company attribute',
  [KEYS.MATCH_CARD.EXPERIENCE.COMPANY_SIZE_TOOLTIP]: 'Company size',
  [KEYS.MATCH_CARD.EXPERIENCE.MORE_TAGS_TOOLTIP]: 'See More',
  [KEYS.MATCH_CARD.SKILLS.TALENT_PROFILE_SOURCE_TOOLTIP_TEXT]: 'Extracted from talent profile',
  [KEYS.MATCH_CARD.SKILLS.INFERRED_SOURCE_TOOLTIP_TEXT]: 'Extracted from peers',
  [KEYS.MATCH_CARD.SKILLS.COMPANY_INSIGHT_TOOLTIP_TEXT]: 'Extracted from companies data',
  [KEYS.MATCH_CARD.ATS_INDICATION.CANDIDATE_IN_ATS]: '<strong>Candidate in ATS</strong>   This candidate already exists in your ATS.',
  [KEYS.MATCH_CARD.ATS_INDICATION.PREVIOUSLY_INTERACTED]:
    '<strong>Previously Interacted</strong> This candidate is either contacted previously or actively considered for the current position.',
  [KEYS.MATCH_CARD.ATS_INDICATION.ENGAGED_ELSEWHERE]:
    '<strong>Engaged Elsewhere</strong> This candidate is already liked or in contact for a different role on our platform.',
  [KEYS.MATCH_CARD.EXPLAINABILITY.EXACT_SKILL]: 'Exact skill',
  [KEYS.MATCH_CARD.EXPLAINABILITY.SIMILAR_SKILL]: 'Similar skill',
  [KEYS.MATCH_CARD.EXPLAINABILITY.EXACT_SKILL_MORE_INFO_TOOLTIP_TEXT]: 'Exact skill - click for more info',
  [KEYS.MATCH_CARD.EXPLAINABILITY.SIMILAR_SKILL_MORE_INFO_TOOLTIP_TEXT]: 'Similar skill - click for more info',
  [KEYS.MATCH_CARD.EXPLAINABILITY.EXACT_INDUSTRY]: 'Exact industry',
  [KEYS.MATCH_CARD.EXPLAINABILITY.SIMILAR_INDUSTRY]: 'Similar industry',
  [KEYS.MATCH_CARD.EXPLAINABILITY.EXACT_INDUSTRY_MORE_INFO_TOOLTIP_TEXT]: 'Exact industry - click for more info',
  [KEYS.MATCH_CARD.EXPLAINABILITY.COMPANY_SIZE_MORE_INFO_TOOLTIP_TEXT]: 'Company size - click for more info',
  [KEYS.MATCH_CARD.EXPLAINABILITY.SIMILAR_INDUSTRY_MORE_INFO_TOOLTIP_TEXT]: 'Similar industry - click for more info',
  [KEYS.MATCH_CARD.EXPLAINABILITY.VERIFIED_FROM_COMPANY_PROFILE_TEXT]: '{{companyName}}, Company profile',
  [KEYS.MATCH_CARD.EXPLAINABILITY.VERIFIED_FROM_COMPANY_DESCRIPTION]: 'As described in {{companyName}}\'s profile',
  [KEYS.MATCH_CARD.EXPLAINABILITY.VERIFIED_FROM_COMPANY_INDUSTRIES]: 'Worked at {{companyName}}, which is in the {{industryName}} industry',
  [KEYS.MATCH_CARD.EXPLAINABILITY.VERIFIED_FROM_TALENT_SPECIFIC_ROLE_DESCRIPTION_TEXT]: 'Mentioned in their experience at {{companyName}} as {{jobTitle}}',
  [KEYS.MATCH_CARD.EXPLAINABILITY.VERIFIED_FROM_TALENT_SPECIFIC_ROLE_SKILLS_TEXT]: 'Listed as a skill in their role at {{companyName}} as {{jobTitle}}',
  [KEYS.MATCH_CARD.EXPLAINABILITY.VERIFIED_FROM_TALENT_GENERAL_PROFILE_DESCRIPTION_TEXT]: 'Mentioned by the candidate in their profile',
  [KEYS.MATCH_CARD.EXPLAINABILITY.VERIFIED_FROM_TALENT_GENERAL_PROFILE_SKILLS_TEXT]: 'Listed as a skill in the candidate profile',
  [KEYS.MATCH_CARD.EXPLAINABILITY.INFO_MENU.SELF_EVIDENCE_SECTION_TITLE]: 'Self evidence',
  [KEYS.MATCH_CARD.EXPLAINABILITY.INFO_MENU.RELEVANCE_EXPLANATION_SECTION_TITLE]: 'Relevance Explanation',
  [KEYS.MATCH_CARD.EXPLAINABILITY.INFO_MENU.VERIFICATION_SOURCE_SECTION_TITLE]: 'Verified from',
  [KEYS.MATCH_CARD.EXPLAINABILITY.INFO_MENU.IS_SIMILAR_TO]: 'Is similar to',
  [KEYS.MATCH_CARD.EXPLAINABILITY.INFO_MENU.SKILL_VERIFIED]: 'Skill Verified',
  [KEYS.MATCH_CARD.EXPLAINABILITY.INFO_MENU.INDUSTRY_VERIFIED]: 'Industry Verified',
  [KEYS.MATCH_CARD.EXPLAINABILITY_WIDGET.LABEL]: 'AI Match Summary',
  [KEYS.MATCH_CARD.EXPLAINABILITY_WIDGET.ITEMS_LABELS.LOCATION]: 'Location',
  [KEYS.MATCH_CARD.EXPLAINABILITY_WIDGET.ITEMS_LABELS.LEVEL_OF_EXPERIENCE]: 'Relevant Experience',
  [KEYS.MATCH_CARD.EXPLAINABILITY_WIDGET.ITEMS_LABELS.SKILLS]: 'Skills',
  [KEYS.MATCH_CARD.EXPLAINABILITY_WIDGET.ITEMS_LABELS.COMPANY_BACKGROUND]: 'Industry Background',
  [KEYS.MATCH_CARD.EXPLAINABILITY_WIDGET.ITEMS_LABELS.COMPANY_SIZE]: 'Company size',
  [KEYS.MATCH_CARD.EXPLAINABILITY_WIDGET.ITEMS_LABELS.EDUCATION]: 'Education',
  [KEYS.POSITION_SELECTION_GROUP.OVERVIEW_TITLE]: 'Overview',
  [KEYS.POSITION_SELECTION_GROUP.MATCH_TITLE]: 'Match',
  [KEYS.POSITION_SELECTION_GROUP.OUTREACH_TITLE]: 'Outreach',
  [KEYS.MATCH_WIDGETS.BADGE_TITLE]: 'Highlight',
  [KEYS.MATCH_WIDGETS.ACADEMIC_EXCELLENCE.TITLE]: 'Academic Excellence',
  [KEYS.MATCH_WIDGETS.ACADEMIC_EXCELLENCE.DESCRIPTION]: 'Studied at an elite university',
  [KEYS.MATCH_WIDGETS.EXPERIENCE_BY_INDUSTRY.TITLE]: 'Experience by Industry',
  [KEYS.MATCH_WIDGETS.LIKELIHOOD_TO_MOVE.TITLE]: 'Likelihood to move',
  [KEYS.MATCH_WIDGETS.LIKELIHOOD_TO_MOVE.DESCRIPTION]: 'Industry trends, tenure, and recent upskilling suggest {{talentName}} is ready for a new challenge.',
  [KEYS.MATCH_WIDGETS.LIKELIHOOD_TO_MOVE.LEVEL_LABEL]: 'Likelihood to move:',
  [KEYS.MATCH_WIDGETS.LIKELIHOOD_TO_MOVE.LEVEL.LOW]: 'Somewhat Likely',
  [KEYS.MATCH_WIDGETS.LIKELIHOOD_TO_MOVE.LEVEL.MEDIUM]: 'Likely To Move',
  [KEYS.MATCH_WIDGETS.LIKELIHOOD_TO_MOVE.LEVEL.HIGH]: 'Very Likely',
  [KEYS.MATCH_WIDGETS.LIKELIHOOD_TO_MOVE.CHILD_WIDGETS.PERSONAL_TENURE_COMPARISON.DESCRIPTION]:
    '<strong>Professional Milestone:</strong> With her tenure at Square, a career move is statistically likely.',
  [KEYS.MATCH_WIDGETS.LIKELIHOOD_TO_MOVE.CHILD_WIDGETS.LAYOFF_TALENT.DESCRIPTION]:
    '<strong>Industry Momentum:</strong> Current trends in the tech industry point towards higher mobility.',
  [KEYS.MATCH_WIDGETS.SKILLS.TITLE]: 'Skills & Attributes',
  [KEYS.MATCH_WIDGETS.TALENT_NETWORK.TITLE]: 'Insider Network',
  [KEYS.ENGAGEMENT_FEEDBACK.THANKS]: 'Thanks! We’ll learn from it.',
  [KEYS.ENGAGEMENT_FEEDBACK.GOT_YOU]: 'Got you! Thanks for your feedback.',
  [KEYS.ENGAGEMENT_FEEDBACK.CHEERS]: 'Cheers! Your feedback fuels progress.',
  [KEYS.ENGAGEMENT_FEEDBACK.SUPER]: 'Super helpful! We\'re evolving, thanks.',
  [KEYS.ENGAGEMENT_FEEDBACK.AWESOME]: 'Awesome! Your input helps us grow.',
  [KEYS.ENGAGEMENT_FEEDBACK.LOVE_IT]: 'Love it! We\'re getting better, thanks.',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_ASSISTANT.NEXT_BUTTON]: 'next',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_ASSISTANT.DISMISS_BUTTON]: 'dismiss',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_ASSISTANT.LABEL]: 'Position Assistant',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_ASSISTANT.EMPTY_ASSISTANT.TITLE]: 'All Set for Now!',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_ASSISTANT.EMPTY_ASSISTANT.DESCRIPTION]:
    'There are no new suggestions right now. You’re on the right track with\n' +
    ' your current recruitment efforts. We’ll be here with recommendations\n' +
    ' when there’s an opportunity to optimize further.',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_ASSISTANT.ACTIVATED_AUTOMATED_OUTREACH.TITLE]: 'Boost Your Outreach\n Activate Auto Mode',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_ASSISTANT.ACTIVATED_AUTOMATED_OUTREACH.DESCRIPTION]:
    'Activate Auto Mode for outreach campaigns that not only reach further\n but hit the mark, driving up your reply rates significantly.',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_ASSISTANT.ACTIVATED_AUTOMATED_OUTREACH.ACTION_TITLE]: 'Activate auto',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_ASSISTANT.ATS_INTEGRATION.TITLE]: 'Seamless Sync\n Link Position With Your ATS',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_ASSISTANT.ATS_INTEGRATION.DESCRIPTION]:
    'Simplify your recruitment workflow by linking this position in your ATS.\n Experience effortless data flow and reduced manual tasks',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_ASSISTANT.ATS_INTEGRATION.ACTION_TITLE]: 'Sync position with ATS',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_ASSISTANT.PROMPT_FOLLOW_UP.TITLE]: 'Follow-Up Nudge\n You have {{count}} follow-ups pending',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_ASSISTANT.PROMPT_FOLLOW_UP.DESCRIPTION]:
    'Increase the chance of a reply by following up, or let Auto Mode take\n over for consistent, impactful engagement.',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_ASSISTANT.PROMPT_FOLLOW_UP.ACTION_TITLE]: 'Follow Up Now',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_ASSISTANT.ENCOURAGE_TIMELY_REPLIES.TITLE]: 'Time to Respond!\n {{count}} Messages Awaiting',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_ASSISTANT.ENCOURAGE_TIMELY_REPLIES.DESCRIPTION]:
    'You’ve got replies waiting. Keep the momentum going by\n getting back to them now.',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_ASSISTANT.ENCOURAGE_TIMELY_REPLIES.ACTION_TITLE]: 'Reply Now',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_ASSISTANT.OUTREACH_TO_LIKED_MATCHES.TITLE]: 'They Caught Your Eye',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_ASSISTANT.OUTREACH_TO_LIKED_MATCHES.DESCRIPTION]:
    'Those matches you liked? They could be your next great hire.\n Why not reach out?',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_ASSISTANT.OUTREACH_TO_LIKED_MATCHES.ACTION_TITLE]: 'reach out',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_ASSISTANT.IMPROVE_OUTREACH_ENGAGEMENT.TITLE]: 'Unleash the Auto Magic\n Increase Reply Rate to up to 20%!',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_ASSISTANT.IMPROVE_OUTREACH_ENGAGEMENT.DESCRIPTION]:
    'Elevate your reply rates with Auto Mode. Automate follow-ups and send\n out compelling messages with ease. <0>Learn how</0>',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_ASSISTANT.IMPROVE_OUTREACH_ENGAGEMENT.ACTION_TITLE]: 'Switch to Auto',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_ASSISTANT.BUILD_STRONGER_CANDIDATE_PIPELINE.TITLE]: 'Pipeline Power-Up\n Add {{count}} Matches to Outreach',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_ASSISTANT.BUILD_STRONGER_CANDIDATE_PIPELINE.DESCRIPTION]:
    'A mighty pipeline leads to mighty hires. Why not add a\n few more AI matches to your outreach?',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_ASSISTANT.BUILD_STRONGER_CANDIDATE_PIPELINE.ACTION_TITLE]: 'Review matches',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_ASSISTANT.MILESTONE_CELEBRATION.ACTION_TITLE]: 'Celebrate',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_ASSISTANT.MILESTONE_CELEBRATION.FIRST_REPLAY.TITLE]: 'First ‘Yes’ in the Bag!',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_ASSISTANT.MILESTONE_CELEBRATION.FIRST_REPLAY.DESCRIPTION]:
    'You got your first positive reply! That’s the sound of your recruiting skills\n and Perfect’s AI making beautiful music together.',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_ASSISTANT.MILESTONE_CELEBRATION.REVIEWED_100_MATCHES.TITLE]: '100 Reviewed!\n Milestone Unlocked',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_ASSISTANT.MILESTONE_CELEBRATION.REVIEWED_100_MATCHES.DESCRIPTION]:
    'You’ve reviewed 100 matches! That’s a lot of talent scanned and a big\n step towards finding your ideal talents. Keep up the great work!',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_ASSISTANT.MILESTONE_CELEBRATION.ADDED_50_TO_OUTREACH.TITLE]: '50 Added to Outreach!',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_ASSISTANT.MILESTONE_CELEBRATION.ADDED_50_TO_OUTREACH.DESCRIPTION]:
    'You’ve added 50 candidates to your outreach list! That’s impressive\n effort towards broadening your talent search. Here’s to many more!”',
  [KEYS.POSITION_OVERVIEW_PAGE.ATS.CONNECT_TO_ATS_TEXT]: 'Connect position to ATS',
  [KEYS.POSITION_OVERVIEW_PAGE.ATS.SYNCED_TEXT]: 'Synced with <0>{{positionName}}</0>',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_REALIZATION_METRICS.EFFORT_INVESTED.LABEL]: 'Effort Invested',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_REALIZATION_METRICS.EFFORT_INVESTED.TITLE]: '<0>{{hoursInvested}}</0> hours',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_REALIZATION_METRICS.EFFORT_INVESTED.CALCULATING_TITLE]: 'Calculating...',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_REALIZATION_METRICS.EFFORT_INVESTED.DESCRIPTION]:
    'Time spent on this position, <hoursSaved>saving {{ hoursSaved }} hrs</hoursSaved>\n compared to traditional methods.',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_REALIZATION_METRICS.EFFORT_INVESTED.NEGATIVE_TIME_DESCRIPTION]:
    'Spending more time than expected? <Underline>Let\'s optimize your process.</Underline>',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_REALIZATION_METRICS.EFFORT_INVESTED.TOOLTIP_TEXT]:
    'We measure the time you actively engage with this position and compare it to the standard 30 minutes needed to find and message a candidate traditionally. ' +
    'This shows how much time Perfect helps you save.',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_REALIZATION_METRICS.TALENT_RARITY.LABEL]: 'talent rarity',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_REALIZATION_METRICS.TALENT_RARITY.TITLE]: '<0>1</0> out of <0>70</0>',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_REALIZATION_METRICS.TALENT_RARITY.DESCRIPTION]: 'Each AI match is rare, selected from 70\n talents meeting your basic criteria.',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_STATISTICS_CARDS.LABEL]: 'tasks',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_STATISTICS_CARDS.TO_REVIEW.TITLE]: 'To Review',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_STATISTICS_CARDS.TO_REVIEW.INFO_TEXT]: 'More matches soon',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_STATISTICS_CARDS.TO_REVIEW.INFO_TEXT_DAILY_LIMIT]: 'More matches tomorrow...',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_STATISTICS_CARDS.TO_REVIEW.INFO_TEXT_MATCHING_PROGRESS]: 'Matching in the making...',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_STATISTICS_CARDS.TO_REVIEW.INFO_TEXT_NO_MATCHES_FOUND]: 'No matches found',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_STATISTICS_CARDS.TO_REVIEW.ACTION_BUTTON_TEXT]: 'Review now',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_STATISTICS_CARDS.TO_REACHOUT.TITLE]: 'To Reachout',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_STATISTICS_CARDS.TO_REACHOUT.INFO_TEXT]: 'All done and done!',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_STATISTICS_CARDS.TO_REACHOUT.ACTION_BUTTON_TEXT]: 'Reachout now',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_STATISTICS_CARDS.TO_REPLY.TITLE]: 'To Reply',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_STATISTICS_CARDS.TO_REPLY.INFO_TEXT]: 'All done and done!',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_STATISTICS_CARDS.TO_REPLY.ACTION_BUTTON_TEXT]: 'Respond now',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_PERFORMANCE_FUNNEL.PEOPLE_MATCHED.TITLE]: 'People Matched',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_PERFORMANCE_FUNNEL.PEOPLE_MATCHED.SUBTITLE]: '(+{{pendingReviewMatches}} New matches)',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_PERFORMANCE_FUNNEL.TITLE]: 'performance funnel',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_PERFORMANCE_FUNNEL.OPTIMAL_INFO_LABEL]: 'Optimal',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_PERFORMANCE_FUNNEL.ACTUAL_INFO_LABEL]: 'Actual',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_PERFORMANCE_FUNNEL.PEOPLE_MATCHED.TAGS.OUT_OF_COMPANIES]: 'Out of {{count}} companies',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_PERFORMANCE_FUNNEL.PEOPLE_MATCHED.TAGS.GREAT_MIX]: 'Great mix! - {{count}} companies',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_PERFORMANCE_FUNNEL.LIKED.TITLE]: 'Liked',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_PERFORMANCE_FUNNEL.LIKED.TAGS.OPTIMAL_RESULT]: 'Add {{ count }} more for optimal results',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_PERFORMANCE_FUNNEL.LIKED.TAGS.KEEP_IT_OUT]: 'Keep it up',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_PERFORMANCE_FUNNEL.LIKED.TAGS.GREAT_START]: 'Great Start!',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_PERFORMANCE_FUNNEL.LIKED.TAGS.EXCELLENT_PIPELINE]: 'Excellent Pipeline!',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_PERFORMANCE_FUNNEL.LIKED.TAGS.AMAZING]: 'Amazing! {{ rate }}% Acceptance Rate!',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_PERFORMANCE_FUNNEL.CONTACTED.TITLE]: 'Contacted',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_PERFORMANCE_FUNNEL.CONTACTED.TAGS.FOLLOWUPS]: '{{ count }} Waiting Follow-ups',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_PERFORMANCE_FUNNEL.CONTACTED.TAGS.WELL_DONE]: 'Well Done!',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_PERFORMANCE_FUNNEL.CONTACTED.TAGS.HIGH_OPEN_RATE]: 'High Open Rate!',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_PERFORMANCE_FUNNEL.CONTACTED.TAGS.OPEN_RATE]: '{{ rate }}% Open rate',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_PERFORMANCE_FUNNEL.CONTACTED.TAGS.SENT]: '{{ count }} Messages Sent',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_PERFORMANCE_FUNNEL.REPLIED.TITLE]: 'Replied',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_PERFORMANCE_FUNNEL.REPLIED.INFO_TEXT]:
    'This shows the percentage of recipients who\'ve \n' +
    'responded to your message as of now. The \n' +
    'campaign is ongoing, and follow-ups can \n' +
    'increase this rate. Keep in mind that some \n' +
    'recipients may take a few days to respond.',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_PERFORMANCE_FUNNEL.REPLIED.TAGS.LOW]: '{{ rate }}% Reply Rate',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_PERFORMANCE_FUNNEL.REPLIED.TAGS.MED]: 'WOW! {{ rate }}% Reply Rate',
  [KEYS.POSITION_OVERVIEW_PAGE.POSITION_PERFORMANCE_FUNNEL.REPLIED.TAGS.HIGH]: 'Breaking records! - {{ rate }}% Reply Rate',
  [KEYS.POSITION_ADJUSTMENTS_PAGE.SUBTITLE]: 'Oops, we’re a bit short on matches!',
  [KEYS.POSITION_ADJUSTMENTS_PAGE.TITLE]: 'A tweak or two, and more matches will pop up!',
  [KEYS.POSITION_ADJUSTMENTS_PAGE.ITEMS_CONTAINER_HINT]: 'Recommended tweaks for more matches',
  [KEYS.POSITION_ADJUSTMENTS_PAGE.CONFIRM_BUTTON_TEXT]: 'Confirm & Rematch',
  [KEYS.POSITION_ADJUSTMENTS_PAGE.EDIT_PREFERENCES]: 'Edit Preferences',
  [KEYS.POSITION_ADJUSTMENTS_PAGE.REDIRECT_TO_EDIT_POSITION]: 'Edit preferences',
  [KEYS.POSITION_ADJUSTMENTS_PAGE.ADJUSTMENT_ITEM_ACTION_BUTTON_TEXT]: 'Accepted',
  [KEYS.POSITION_ADJUSTMENTS_PAGE.ADJUSTMENT_ITEM_ACTION_BUTTON_SELECTED_TEXT]: 'Accept Suggestion',
  [KEYS.POSITION_ADJUSTMENTS_PAGE.LOCATION_ADJUSTMENT.TITLE]: 'Adjust Location Preferences',
  [KEYS.POSITION_ADJUSTMENTS_PAGE.LOCATION_ADJUSTMENT.DESCRIPTION]: 'Consider adding nearby locations to your preferences.',
  [KEYS.POSITION_ADJUSTMENTS_PAGE.TITLE_ADJUSTMENT.TITLE]: 'Refine Your Position Title for Clarity',
  [KEYS.POSITION_ADJUSTMENTS_PAGE.TITLE_ADJUSTMENT.DESCRIPTION]:
    'Sometimes, simpler is better. Make sure your position title is straightforward and free from internal jargon or overly specific terms.',
  [KEYS.POSITION_ADJUSTMENTS_PAGE.RECOMMENDED_ADJUSTMENTS.LOCATION.TITLE]: 'Boost Your Search Area',
  [KEYS.POSITION_ADJUSTMENTS_PAGE.RECOMMENDED_ADJUSTMENTS.LOCATION.DESCRIPTION]: 'Consider broadening your search area. Extending the radius can open up more matches.',
  [KEYS.POSITION_ADJUSTMENTS_PAGE.RECOMMENDED_ADJUSTMENTS.LEVEL_OF_EXPERIENCE.TITLE]: 'Expand Experience Horizons',
  [KEYS.POSITION_ADJUSTMENTS_PAGE.RECOMMENDED_ADJUSTMENTS.LEVEL_OF_EXPERIENCE.DESCRIPTION]:
    'You’re looking for [{{ currentLevelsOfExperience }}]. How about including candidates with [{{ recommendedLevelsOfExperience }}] as well?',
  [KEYS.POSITION_ADJUSTMENTS_PAGE.RECOMMENDED_ADJUSTMENTS.COMPANY_SIZE.TITLE]: 'Broaden Company Size Range',
  [KEYS.POSITION_ADJUSTMENTS_PAGE.RECOMMENDED_ADJUSTMENTS.COMPANY_SIZE.DESCRIPTION]:
    'Your search is set to [{{ currentCompanySizes }}]. Let’s include [{{ recommendedCompanySizes }}] sizes for variety.',
  [KEYS.POSITION_ADJUSTMENTS_PAGE.RECOMMENDED_ADJUSTMENTS.MUST_HAVES.TITLE]: 'Flex Your Must-Haves',
  [KEYS.POSITION_ADJUSTMENTS_PAGE.RECOMMENDED_ADJUSTMENTS.MUST_HAVES.DESCRIPTION]: 'You’ve set some criteria as mandatory. Maybe switch a few to ‘nice-to-have’?',
  [KEYS.POSITION_ADJUSTMENTS_PAGE.RECOMMENDED_ADJUSTMENTS.JOB_TITLE.TITLE]: 'Optimize Your Role Title',
  [KEYS.POSITION_ADJUSTMENTS_PAGE.RECOMMENDED_ADJUSTMENTS.JOB_TITLE.DESCRIPTION]:
    'How about ‘{{ recommendedJobTitle }}’ for your title? This tweak, based on roles you’ve liked before, is set to bring more matches your way.',
  [KEYS.TIME_OF_DAY.NIGHT]: 'Night',
  [KEYS.TIME_OF_DAY.EVENING]: 'Evening',
  [KEYS.TIME_OF_DAY.AFTERNOON]: 'Afternoon',
  [KEYS.TIME_OF_DAY.MORNING]: 'Morning',
  [KEYS.HOMEPAGE.TEAMMATES_LABEL]: 'Your Teammates',
  [KEYS.HOMEPAGE.ERRORS.CLOSED_POSITION_TITLE]: 'The position you’re trying to view is no longer available.',
  [KEYS.HOMEPAGE.ERRORS.CLOSED_POSITION_DESCRIPTION]: ' The position has been archived or deleted.',
  [KEYS.HOMEPAGE.POSITIONS_TITLE]: 'My Open Positions',
  [KEYS.HOMEPAGE.ACTIVE_POSITIONS_WIDGET.LABEL]: 'Active Positions Used',
  [KEYS.HOMEPAGE.ACTIVE_POSITIONS_WIDGET.POSITIONS_COUNT_TEXT]: '{{openPositionsCount}} of {{maximumOpenPositionsCount}}',
  [KEYS.HOMEPAGE.HEADER.TITLE]: 'Good {{ timeOfDay }}',
  [KEYS.HOMEPAGE.HEADER.DESCRIPTION]: '“Time spent on hiring is time well spent.”– Robert Half',
  [KEYS.HOMEPAGE.HEADER.DAILY_STREAK.CONSONANT_LABEL]: 'You’re on a',
  [KEYS.HOMEPAGE.HEADER.DAILY_STREAK.VOWEL_LABEL]: 'You’re on an',
  [KEYS.HOMEPAGE.HEADER.DAILY_STREAK.COUNTER]: '{{ count }} day streak!',
  [KEYS.HOMEPAGE.HEADER.DAILY_STREAK.TITLE_VARIANT[0][1]]: 'Talent Spark Ignited! 🔥',
  [KEYS.HOMEPAGE.HEADER.DAILY_STREAK.TITLE_VARIANT[1][1]]: 'Rising Talent Ninja! 🥷',
  [KEYS.HOMEPAGE.HEADER.DAILY_STREAK.TITLE_VARIANT[2][1]]: 'Supercharged Talent Dynamo! ⚡️',
  [KEYS.HOMEPAGE.HEADER.DAILY_STREAK.TITLE_VARIANT[3][1]]: 'Unleashing Talent Wizardry! 🧙‍♂️',
  [KEYS.HOMEPAGE.HEADER.DAILY_STREAK.TITLE_VARIANT[4][1]]: 'Unleashing Talent Wizardry! 🧙‍♂️',
  [KEYS.HOMEPAGE.HEADER.DAILY_STREAK.TITLE_VARIANT[5][1]]: 'Talent Sorcerer Extraordinaire! 🎩',
  [KEYS.HOMEPAGE.HEADER.DAILY_STREAK.TITLE_VARIANT[6][1]]: 'Master of Talent Domination! 👑',
  [KEYS.HOMEPAGE.HEADER.DAILY_STREAK.TITLE_VARIANT[7][1]]: 'Talent Success Amplified! 🌟',
  [KEYS.HOMEPAGE.HEADER.DAILY_STREAK.TITLE_VARIANT[8][1]]: 'Talent Unleashed, World Amazed! 🌎',
  [KEYS.HOMEPAGE.HEADER.DAILY_STREAK.TITLE_VARIANT[9][1]]: 'Talent Sensation on Fire! 🔥',
  [KEYS.HOMEPAGE.HEADER.DAILY_STREAK.TITLE_VARIANT[10][1]]: 'Talent Powerhouse Unleashed! 💪',
  [KEYS.HOMEPAGE.HEADER.DAILY_STREAK.TITLE_VARIANT[11][1]]: 'Epic Talent Conqueror! 🏆',
  [KEYS.HOMEPAGE.HEADER.DAILY_STREAK.TITLE_VARIANT[12][1]]: 'Talent Prodigy Rising! 🌟',
  [KEYS.HOMEPAGE.HEADER.DAILY_STREAK.TITLE_VARIANT[13][1]]: 'Ultimate Talent Dynamo! ⚡️',
  [KEYS.HOMEPAGE.HEADER.DAILY_STREAK.TITLE_VARIANT[14][1]]: 'Talent Marvel Unleashed! 😍',
  [KEYS.HOMEPAGE.HEADER.DAILY_STREAK.TITLE_VARIANT[15][1]]: 'Talent Beast Mode Activated! 🦾',
  [KEYS.HOMEPAGE.HEADER.DAILY_STREAK.TITLE_VARIANT[16][1]]: 'Talent Phenom Ascended! 🌟',
  [KEYS.HOMEPAGE.HEADER.DAILY_STREAK.TITLE_VARIANT[17][1]]: 'Talent Champion Rising! 🏆',
  [KEYS.HOMEPAGE.HEADER.DAILY_STREAK.TITLE_VARIANT[18][1]]: 'Unstoppable Talent Force! 💪',
  [KEYS.HOMEPAGE.HEADER.DAILY_STREAK.TITLE_VARIANT[19][1]]: 'Legendary Talent Conqueror! 🌟',
  [KEYS.HOMEPAGE.HEADER.DAILY_STREAK.TITLE_VARIANT[20][1]]: 'extraordinary {{ count }} day streak! 🎁',
  [KEYS.HOMEPAGE.HEADER.TRIAL_BANNER.LABEL]: 'Hi! it’s Your trial',
  [KEYS.HOMEPAGE.HEADER.TRIAL_BANNER.ENDED.TITLE]: 'We can’t really put a price on magic and rainbows.',
  [KEYS.HOMEPAGE.HEADER.TRIAL_BANNER.WILL_END.TITLE]: 'Your trial will end in <days>{{ count }}</days> days',
  [KEYS.HOMEPAGE.HEADER.TRIAL_BANNER.WILL_END.DESCRIPTION]:
    'We hope you loved our free trial period. To continue using Perfect, upgrade to a paid plan. We bet you’ll love our pricing too',
  [KEYS.HOMEPAGE.HEADER.TRIAL_BANNER.ENDED.DESCRIPTION]:
    'But… your free trial just expired. upgrade to one of our paid plans to continue enjoying Perfect.',
  [KEYS.HOMEPAGE.HEADER.TRIAL_BANNER.WILL_END.ACTION_BUTTON_TEXT]: 'Upgrade now',
  [KEYS.HOMEPAGE.HEADER.TRIAL_BANNER.ENDED.ACTION_BUTTON_TEXT]: 'Upgrade',
  [KEYS.HOMEPAGE.POSITION_STATISTICS_CARDS.TO_REVIEW.TITLE]: 'Matches to review',
  [KEYS.HOMEPAGE.POSITION_STATISTICS_CARDS.TO_REVIEW.TITLE_DAILY_LIMIT]: 'More matches coming soon...',
  [KEYS.HOMEPAGE.POSITION_STATISTICS_CARDS.TO_REVIEW.TITLE_MATCHING_IN_THE_MAKING]: 'Matching in the making...',
  [KEYS.HOMEPAGE.POSITION_STATISTICS_CARDS.TO_REVIEW.TITLE_EMPTY]: 'More matches soon...',
  [KEYS.HOMEPAGE.POSITION_STATISTICS_CARDS.TO_REACHOUT.TITLE]: 'Matches to reachout',
  [KEYS.HOMEPAGE.POSITION_STATISTICS_CARDS.TO_REPLY.TITLE]: 'New Replies',
  [KEYS.HOMEPAGE.POSITION_STATISTICS_CARDS.LABEL_ATS]: 'Synced to position in ',
  [KEYS.HOMEPAGE.POSITION_CARD.CREATE_POSITION_CARD_TITLE]: 'create another position',
  [KEYS.HOMEPAGE.POSITION_CARD.ONE_MORE_LOCATION]: 'and <0>1 more location</0>',
  [KEYS.HOMEPAGE.POSITION_CARD.X_MORE_LOCATIONS]: 'and <0>{{locationsAmount}} more locations</0>',
  [KEYS.HOMEPAGE.POSITION_CARD.CLOSED_TEXT]: 'Position closed',
  [KEYS.HOMEPAGE.POSITION_CARD.CLOSED_INFO]: 'by {{username}} at {{timestamp}}',
  [KEYS.HOMEPAGE.POSITION_CARD.CREATED]: 'Created <0></0>',
  [KEYS.HOMEPAGE.POSITION_CARD.UPDATED]: '<0>Last updated</0> <1></1>',
  [KEYS.USER_PICKER.ASSIGN]: 'Assign',
  [KEYS.USER_PICKER.INVITE_TEAMMATE_TEXT]: 'Invite a teammate',
  [KEYS.USER_PICKER.RECRUITER]: 'Recruiter',
  [KEYS.USER_PICKER.RECRUITER_MENU_TITLE]: 'Assign a recruiter',
  [KEYS.USER_PICKER.HIRING_MANAGER]: 'Hiring Manager',
  [KEYS.USER_PICKER.HIRING_MANAGER_MENU_TITLE]: 'Assign a hiring manager',
  [KEYS.USER_MENU_ITEM.ME]: 'Me',
  [KEYS.USER_MENU_ITEM.INVITE_PENDING]: 'Invite pending',
  [KEYS.USER_MENU_ITEM.EMAIL_NOT_CONFIGURED]: 'Email not configured',
  [KEYS.GUIDANCE_MODAL.TITLE]: 'Guidance',
  [KEYS.GUIDANCE_MODAL.OPEN_GUIDANCE_MODAL_BUTTON_TEXT]: 'Guidance on This Step',
  [KEYS.GUIDANCE_MODAL.COMPANY_BACKGROUND_MODAL_TITLE]: 'Past Industry Experience',
  [KEYS.GUIDANCE_MODAL.SKILLS_MODAL_TITLE]: 'Skill Selection: Dos and Don’ts',
  [KEYS.GUIDANCE_MODAL.OUTREACH_AI_INSTRUCTIONS_MODAL_TITLE]: 'Examples of Special Instructions',
  [KEYS.CONNECT_EMAIL_NOTIFICATION.TITLE]: 'Email Connection Issue',
  [KEYS.CONNECT_EMAIL_NOTIFICATION.DESCRIPTION]:
  '{{userName}}\'s email connection is disconnected for the {{positionName}} position. Outreach is paused and emails cannot be sent. ' +
  'Please ask {{userName}} to reconnect their email or <0>contact support.</0>',
  [KEYS.CONNECT_EMAIL_NOTIFICATION.DESCRIPTION_WITHOUT_POSITION]:
  '{{userName}}\'s email connection is disconnected. Outreach is paused and emails cannot be sent. ' +
  'Please ask {{userName}} to reconnect their email or <0>contact support.</0>',
  [KEYS.CONNECT_EMAIL_NOTIFICATION.DESCRIPTION_WITHOUT_USERNAME]:
  'Email connection is disconnected for the {{positionName}} position. Outreach is paused and emails cannot be sent. ' +
  'Please ask the position owner to reconnect their email or <0>contact support.</0>',
  [KEYS.CONNECT_EMAIL_NOTIFICATION.DESCRIPTION_WITHOUT_POSITION_AND_USERNAME]:
  'Email connection is disconnected for one of the positions. Outreach is paused and emails cannot be sent. ' +
  'Please check the email connection or <0>contact support.</0>',
  [KEYS.CONNECT_EMAIL_NOTIFICATION.CURRENT_USER_DESCRIPTION]:
  'Your email connection is disconnected for the {{positionName}}. Outreach is paused and emails cannot be sent. ' +
  'Please reconnect your email or <0>contact support.</0>',
  [KEYS.CONNECT_EMAIL_NOTIFICATION.CURRENT_USER_DESCRIPTION_WITHOUT_POSITION]:
  'Your email connection is disconnected for one of the positions. Outreach is paused and emails cannot be sent. ' +
  'Please reconnect your email or <0>contact support.</0>',
  [KEYS.CONNECT_EMAIL_NOTIFICATION.ACTION_BUTTON_TEXT]: 'Re-connect email',
  [KEYS.CONNECT_EMAIL_NOTIFICATION.CLOSE_BUTTON_TEXT]: 'Dismiss',
  [KEYS.CAMPAIGN_ERROR_NOTIFICATION.TITLE]: 'Your Outreach Campaign in on Hold',
  [KEYS.CAMPAIGN_ERROR_NOTIFICATION.DESCRIPTION]:
  'Messages for {{positionName}} are paused due to an issue. Tap \'Retry\' to try again or reach out to support if you need help.',
  [KEYS.CAMPAIGN_ERROR_NOTIFICATION.ACTION_BUTTON_TEXT]: 'Retry',
  [KEYS.CAMPAIGN_ERROR_NOTIFICATION.SECONDARY_ACTION_BUTTON_TEXT]: 'Support',
  [KEYS.CAMPAIGN_ERROR_NOTIFICATION.CLOSE_BUTTON_TEXT]: 'Dismiss',
};

export default STRINGS;
