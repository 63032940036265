import styled from 'styled-components';
import { THEME } from '../../../../../themes';
import BaseButton from '../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.component';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.XLOOSE};
`;

export const IncludeEmailSignatureWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.TINY};
`;

export const TrackEmailOpensWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.TINY};
`;

export const SignaturePreviewButton = styled(BaseButton)`
  color: ${THEME.COLORS.ACCENT};
  width: 146px;
`;
