import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import ConversationSidePanel from '../conversation-side-panel/conversation-side-panel.component';
import { usePageFilters } from '../../../../../hooks/use-page-filters.hooks';
import { AppRouting } from '../../../../../services/appRoutingResolver';
import { PageName } from '../../../../../enums/page-name.enum';
import { SequencePreferencesDto } from '../../../../../store/rtk-query/apis/sequence/dto/sequence-preferences.dto';
import { useGetPositionSequencePreferencesQuery } from '../../../../../store/rtk-query/apis/sequence/hooks/get-position-sequence-preferences.query-hook';
import { SequenceModeEnum } from '../../../../../store/rtk-query/apis/sequence/enums/sequence-mode.enum';
import { useGetConversationsByPositionQuery } from '../../../../../store/rtk-query/apis/outreach/hooks/get-conversations-by-position-id.query-hook';
import { OutreachBannerTypeEnum } from '../outreach-banner/outreach-banner-type.enum';
import { OutreachInitializationDialogs } from '../../../../../components/dialogs/outreach-initialization-dialogs/outreach-initialization-dialogs.component';
import { UpgradePlanDialog } from '../../../../../components/dialogs/upgrade-plan-dialog/upgrade-plan-dialog.component';
import { IntercomManager } from '../../../../../services/intercom-manager';
import {
  useResumeCampaignQuery,
} from '../../../../../store/rtk-query/apis/sequence/hooks/resume-campaign.query-hook';
import STRING_KEYS from '../../../../../language/keys';
import { AlertSnackBar } from '../../../../../components/Common/CustomSnackBar/AlertSnackBar';
import { BannerContainer, LayoutContainer, PageContainer } from './outreach.styled';
import { useOutreachBanner } from './outreach.hooks';

export const OutreachLayout = ({ children }: { children:  JSX.Element | JSX.Element[] }): JSX.Element => {
  const { t: translate } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const { positionId } = useParams<MatchUrlParams>();
  const { getFiltersByKey } = usePageFilters();
  const { data: { sequencePreferences = {} as SequencePreferencesDto } } = useGetPositionSequencePreferencesQuery(positionId);
  const { mode } = sequencePreferences;
  const isAutoMode = mode === SequenceModeEnum.Auto || mode === SequenceModeEnum.Custom;
  const pageName = AppRouting.getPageName(location.pathname);

  const [isOutreachInitializationDialogOpen, setIsOutreachInitializationDialogOpen]  = useState(false);
  const [isUpgradeDialogOpen, setIsUpgradeDialogOpen] = useState(false);
  const [resumeCampaign, { isError }] = useResumeCampaignQuery();
  const [showError, setShowError] = useState<boolean>(false);

  useEffect(() => {
    if (isError) {
      setShowError(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  const onBannerActionButtonClick = (bannerType: OutreachBannerTypeEnum) => {
    switch (bannerType) {
      case OutreachBannerTypeEnum.UpgradeToPro:
        setIsUpgradeDialogOpen(true);
        break;
      case OutreachBannerTypeEnum.SetupGenAi:
        history.push(AppRouting.buildPositionContentPreferencesPageUrl(positionId));
        break;
      case OutreachBannerTypeEnum.ConnectEmail:
      case OutreachBannerTypeEnum.ReconnectEmail:
      case OutreachBannerTypeEnum.CampaignOnHoldUserEmailConnection:
      case OutreachBannerTypeEnum.CampaignOnHoldEmailConnection:
        setIsOutreachInitializationDialogOpen(true);
        break;
      case OutreachBannerTypeEnum.CampaignOnHoldInternalError:
        resumeCampaign({ positionId });
        break;
      default:
        break;
    }
  };

  const onBannerSecondaryActionButtonClick = (bannerType: OutreachBannerTypeEnum) => {
    switch (bannerType) {
      case OutreachBannerTypeEnum.CampaignOnHoldInternalError:
        IntercomManager.showChat();
        break;
      default:
        break;
    }
  };

  const bannerConfiguration = useOutreachBanner({
    positionId,
    actionButtonClickHandler: onBannerActionButtonClick,
    secondaryActionButtonClickHandler: onBannerSecondaryActionButtonClick,
  });

  const Banner = useMemo(() => {
    if (bannerConfiguration?.bannerType === OutreachBannerTypeEnum.SetupGenAi && pageName !== PageName.OutreachCampaignPreferences) {
      return null;
    }

    return (bannerConfiguration?.Banner && (
      <BannerContainer>{ bannerConfiguration?.Banner }</BannerContainer>
    ));
  }, [bannerConfiguration, pageName]);

  const {
    isLoading: isConversationsLoading, isFetching: isConversationsFetching,
  } = useGetConversationsByPositionQuery({ positionId, refetchOnMountOrArgChange: true });

  const pageContainerFullWidth = useMemo(() => {
    if (AppRouting.getPageName(location.pathname) === PageName.Conversation) {
      return true;
    }

    return AppRouting.getPageName(location.pathname) === PageName.OutreachContentPreferences;
  }, [location.pathname]);

  const isStepSelected = useMemo(() => {
    return !!getFiltersByKey(AppRouting.URL_PARAMS.OUTREACH.CONVERSATION_GROUP)[0];
  }, [getFiltersByKey]);

  return (
    <>
      { Banner }
      <LayoutContainer
        isStepSelected={ isStepSelected }
        isAutoMode={ isAutoMode }
        isLoading={ isConversationsLoading || isConversationsFetching }
        isBannerVisible={ !!Banner }
      >
        <ConversationSidePanel isBannerVisible={ !!Banner } />
        <PageContainer isAutoMode={ isAutoMode } fullWidth={ !pageContainerFullWidth } isStepSelected={ isStepSelected }>
          { children }
        </PageContainer>
      </LayoutContainer>
      <OutreachInitializationDialogs
        isOpen={ isOutreachInitializationDialogOpen }
        closeDialogHandler={ () => setIsOutreachInitializationDialogOpen(false) }
        positionId={ positionId }
      />
      { isUpgradeDialogOpen && (
        <UpgradePlanDialog
          isDialogOpen={ isUpgradeDialogOpen }
          onCloseDialog={ () => setIsUpgradeDialogOpen(false) }
        />
      )}
      <AlertSnackBar
        title={ translate(STRING_KEYS.ALERT) }
        description={ translate(STRING_KEYS.GLOBAL_ERROR_MESSAGE) }
        isOpen={ showError }
        onClose={ () => setShowError(false) }
        shouldAutoDismiss
      />
    </>
  );
};
