import styled from 'styled-components';
import { ReactComponent as CLOSE_DIALOG } from '../../../../assets/close_dialog.svg';
import BaseButton from '../../../Common/Buttons/perfect-base-button/perfect-base-button.component';
import { THEME } from '../../../../themes';
import { hexToRgbA } from '../../../../utils/colors';
import { Subtitle2Font } from '../../../../themes/fonts';

export const FooterContainer = styled.footer`
  display: flex;
  justify-content: space-between;
`;

export const CloseButtonContainer = styled(BaseButton)`
  ${Subtitle2Font};
  color: ${hexToRgbA(THEME.COLORS.PRIMARY_DARK, THEME.OPACITY.LOW)};
  margin-left: -${THEME.SPACING.BASE};
`;

export const ActionButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ActionButtonContainer = styled(BaseButton)`
  color: ${THEME.COLORS.ACCENT};
  padding-right: 0;
`;

export const CloseIcon = styled(CLOSE_DIALOG)`
  width: 32px;
  height: 32px;
`;
