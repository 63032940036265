import styled from 'styled-components';
import { Scrollbar } from '../../../../../../../themes/scrollbar';
import { THEME } from '../../../../../../../themes';

export const Container = styled.div`
  ${Scrollbar};
  height: 100%;
  overflow-y: scroll;
  padding: ${THEME.SPACING.MEDIUM};
`;
