import { Container } from './signature.styled';

export const Signature = ({ htmlContent }: SignatureProps): JSX.Element => {
  return (
    <Container
      dangerouslySetInnerHTML={ { __html: htmlContent } }
      style={ { overflowY: 'auto' } }
    />
  );
};
