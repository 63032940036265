import { ResumeCampaignQueryArguments } from '../dto/query-arguments/resume-campaign-query.arguments';
import { sequenceQuery } from '../sequence.toolkit-api';

type UseSendMessageMutationType = [
  (request: ResumeCampaignQueryArguments) => void,
  AppSelectorType<void | undefined>
];

export const useResumeCampaignQuery = (): UseSendMessageMutationType => {
  const [
    resumeCampaign,
    {
      data, isLoading, isError, error, isSuccess,
    }] = sequenceQuery.useResumeCampaignMutation();

  const meta = {
    isLoading, isError, error, isSuccess, data,
  };

  return [
    resumeCampaign,
    { ...meta },
  ];
};
