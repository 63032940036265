import {
  useCallback, useEffect, useRef, useState,
} from 'react';

interface PopupConfig {
  width?: number;
  height?: number;
  title?: string;
}

interface UseAuthPopupConfig extends PopupConfig {
  onClose?: () => void;
  onTimeout?: () => void;
  onError?: (error: Error) => void;
}

interface UseAuthPopupReturn {
  isOpen: boolean;
  openPopup: () => void;
  closePopup: () => void;
  error: Error | null;
}

interface PopupPosition {
  width: number;
  height: number;
  left: number;
  top: number;
}

class PopupError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'PopupError';
  }
}

const DEFAULT_CONFIG: Required<PopupConfig> = {
  width: 500,
  height: 600,
  title: 'CustomPopup',
};

const calculatePopupPosition = (width: number, height: number): PopupPosition => {
  const left = window.screenX + (window.outerWidth - width) / 2;
  const top = window.screenY + (window.outerHeight - height) / 2;

  return {
    width, height, left, top,
  };
};

const createPopupFeatures = (position: PopupPosition): string => {
  const features = {
    width: position.width,
    height: position.height,
    left: position.left,
    top: position.top,
    resizable: 'yes',
    scrollbars: 'yes',
    status: 'yes',
  };

  return Object.entries(features)
    .map(([key, value]) => `${key}=${value}`)
    .join(',');
};

export const useCustomWindow = (
  authUrl: string,
  {
    width = DEFAULT_CONFIG.width,
    height = DEFAULT_CONFIG.height,
    title = DEFAULT_CONFIG.title,
    onClose,
    onError,
  }: UseAuthPopupConfig = {},
): UseAuthPopupReturn => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const popupRef = useRef<Window | null>(null);
  const cleanupRef = useRef<(() => void) | null>(null);

  const cleanup = useCallback(() => {
    if (cleanupRef.current) {
      cleanupRef.current();
      cleanupRef.current = null;
    }
    if (popupRef.current && !popupRef.current.closed) {
      popupRef.current.close();
    }
    setIsOpen(false);
  }, []);

  const handleError = useCallback((e: Error) => {
    setError(e);
    onError?.(e);
    cleanup();
  }, [cleanup, onError]);

  const openPopup = useCallback(() => {
    try {
      // If popup is already open, focus it
      if (popupRef.current?.closed === false) {
        popupRef.current.focus();

        return;
      }

      // Reset error state
      setError(null);

      // Calculate position and create features string
      const position = calculatePopupPosition(width, height);
      const features = createPopupFeatures(position);

      // Open popup
      const popup = window.open(authUrl, title, features);

      // Handle popup blocked
      if (!popup) {
        throw new PopupError('Popup was blocked by the browser');
      }

      popupRef.current = popup;
      setIsOpen(true);

      // Set up popup monitoring
      let popupClosed = false;

      const checkClosed = () => {
        if (popupRef.current?.closed && !popupClosed) {
          popupClosed = true;
          setIsOpen(false);
          onClose?.();
          cleanup();
        }
      };

      const interval = setInterval(checkClosed, 500);

      const handleFocus = () => {
        if (popupRef.current?.closed) {
          checkClosed();
        }
      };

      window.addEventListener('focus', handleFocus);

      // Create cleanup function
      cleanupRef.current = () => {
        clearInterval(interval);
        window.removeEventListener('focus', handleFocus);
      };
    } catch (err) {
      handleError(err instanceof Error ? err : new Error('Failed to open popup'));
    }
  }, [authUrl, width, height, title, cleanup, handleError, onClose]);

  const closePopup = useCallback(() => {
    cleanup();
  }, [cleanup]);

  // Cleanup on unmount
  useEffect(() => {
    return cleanup;
  }, [cleanup]);

  return {
    isOpen,
    openPopup,
    closePopup,
    error,
  };
};
