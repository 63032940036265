import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import STRING_KEYS from '../../../../../language/keys';
import OutreachContentPreferencesSection
  from '../../../pages/outreach-content-preferences/outreach-content-preferences-section/outreach-content-preferences-section.component';
import NumberInputItem from '../input-items/number-input-item/number-input-item.component';
import { OutreachContentPreferencesFormikKeys } from '../../../pages/outreach-content-preferences/outreach-content-preferences.enums';
import SwitchInputItem from '../input-items/switch-input-item/switch-input-item.component';
import {
  useGetEmailSenderSelector,
} from '../../../../../store/rtk-query/apis/outreach/selectors/get-email-sender.selector';
import { EmailCredentialsTypeEnum } from '../../../../../enums/email-credentials-type.enum';
import {
  PerfectButtonHoverVariants,
  PerfectButtonVariants,
} from '../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.enums';
import { useGetSignatureQuery } from '../../../../../store/rtk-query/apis/outreach/hooks/get-signature.query-hook';
import { MoreOptionsProps } from './more-options';
import {
  Container,
  IncludeEmailSignatureWrapper,
  SignaturePreviewButton,
  TrackEmailOpensWrapper,
} from './more-options.styled';
import TrackEmailOpensBanner from './track-email-opens-banner/track-email-opens-banner.component';
import EnableReadPermissionBanner from './enable-read-permission-banner/enable-read-permission-banner.component';
import { PreviewSignatureDialog } from './preview-signature-dialog/preview-signature-dialog.component';

const MoreOptions = ({
  positionId,
  includeEmailSignature,
  messageCharactersLength,
  includeEmailTracking,
  onChangeHandler,
}: MoreOptionsProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const [previewSignatureDialogOpen, setPreviewSignatureDialogOpen] = useState(false);
  const { data: emailSender } = useGetEmailSenderSelector();
  const readPermissionsGranted = emailSender?.readPermissionsGranted;
  const { data: signatureData, refetch: refetchSignature } = useGetSignatureQuery();
  const shouldShowPermissionBanner = useMemo(() => {
    return EmailCredentialsTypeEnum.GmailOauthRefreshToken === emailSender?.credentials.type && !emailSender?.readPermissionsGranted;
  }, [emailSender]);

  const isSignatureExists = useMemo(() => {
    return !!signatureData?.signatureHtml;
  }, [signatureData]);

  const openPreviewSignatureDialog = () => {
    setPreviewSignatureDialogOpen(true);
  };

  const closePreviewSignatureDialog = (signatureConfirmed?: boolean) => {
    setPreviewSignatureDialogOpen(false);
    if (signatureConfirmed) {
      onChangeHandler(OutreachContentPreferencesFormikKeys.IncludeEmailSignature, true);
      refetchSignature?.();
    }
  };

  const onMessageCharactersLengthChange = (updatedLength: number) => {
    let value: number | undefined = updatedLength;

    if (updatedLength === 0) {
      value = undefined;
    }

    onChangeHandler(OutreachContentPreferencesFormikKeys.MessageCharactersLength, value);
  };

  const onIncludeEmailSignatureToggleChange = (value: boolean) => {
    if (!isSignatureExists && emailSender?.credentials.type === EmailCredentialsTypeEnum.MicrosoftOauthRefreshToken) {
      openPreviewSignatureDialog();
    } else {
      onChangeHandler(OutreachContentPreferencesFormikKeys.IncludeEmailSignature, value);
    }
  };

  return (
    <>
      <OutreachContentPreferencesSection
        title={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MORE_OPTIONS.TITLE) }
      >
        <Container>
          <NumberInputItem
            title={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MORE_OPTIONS.INPUTS.MESSAGE_LENGTH.TITLE) }
            description={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MORE_OPTIONS.INPUTS.MESSAGE_LENGTH.DESCRIPTION) }
            placeholder={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MORE_OPTIONS.INPUTS.MESSAGE_LENGTH.PLACEHOLDER) }
            inputLabel={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MORE_OPTIONS.INPUTS.MESSAGE_LENGTH.INPUT_LABEL) }
            value={ messageCharactersLength }
            min={ 0 }
            max={ 500 }
            changeHandler={ onMessageCharactersLengthChange }
          />
          <IncludeEmailSignatureWrapper>
            <SwitchInputItem
              title={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MORE_OPTIONS.INPUTS.INCLUDE_EMAIL_SIGNATURE.TITLE) }
              description={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MORE_OPTIONS.INPUTS.INCLUDE_EMAIL_SIGNATURE.DESCRIPTION) }
              value={ includeEmailSignature }
              changeHandler={ onIncludeEmailSignatureToggleChange }
              disabled={ !readPermissionsGranted }
            />
            {
              isSignatureExists && (
                <SignaturePreviewButton
                  variant={ PerfectButtonVariants.Ghost }
                  hoverVariant={ PerfectButtonHoverVariants.Grow }
                  onClick={ openPreviewSignatureDialog }
                >
                  { translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MORE_OPTIONS.INPUTS.INCLUDE_EMAIL_SIGNATURE.PREVIEW_SIGNATURE_BUTTON) }
                </SignaturePreviewButton>
              )
            }
            { shouldShowPermissionBanner && (
              <EnableReadPermissionBanner positionId={ positionId } />
            ) }
          </IncludeEmailSignatureWrapper>
          <TrackEmailOpensWrapper>
            <SwitchInputItem
              title={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MORE_OPTIONS.INPUTS.TRACK_EMAIL_OPENS.TITLE) }
              description={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MORE_OPTIONS.INPUTS.TRACK_EMAIL_OPENS.DESCRIPTION) }
              value={ includeEmailTracking }
              changeHandler={ (value) => onChangeHandler(OutreachContentPreferencesFormikKeys.IncludeEmailTracking, value) }
            />
            <TrackEmailOpensBanner />
          </TrackEmailOpensWrapper>
        </Container>
      </OutreachContentPreferencesSection>
      <PreviewSignatureDialog
        isDialogOpen={ previewSignatureDialogOpen }
        closeDialogHandler={ closePreviewSignatureDialog }
      />
    </>
  );
};

export default MoreOptions;
