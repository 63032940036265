import { Trans, useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import STRING_KEYS from '../../../language/keys';
import NotificationDialog from '../notification-dialog/notification-dialog.component';
import { IntercomManager } from '../../../services/intercom-manager';
import { useMyUserSelector } from '../../../store/rtk-query/apis/user/selectors/get-my-user.selector';
import { useDeleteAccountAcknowledgementById } from '../../../store/rtk-query/apis/user/hooks/delete-account-aknowledgement.hook';
import { useSeeAcknowledgements } from '../../../hooks/acknowledgements-react-qury.hooks';
import { AcknowledgementType } from '../../../enums/acknowledgement-type.enum';
import { useGetPositionSequencePreferencesQuery } from '../../../store/rtk-query/apis/sequence/hooks/get-position-sequence-preferences.query-hook';
import { useGetPositionByIdFromPositionsQuerySelector } from '../../../store/rtk-query/apis/position/selectors/get-positions.selector';
import { useResumeCampaignQuery } from '../../../store/rtk-query/apis/sequence/hooks/resume-campaign.query-hook';
import {
  ActionIcon,
  Container,
  Description,
  Icon,
  SecondaryActionIcon,
  Title,
} from './campaign-error-notification.styled';

const CampaignErrorNotification = ({ onErrorHandler }: CampaignErrorNotificationProps): JSX.Element | null => {
  const { t: translate } = useTranslation();
  const { data: { acknowledgements, id: userId } } = useMyUserSelector();
  const deleteAccountAcknowledgementById = useDeleteAccountAcknowledgementById();
  const [resumeCampaign, { isError }] = useResumeCampaignQuery();
  const { mutate: seeAcknowledgementMutate } = useSeeAcknowledgements();
  const [isNotificationOpen, setIsNotificationOpen] = useState(true);

  useEffect(() => {
    if (isError) {
      onErrorHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  const getCampaignErrorAcknowledgement = () => {
    const campaignErrorAcknowledgements = acknowledgements?.filter((ack) => ack.type === AcknowledgementType.CampaignError);

    return campaignErrorAcknowledgements?.find((ack) => ack.metadata.senderId === userId) || campaignErrorAcknowledgements?.[0];
  };

  const campaignErrorAcknowledgement = useMemo(getCampaignErrorAcknowledgement, [acknowledgements, userId]);

  const isNotificationRelatedToCurrentUser = campaignErrorAcknowledgement?.metadata.senderId === userId;

  const {
    data: preferences,
    isLoading: isPreferencesLoading,
  } = useGetPositionSequencePreferencesQuery(campaignErrorAcknowledgement?.positionId, !campaignErrorAcknowledgement || isNotificationRelatedToCurrentUser);
  const { data: relatedPosition } = useGetPositionByIdFromPositionsQuerySelector(campaignErrorAcknowledgement?.positionId);

  const senderName = useMemo(
    () => preferences.sequencePreferences?.senders?.find((sender) => sender.user.id === campaignErrorAcknowledgement.metadata.senderId)?.user?.fullName,
    [preferences.sequencePreferences?.senders, campaignErrorAcknowledgement?.metadata?.senderId]);

  const DescriptionComponent = useMemo(() => {
    return (
      <Description>
        <Trans
          i18nKey={ STRING_KEYS.CAMPAIGN_ERROR_NOTIFICATION.DESCRIPTION }
          values={ { positionName: relatedPosition.jobTitle } }
        />
      </Description>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relatedPosition.jobTitle, senderName, isNotificationRelatedToCurrentUser]);

  const dismissAcknowledgementAndCloseNotification = () => {
    setIsNotificationOpen(false);
    seeAcknowledgementMutate(campaignErrorAcknowledgement.id);
    deleteAccountAcknowledgementById(campaignErrorAcknowledgement.id);
  };

  const onActionButtonClick = () => {
    resumeCampaign({ positionId: relatedPosition.id });
    dismissAcknowledgementAndCloseNotification();
  };

  const onSecondaryActionButtonClick = () => {
    IntercomManager.showChat();
  };

  if (!campaignErrorAcknowledgement || isPreferencesLoading) {
    return null;
  }

  return (
    <>
      <NotificationDialog
        isOpen={ isNotificationOpen }
        onClose={ dismissAcknowledgementAndCloseNotification }
        closeTitle={ translate(STRING_KEYS.CAMPAIGN_ERROR_NOTIFICATION.CLOSE_BUTTON_TEXT) }
        actionTitle={ translate(STRING_KEYS.CAMPAIGN_ERROR_NOTIFICATION.ACTION_BUTTON_TEXT) }
        secondaryActionTitle={ translate(STRING_KEYS.CAMPAIGN_ERROR_NOTIFICATION.SECONDARY_ACTION_BUTTON_TEXT) }
        ActionIcon={ ActionIcon }
        SecondaryActionIcon={ SecondaryActionIcon }
        onAction={ onActionButtonClick }
        onSecondaryAction={ onSecondaryActionButtonClick }
        showDivider
      >
        <Container>
          <Icon />
          <Title>{ translate(STRING_KEYS.OUTREACH_PAGE.CAMPAIGN_ON_HOLD.TITLE) }</Title>
          { DescriptionComponent }
        </Container>
      </NotificationDialog>
    </>
  );
};

export default CampaignErrorNotification;
